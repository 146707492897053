import {useState} from 'react';
import qs from 'qs';
import {useTranslation} from 'react-i18next';

const query = qs.parse(window.location.search, {ignoreQueryPrefix: true});

const defaultState = {
  contract_address: query.a || '',
  compiler_type: '',
  compiler_version: '',
  license_type: 'None',
  chk_terms: false,
};
export default function StepOne({compilerLicenseList, compilerVersionList, onConfirm}) {
  const {t} = useTranslation(['verifyContract']);
  const [state, setState] = useState({
    ...defaultState,
  });

  return (
    <main role="main">
      <div className="container space-top-1 space-bottom-2">
        <form method="post" action="./verifyContract" className="js-validate mt-2" noValidate="novalidate">
          <div className="text-center mb-3">
            <div className="w-md-75 w-lg-75 mx-md-auto ">
              <h1 className="h3">{t('verifyContract.StepOne.title1')} </h1>
              <small className="d-block text-muted color-strong">{t('verifyContract.StepOne.title2')}</small>
            </div>
          </div>
          <div className="border-top py-4">
            <div className="row">
              <div className="col-md-10 offset-md-1 text-secondary mt-3 mb-2">
                <img src="/images/undraw/undraw_Security_on_s9ym.svg" width="115" align="left" style={{marginTop: '-15px', marginBottom: '10px', marginRight: '10px'}} />
                {t('verifyContract.StepOne.title3')}
              </div>
            </div>
            <div className="w-md-75 w-lg-50 mx-md-auto mt-2">
              <div className="js-form-message form-group">
                <label className="d-block" htmlFor="txtUserName">
                  {t('verifyContract.StepOne.lable1')}
                </label>
                <input
                  name="ctl00$ContentPlaceHolder1$txtContractAddress"
                  maxLength="42"
                  className="form-control form-control-sm"
                  required=""
                  placeholder="0x..."
                  value={state.contract_address}
                  data-success-class="u-has-success"
                  data-error-class="u-has-error"
                  data-rule-minlength="40"
                  type="search"
                  data-msg-minlength="Invalid Length"
                  data-msg="Required"
                  data-name="txtContractAddress"
                  data-rule-maxlength="42"
                  onChange={e => {
                    setState({...state, contract_address: e.target.value});
                  }}
                />
                <div id="ContentPlaceHolder1_txtContractAddress-error" className="invalid-feedback" style={{display: 'none'}}>
                  Required
                </div>
              </div>
              <div className="js-form-message form-group">
                <label className="d-block mt-4"> {t('verifyContract.StepOne.lable2')}</label>
                <select
                  name="ctl00$ContentPlaceHolder1$ddlCompilerType"
                  defaultValue=""
                  value={state.compiler_type}
                  className="custom-select custom-select-sm mb-1"
                  onChange={e => {
                    setState({...state, compiler_type: e.target.value});
                  }}
                >
                  <option value="">{t('verifyContract.StepOne.selected')}</option>
                  <option value="SingleFile" title="A simple interface for solidity code that fits or concatenated into a single file">
                  {t('verifyContract.StepOne.selectSingle')}
                  </option>
                  <option value="MultiFile" title="Support for multi-part/file solidity code with imports"> 
                    {t('verifyContract.StepOne.selectMul')}
                  </option>
                  <option value="Json" title="The recommended way to interface with the Solidity compiler especially for more complex and automated setups">
                    {t('verifyContract.StepOne.selectSta')}
                  </option>
                  {/* <option value="Vyper" title="Experimental support for the Vyper smart contract development language">
                    Vyper (Experimental)
                  </option> */}
                </select>
                <div></div>
              </div>
              <span id="ContentPlaceHolder1_compilerversion">
                <div className="js-form-message form-group">
                  <label className="d-block mt-4"> {t('verifyContract.StepOne.lable3')}</label>
                  <select
                    name="ctl00$ContentPlaceHolder1$ddlCompilerVersions"
                    id="ContentPlaceHolder1_ddlCompilerVersions"
                    className="custom-select custom-select-sm"
                    required=""
                    data-msg="Required"
                    data-error-class="u-has-error"
                    data-success-class="u-has-success"
                    value={state.compiler_version}
                    onChange={e => {
                      setState({...state, compiler_version: e.target.value});
                    }}
                  >
                    <option key="[Please Select]" value="">
                      [Please Select]
                    </option>
                    {compilerVersionList.map(item => {
                      return (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div id="ContentPlaceHolder1_divMajorBuildsOnly" className="custom-checkbox d-flex align-items-center text-muted" style={{marginTop: '-8px'}}>
                  <span className="ml-2">
                    <input id="ContentPlaceHolder1_chkShowAllCommits" type="checkbox" name="ctl00$ContentPlaceHolder1$chkShowAllCommits" checked="checked" onChange={() => {}} />
                  </span>
                  <small className="d-block text-muted ml-1">{t('verifyContract.StepOne.chkLable3')}</small>
                </div>
              </span>
              <span>
                <div className="js-form-message form-group" style={{display: 'block'}}>
                  <label className="d-block mt-4">
                    {t('verifyContract.StepOne.lable4')}{' '}
                    <a href="/contract-license-types" target="_blank" data-toggle="tooltip" title="" data-original-title="Contract Source Code License Type, click for more info">
                      <i className="far fa-info-circle"></i>
                    </a>
                  </label>
                  <select
                    name="ctl00$ContentPlaceHolder1$ddlLicenseType"
                    title="Select a suitable license type"
                    className="custom-select custom-select-sm"
                    required=""
                    data-msg="Required"
                    data-error-class="u-has-error"
                    data-success-class="u-has-success"
                    value ={state.license_type}
                    onChange={e => {
                      setState({...state, license_type: e.target.value});
                    }}
                  >
                    {compilerLicenseList.map(item => {
                      return (
                        <option key={item.symbol} value={item.symbol}>
                          {item.liscense_type}({item.symbol})
                        </option>
                      );
                    })}
                  </select>
                </div>
              </span>
              <p align="center" className="mt-3">
                <span
                  className="custom-control custom-checkbox align-items-center text-muted mb-2"
                  onClick={e => {
                    setState({...state, chk_terms: !state.chk_terms});
                  }}
                >
                  <input
                    name="chkTermsOfUse"
                    type="checkbox"
                    className="custom-control-input align-items-center"
                    checked={state.chk_terms ? 'checked' : ''}
                    onChange={e => {
                      setState({...state, chk_terms: !state.chk_terms});
                    }}
                  />
                  <label className="custom-control-label" htmlFor="ContentPlaceHolder1_chkRemember">
                    <span>
                       <a href="/terms">{t('verifyContract.StepOne.chkLable4')}</a>
                    </span>
                  </label>
                </span>
                <input
                  type="button"
                  value={t('verifyContract.StepOne.btnContinue')}
                  id="ContentPlaceHolder1_btnSubmit"
                  className="btn btn-primary mr-2"
                  onClick={e => {
                    console.log(state);
                    if (!state.chk_terms) {
                      return;
                    }
                    onConfirm(state);
                  }}
                />
                <input
                  type="button"
                  value={t('verifyContract.StepOne.btnReset')}
                  formNoValidate="formnovalidate"
                  className="btn btn-soft-secondary"
                  onClick={e => {
                    setState({...defaultState});
                  }}
                />
              </p>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
}
