import {useState} from 'react';
import {useRequest} from 'ahooks'; 
import VerifyCode from '../../components/VerifyCode'; 
import { encrypt } from '../../commons/utils';
import {useTranslation} from 'react-i18next';

export default function Register() {
  const {t} = useTranslation(['register']);

  const registerRequest = useRequest(
    body => ({
      url: '/loginapi/sys/user/register',
      method: 'post',    
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );
  const [form, setForm] = useState({
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    captchaId: '',
    verifyCode: '',
    terms: false,
    newsletter: false,
  });

  const [errors, setErrors] = useState({});

  
  //按钮状态
  const [isLoading, setLoading] = useState(false)

  const handleForm = field => e => {
    setForm({...form, [field]: e.target.value});

    if (e.target.value) {
      setErrors({...errors, [field]: undefined});
    }
  };

  const handleUpdateVerifyCode = e => {
    setForm({...form, ...e}); 
    if (e.verifyCode) {
      setErrors({...errors, verifyCode: undefined});
    }
  };

  // debounce已达到预期效果！ 防抖
  const debounce=(f,time = 1000)=>{
    let timer = null
    return () => {
      if(timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(f.bind(this),time)
    }
  }
  // throttle已达到预期效果！ 节流
  const throttle = (f,time = 1000) =>{
    let timer = null
    return () => {
      if(timer) return
      timer = setTimeout(() => {
        f.apply(this)
        timer = null
      },time)
    }
  } 


  const handleSubmit = () => {


    const emailREG = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/gi;
    const _errors = {};

    Object.keys(form)
      .filter(f => f !== 'newsletter')
      .forEach(field => {
        _errors[field] = !form[field];
      });

    if (form.password && form.password.length < 5) {
      _errors.password = true;
    }

    if (form.password && form.confirmPassword && form.password !== form.confirmPassword) {
      _errors.confirmPassword = 'equalto';
    }

    if (!emailREG.test(form.email)) {
      _errors.email = true;
    }

    if (!_errors.userName && (form.userName.length < 5 || form.userName.length > 30)) {
      _errors.userName = true;
    } 
    if (Object.keys(_errors).some(e => !!_errors[e])) {
      setErrors(_errors);
    } else { 
      const formEncrpty = {
        userName: encrypt(form.userName),
        email: encrypt(form.email),
        password: encrypt(form.password),
        confirmPassword: encrypt(form.confirmPassword),
        captchaId: encrypt(form.captchaId),
        verifyCode: encrypt(form.verifyCode),
      }

      registerRequest.run({...formEncrpty, terms: undefined, newsletter: undefined}).then(res => { 
        if (res.code === 200) {
          
          prompt.inform('Create success, please login email and actived the account')
          setTimeout(function () {
            window.location.href = '/login';
          },2000)
          // alert(res.message) 
        }
      });
    }

    console.log(_errors);
  };

  const handleBlur = key => () => {
    const emailREG = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/gi;
    const _errors = {...errors};

    Object.keys(form)
      .filter(f => f !== 'newsletter')
      .forEach(field => {
        if (field === key) {
          _errors[field] = !form[field];
        }
      });

    if (form.password && form.password.length < 5 && key === 'password') {
      _errors.password = true;
    }

    if (form.password && form.confirmPassword && form.password !== form.confirmPassword && key === 'confirmPassword') {
      _errors.confirmPassword = 'equalto';
    }

    if (!emailREG.test(form.email) && key === 'email') {
      _errors.email = true;
    }

    if (!_errors.userName && (form.userName.length < 5 || form.userName.length > 30) && key === 'userName') {
      _errors.userName = true;
    }

    if (Object.keys(_errors).some(e => !!_errors[e])) {
      if (_errors[key]) {
        setErrors(_errors);
      }
    }
  };

  const showError = typeof registerRequest.data?.code !== 'undefined' && registerRequest.data?.code !== 200;

  return (
    <main className="bg-light content-height" role="main">
      <div className="container space-2">
        <form className="js-validate w-md-75 w-lg-50 mx-md-auto" noValidate="novalidate">
          <div className="mb-7">
            <h1 className="h3 text-primary font-weight-normal mb-2">
              {t('register.title1')}
            </h1>
            {/* <p>Fill out the form to get started.</p> */}
          </div>
          <div className="alert alert-danger" style={{display: showError ? 'block' : 'none'}}>
            {registerRequest.data?.message}
          </div>
          <div>
            <div className={`js-form-message form-group ${errors.userName ? 'u-has-error' : ''}`}>
              <label className="d-block" htmlFor="txtUserName">
                {t('register.Username')}
              </label>
              <input
                type="text"
                maxLength="30"
                className="form-control form-control-sm"
                placeholder={t('register.UsernamePlaceholder')}
                aria-label="Username"
                data-rule-minlength="5"
                data-msg="Username is invalid."
                value={form.userName}
                onChange={handleForm('userName')}
                onBlur={handleBlur('userName')}
              />
              <div className="invalid-feedback" style={{display: errors.userName ? 'block' : 'none'}}>
                {t('register.Usernameinvalid')}
              </div>
            </div>
            <div className={`js-form-message form-group ${errors.email ? 'u-has-error' : ''}`}>
              <span className="d-flex justify-content-between align-items-center">
                 {t('register.Email')}
                <a className="link-muted font-size-1" href="/resendemail" tabIndex="0">
                  {t('register.ResendEmail')}
                </a>
              </span>
              <input
                maxLength="100"
                className="form-control form-control-sm"
                type="email"
                placeholder={t('register.Email')}
                aria-label="Email address"
                data-msg="Please enter a valid email address."
                value={form.email}
                onChange={handleForm('email')}
                onBlur={handleBlur('email')}
              />
              <div className="invalid-feedback" style={{display: errors.email ? 'block' : 'none'}}> 
                {t('register.EnterEmail')}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className={`js-form-message form-group ${errors.password ? 'u-has-error' : ''}`}>
                  <label className="d-block" htmlFor="txtPassword">
                    {t('register.Password')}
                  </label>
                  <input
                    type="password"
                    maxLength="75"
                    className="form-control form-control-sm"
                    placeholder="******"
                    aria-label="********"
                    data-rule-minlength="5"
                    data-msg="Your password must be at least 5 characters long."
                    value={form.password}
                    onChange={handleForm('password')}
                    onBlur={handleBlur('password')}
                  />
                  <div className="invalid-feedback" style={{display: errors.password ? 'block' : 'none'}}> 
                    {t('register.PasswordLen')}
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className={`js-form-message form-group ${errors.confirmPassword ? 'u-has-error' : ''}`}>
                  <label className="d-block" htmlFor="txtPassword2">
                    {t('register.ConfirmPassword')}
                  </label>
                  <input
                    type="password"
                    maxLength="75"
                    className="form-control form-control-sm"
                    placeholder="******"
                    aria-label="********"
                    data-rule-minlength="5"
                    value={form.confirmPassword}
                    onChange={handleForm('confirmPassword')}
                    onBlur={handleBlur('confirmPassword')}
                  />
                  <div className="invalid-feedback" style={{display: errors.confirmPassword ? 'block' : 'none'}}>
                    {errors.confirmPassword === 'equalto' ? 'Password does not match, please check again.' : 'Your password must be at least 5 characters long.'}
                  </div>
                </div>
              </div>
            </div>

            <div className={`js-form-message form-group ${errors.verifyCode ? 'u-has-error' : ''}`}>
              <label className="d-block" htmlFor="txtEmail">
                {t('register.Captcha')}
              </label>
              <VerifyCode value={form.verifyCode} onChange={handleUpdateVerifyCode} />
              <div className="invalid-feedback" style={{display: errors.verifyCode ? 'block' : 'none'}}>
                {t('register.Captchainvalid')}
              </div>
            </div>
            <div className={`js-form-message ${errors.terms ? 'u-has-error' : ''}`}>
              <div
                className="custom-control custom-checkbox d-flex align-items-center text-muted"
                onClick={e => {
                  handleForm('terms')({target: {value: !form.terms}});
                }}
              >
                <input
                  type="checkbox"
                  name="ContentPlaceHolder1_MyCheckBox"
                  className="custom-control-input"
                  data-msg="Please accept our Terms and Conditions."
                  checked={form.terms ? 'checked' : ''}
                  onChange={() => {}}
                />
                <label className="custom-control-label active" htmlFor="ContentPlaceHolder1_MyCheckBox">
                  <span> 
                    <a className="link-muted" href="/terms" target="_blank">
                      <span>{t('register.agreeItem')}</span>
                    </a>
                  </span>
                </label>
              </div>
              <div className="invalid-feedback" style={{display: errors.terms ? 'block' : 'none'}}>
                {t('register.acceptItem')}
              </div>
            </div>
            <div className="js-form-message">
              <div className="custom-control custom-checkbox d-flex align-items-center text-muted" onClick={() => handleForm('newsletter')({target: {value: !form.newsletter}})}>
                <input type="checkbox" className="custom-control-input" name="ContentPlaceHolder1_SubscribeNewsletter" checked={form.newsletter ? 'checked' : ''} onChange={() => {}} />
                <label className="custom-control-label active" htmlFor="ContentPlaceHolder1_SubscribeNewsletter">
                  <span>{t('register.agreeItem2')}</span>
                </label>
              </div>
            </div>


            <div className="d-flex justify-content-center my-4"></div>

            <div className="row align-items-center">
              <div className="col-11 col-sm-11">
                <span className="text-muted">{t('register.agreeItem3')}</span> 
              </div>
            </div>
            <div className="d-flex justify-content-center my-1"></div>


            <div className="row align-items-center">
              <div className="col-5 col-sm-6">
                <span className="text-muted">{t('register.tip1')}</span>
                <a className="text-nowrap" href="/login">
                {t('register.tip2')}
                </a>
              </div>
              <div className="col-7 col-sm-6 text-right"> 
                <input type="button" value={t('register.tip3')} className="btn btn-sm btn-primary" onClick={debounce(handleSubmit)} disabled={registerRequest.loading} />
              </div>
            </div>


          </div>
        </form>
      </div>
    </main>
  );
}
