import {useRequest} from 'ahooks';
import Loading from '../../components/Loading';
import Copy from '../../components/Copy';
import {useTranslation} from 'react-i18next';

export default function TokenHolder({token_name,token_address,account_address}) {
  const {t} = useTranslation(['token']);
  // debugger
  const tokenHoldAmount = useRequest({
    url: '/blockBrowser/tokens/address/tokenHoldAmount',
    method: 'post',
    body: JSON.stringify({
      token_address:token_address,
      token_types:1,
      value: account_address,
    }),
  });

  if (tokenHoldAmount.loading) {
    return <Loading />;
  }
  const holderAmount = tokenHoldAmount.data;


    // 通证类型：1-erc20 2-erc721,3-其他
    return (
      <div className="card h-100">
        <div className="card-body">
          <div className="border-bottom pb-1 mb-3" style={{marginTop: '-6px'}}>
            <div className="row mx-gutters-md-1">
              <div className="col-md-4 col-lg">
                <h6 className="small font-weight-bold text-dark mb-1">
                  <i className="fa fa-address-book mr-1 text-warning"></i>
                  <span className="text-uppercase">{t('token.TokenHolder.Filter')}</span>{' '}
                </h6>

                <div className="d-flex clipboard-hover">
                  <a 
                    className="font-weight-bold text-truncate d-block mr-2"
                    href={`/address/${holderAmount.account_address}`}
                    data-toggle="tooltip"
                    title=""
                    data-original-title="View Address Page"
                  >
                    {holderAmount.account_address}
                  </a>
                  <Copy text={holderAmount.account_address} title="Copy account address to clipboard" />
                   
                </div>
              </div>
              <div className="col-md-4 col-lg u-ver-divider u-ver-divider--left">
                <h6 className="small text-uppercase text-secondary mb-1">{t('token.TokenHolder.Balance')}</h6>
                <span className="d-block">
                 {holderAmount.balance_amount} {token_name}
                </span>
              </div>
              <div className="col-md-4 col-lg u-ver-divider u-ver-divider--left">
                <h6 className="small text-uppercase text-secondary mb-1">{t('token.TokenHolder.Value')}</h6>
                $ 0.00
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  