const blocks = { 
    'blocks.title': 'Blocks ', 
    'blocks.table.block': 'Block',
    'blocks.table.age': 'Age',
    'blocks.table.time': 'Date Time (UTC)',
    'blocks.table.txn': 'txn', 
    'blocks.table.validator': 'Validator', 
    'blocks.table.difficulty': 'Difficulty', 
    'blocks.tip1': 'Block ',
    'blocks.tip2': ' to',
    'blocks.tip3': 'Total of ',
    'blocks.tip4': 'blocks ',
    'blocks.tip5': 'Show',
    'blocks.tip6': 'Records', 

};

export default blocks;
