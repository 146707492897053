import {useRequest} from 'ahooks';
import {useEffect, useState} from 'react';

import Loading from '../../components/Loading';

import './style.css';
import {useTranslation} from 'react-i18next';
export default function Pending({}) {
  const {t} = useTranslation(['pending']);
  const [hash, setHash] = useState('');
  const [tradeHashStatus, setTradeHashStatus] = useState();
  const rpcAddressRequest = useRequest(
    body => ({
      url: '/blockBrowser/common/rpcAddress',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  const pendingTradeRequest = useRequest(
    body => ({
      url: '/blockBrowser/misc/pending/pendingTrade',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    rpcAddressRequest.run({});
  }, []);

  if (rpcAddressRequest.loading) {
    return <Loading />;
  }

  const {mainnet_rpc_address} = rpcAddressRequest.data || {
    mainnet_rpc_address: 'https://hpbnode.com',
  };

  const handleSearch = () => {
    if (!hash) {
      return;
    }

    if (hash.length !== 66) {
      return;
    }

    pendingTradeRequest
      .run({
        port_address: mainnet_rpc_address,
        trade_hash: hash,
      })
      .then(res => {
        setTradeHashStatus(res.trade_hash_status);
      });
  };

  return (
    <div className="page-pending">
      <div className="container flex-center">
        <div className="w-md-55 faucet-container">
          <div className="mb-5">
            <h1 className="text-primary font-weight">{t('pending.title')}</h1>
          </div>
          <div className="input-container input-group mb-4">
            <input placeholder="RPC Port" className="form-control" defaultValue={mainnet_rpc_address} />
          </div>
          <div className="input-container input-group">
            <input
              placeholder={t('pending.palceholder.tip1')}
              className="form-control input-form"
              value={hash}
              onChange={e => {
                setHash(e.target.value);
              }}
            />
            <button type="button" className="btn btn-primary btn-wide btn-input" onClick={handleSearch}>
              {t('pending.btnQuery')}
            </button>
          </div>
          {typeof tradeHashStatus !== 'undefined' ? (
            <div className="input-container input-group">
              <div class="search-container pre" style={{width: '100%'}}>
                {!tradeHashStatus ? (
                  <>
                    {/* <div>该交易尚未进入节点，或已被mempool规则排除。</div> */}
                    <div> {t('pending.tip1')}</div>
                    <a rel="noopener noreferrer" target="_blank" href="https://docs.hpb.io/#/dev/txpool" class="txpool">
                      {/* 前往链接获取解决方案 */}
                      {t('pending.tip2')}
                    </a>
                  </>
                ) : (
                  // <div>该交易已经进入区块链</div>
                  <div>{t('pending.tip3')}</div>
                )}
              </div>
            </div>
          ) : undefined}
        </div>
      </div>
    </div>
  );
}
