import {useState, useContext,useEffect } from 'react';
import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext'; 
import {useRequest} from 'ahooks'; 
import {Modal } from 'react-bootstrap'; 
import LinkWithTooltip from '../../components/LinkWithTooltip';
import Copy from '../../components/Copy'; 
import Pager2 from '../../components/Pager2';
import Loading from '../../components/Loading';
 

function CreateNameTagModal({user, show, onHide}) {

  const [fields, setFields] = useState({
    address: '',
    remark: '',
    notification: 0,
    chkTokenTransfer: 0,
    chkToken721: 0,
    chkToken1155: 0
  });
  const [errors, setErrors] = useState({});

  const handleForm = field => e => {
    const target = e.target; 
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;  
    setFields({...fields, [field]: value});

    if (e.target.value&& name === 'txtAddress') {
      setErrors({...errors, [field]: undefined});
    }
  }; 

  const handleBlur = key => () => {
    const _errors = {};
    Object.keys(fields).filter(f => f !== 'autologin')
     .forEach(field => {
        _errors[field] = !fields[field];
      });
    if (Object.keys(_errors).some(e => !!_errors[e])) {
      if (_errors[key]) {
        setErrors(_errors);
      }
    } 
  }; 
  
  const addressRequest = useRequest(
    body => ({
      url: '/loginapi/sys/user/account/nameTagCreate',
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
        'X-Access-Token': (user.token ||'')
      },
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );

  const handleHide=()=>{
    onHide(false)
  }
  const handleSubmit = () => { 
    const _errors = {};
 
    _errors['address'] =  !fields['address'];
    if(!!_errors['address']){
      setErrors(_errors);
    }else { 
      addressRequest.run(fields).then(res => {
        console.log(res);
        if (res.code !== 200) {
          prompt.error(res.message); 
        } else { 
          prompt.inform('Add success')
          onHide(true);
        }
      },
      err=>{
        console.log(err);
      }); 
    }
   
  };

  return (
    <Modal show={show} onHide={handleHide} centered  aria-labelledby="contained-modal-title">
      <Modal.Header closeButton className="p-2">
          <h5 className="pl-3 modal-title h5 font-weight-bold text-break w-100 mt-2 mb-2"> Add New Address</h5> 
      </Modal.Header>
      <Modal.Body>

      <div className="mb-4">
          <div className="form-group">
            <label htmlFor="txtAddress">Ethereum Address</label>
            <div className="js-form-message">
              <input name="txtAddress" type="text" value={fields.address} maxLength="44" id="txtAddress" 
               className="form-control form-control-sm" required=""     placeholder="Enter a address" onChange={handleForm('address')} 
                onBlur={handleBlur('address')} />
                <div className="invalid-feedback" style={{display: errors.address ? 'block' : 'none'}}>
                  Please enter eth address
                </div>
            </div>
            <div className="input-group input-group-sm">
                <span id="revAddress" style={{color:"red",fontSize:"Smaller",display: errors.valiadateAddress ? 'block' : 'none'}} >*Invalid Address</span>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="txtNameTag">Private Name Tag</label>
            <input name="txtNameTag" type="text" value={fields.nameTag} maxLength="42" id="txtNameTag" 
               onChange={handleForm('nameTag')}    className="form-control form-control-sm"   placeholder="e.g. Donate Address" /> 
            <span className="form-text small text-secondary">Private Name Tags (up to 35 characters) can be used for easy identification of addresses</span>
          </div> 
          
           <div className="form-group">
            <label htmlFor="txtRemark">Private Note</label>
            <input name="txtRemark" type="text" value={fields.remark} id="txtRemark" 
               onChange={handleForm('remark')}    className="form-control form-control-sm"   placeholder="Short description.." /> 
            <span className="form-text small text-secondary">A private note (up to 500 characters) can be attached to this address. Please DO NOT store any passwords or private keys here.</span>
          </div>
        </div> 


      </Modal.Body>
      <Modal.Footer>
        <input type="button" value="Add" className="btn btn-sm btn-primary btn-xs" onClick={handleSubmit} />
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={handleHide}>
          Cancel
        </button>
       
      </Modal.Footer>
    </Modal>
  );
  
}

function EditNameTagModal({data, user, show, onHide}) {
 
  const [errors, setErrors] = useState({});

  const [fields, setFields] = useState({}); 
  

  const handleForm = field => e => {
    const target = e.target; 
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;  
    setFields({...fields, [field]: value});

    if (e.target.value&& name === 'txtAddress') {
      setErrors({...errors, [field]: undefined});
    }
  }; 

  const handleBlur = key => () => {
    const _errors = {};
    Object.keys(fields).filter(f => f !== 'autologin')
     .forEach(field => {
        _errors[field] = !fields[field];
      });
    if (Object.keys(_errors).some(e => !!_errors[e])) {
      if (_errors[key]) {
        setErrors(_errors);
      }
    } 
  }; 
  const handleHide=()=>{
    onHide(false);
  }
  const keyEditRequest = useRequest(
    body => ({
      url: '/loginapi/sys/user/account/nameTagEdit',
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
        'X-Access-Token': (user.token ||'')
      },
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  ); 

  useEffect(() => {
    const id = data?.id||0;
    const address = data?.address||'';
    const remark = data?.remark||''; 
    const nameTag = data.nameTag || '';
    setFields({
      id:id,
      address: address,
      remark: remark,
      nameTag: nameTag 
    });
  }, [data]);
 
  const handleEdit = () => { 
    const _errors = {}; 
    _errors['address'] =  !fields['address']; 
    if(!!_errors['address']){ 
      setErrors(_errors); 
    }else {  
      keyEditRequest.run(fields).then(res => {
        console.log(res);
        if (res.code !== 200) {
          prompt.error(res.message); 
        } else { 
          prompt.inform('Add success')
          onHide(true);
        }
      },
      err=>{
        console.log(err);
      }); 
    }
 
  }; 
 
  return (
    <Modal show={show} onHide={handleHide}  centered  aria-labelledby="contained-modal-title">
      <Modal.Header closeButton className="p-2">
        <Modal.Title>
        <h5 className="pl-3 modal-title h5 font-weight-bold text-break w-100 mt-2 mb-2"> Edit Private Name Tag</h5> 
        </Modal.Title>
        
      </Modal.Header>
      <Modal.Body>
         
        <div className="mb-4">
          <div className="form-group">
            <label htmlFor="txtAddress">Ethereum Address</label>
            <div className="js-form-message">
              <input name="txtAddress" type="text" value={fields.address} maxlength="44" id="txtAddress" readonly  
               className="form-control form-control-sm" required=""     placeholder="Enter a address" onChange={handleForm('address')} 
                onBlur={handleBlur('address')} />
                <div className="invalid-feedback" style={{display: errors.address ? 'block' : 'none'}}>
                  Please enter eth address
                </div>
            </div>
            <div className="input-group input-group-sm">
                <span id="revAddress" style={{color:"red",fontSize:"Smaller",display: errors.valiadateAddress ? 'block' : 'none'}} >*Invalid Address</span>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="txtNameTag">Private Name Tag</label>
            <input name="txtNameTag" type="text" value={fields.nameTag} maxLength="42" id="txtNameTag" 
               onChange={handleForm('nameTag')}    className="form-control form-control-sm"   placeholder="e.g. Donate Address" /> 
            <span className="form-text small text-secondary">Private Name Tags (up to 35 characters) can be used for easy identification of addresses</span>
          </div> 
          
           <div className="form-group">
            <label htmlFor="txtRemark">Private Note<span className="text-secondary">(Optional)</span></label>
            <input name="txtRemark" type="text" value={fields.remark} id="txtRemark" 
               onChange={handleForm('remark')}    className="form-control form-control-sm"   placeholder="Short description.." /> 
            <span className="form-text small text-secondary">A private note (up to 500 characters) can be attached to this address. Please DO NOT store any passwords or private keys here.</span>
          </div>
        </div> 

      </Modal.Body>
      <Modal.Footer>
        <div className="d-sm-flex justify-content-end" style={{width: '100%'}}>
          <div className="d-flex">
            <div>
              <input type="button" value="Save Changes" className="btn btn-primary btn-xs" onClick={handleEdit} />
            </div>
            <div className="px-2">
              <input type="button" value="Cancel" className="btn btn-secondary btn-xs" onClick={handleHide} />
            </div>
          </div> 
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function DelNameTagModal({data, user, show, onHide}){ 
  const keyDeleteRequest = useRequest(
    body => ({
      url: '/loginapi/sys/user/account/nameTagDelete',
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
        'X-Access-Token': (user.token ||'')
      },
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );

  const handleDelItem = () => { 
    if (!data.id) {
      return;
    } 
    const formEncrpty = { 
      id: data.id 
    }
    keyDeleteRequest.run(formEncrpty).then(res => {
      if (res.code === 200) { 
        prompt.inform('Delete success')
        onHide(true);
      } else  {
        prompt.error(res.message) 
      }  
    }); 
  }; 

  const handleHide=()=>{
    onHide(false);
  }

  return (
    <Modal show={show} onHide={handleHide}   aria-labelledby="contained-modal-title">
      <Modal.Header closeButton className="p-2">
        <Modal.Title>
        <h5 className="pl-3 modal-title h5 font-weight-bold text-break w-100 mt-2 mb-2">Confirmation Required</h5> 
        </Modal.Title>
        
      </Modal.Header>
      <Modal.Body>         
        <div className="mb-4">
          <div className="form-group">           
            <div className="js-form-message">
              Are you sure you wish to remove the address tag?
            </div>
            <div className="js-form-message">
              {data.address}
            </div>            
          </div>
        </div> 
      </Modal.Body>
      <Modal.Footer>
        <div className="d-sm-flex justify-content-end" style={{width: '100%'}}>
          <div className="d-flex">
            <div>
              <input type="button" value="Remove" className="btn btn-primary btn-xs" onClick={handleDelItem} />
            </div>
            <div className="px-2">
              <input type="button" value="Cancel" className="btn btn-secondary btn-xs" onClick={handleHide} />
            </div>
          </div> 
        </div>
      </Modal.Footer>
    </Modal>
  );
}


function NameTagList({user,listData,onRefresh}) {

  const [currentItem, setCurrentItem] = useState({
    Id:0,
    address: '',
    nameTag: '',
    remark: '' 
  });
  const [showEditModal, setShowEditModal] = useState(false); 

  const [showDelModal, setShowDelModal] = useState(false); 
 
  const handleEditHide = (isRefresh) => {  
    setShowEditModal(false);
     if(isRefresh){
      onRefresh()
     } 
  };   

  const handleDelHide = (isRefresh) => {  
    setShowDelModal(false);
     if(isRefresh){
      onRefresh()
     } 
  };   

 
  const data = listData?.nametag_list || [];
  const counts = listData?.counts || 0;
  const limit_counts = listData?.limit_counts || 0; 

  return (
    <div className="card-body">
      <p className="text-dark mb-4">A private name tag (up to 35 chars) and memo (up to 500 chars) for individual addresses can be saved and is useful for labelling addresses of interest.</p>
     
      <div className="d-md-flex justify-content-between align-items-center mb-4">
        <p className="mb-2 mb-lg-0">
            {counts !==limit_counts  ?  `${counts} address tagged(Out of ${limit_counts} max limit)`  : ` Limit of  ${limit_counts} address tagged reached`} 
        </p> 
      </div>
      
      <div className="table-responsive mb-2 mb-md-0">
        <div className="mx-md-auto">
          <figure className="mb-7 ie-data-report">
            <table className="table table-text-normal table-align-middle table-lg-x table-hover">
              <thead className="thead-light">
                <tr> 
                  <th scope="col">Address</th>
                  <th scope="col">Private Nametag</th> 
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) && data.length !== 0 ? (
                  data.map((item, index) => {
                    return (
                      <tr key={index}>
                     
                        <td className="align-top">  
                            <div className="d-flex">
                              <div>
                                  <div className="d-flex align-items-center">
                                      <a className="mr-2" href={`/address/${item.address}`}  title={item.address}>
                                        <span className="text-primary" >
                                          {item.address}
                                        </span>
                                      </a> 
                                      <Copy text={item.address} title="Copy block miner  to clipboard" />
                                  </div>
                                  <div className="font-size-1 mt-1">
                                      <span className="text-secondary">Added on:</span> {item.createTime}
                                  </div> 
                                  <div className="font-size-1 mt-1" style={{maxWidth:'20rem'}}>
                                    <span className="text-secondary">Note: </span>
                                    <span>{item.remark}</span>
                                  </div>
                              </div>
                            </div>
                        </td>  
                        <td className="align-center">{item.nameTag}</td>

                        <td className="align-center text-nowrap">
                          <LinkWithTooltip placement="top" tooltip="Edit Address"> 
                              <a  className="btn btn-xs btn-white border text-nowrap mr-1"
                                href="#!"    onClick={e => {
                                  e.preventDefault();
                                  setCurrentItem(item);
                                  setShowEditModal(true);
                                }}  >
                                Edit <i className="fal fa-pen ml-1"></i>
                              </a>
                          </LinkWithTooltip> 

                          <LinkWithTooltip placement="top" tooltip="Remove Address"> 
                              <a  className="btn btn-xs btn-white border text-nowrap mr-1"
                                href="#!"    onClick={e => {
                                  e.preventDefault();
                                  setCurrentItem(item);
                                  setShowDelModal(true);
                                }}>
                                  Remove <i className="fal fa-trash text-danger"></i>
                              </a>
                          </LinkWithTooltip>  


                        </td>

                      </tr>
                    );
                  })
                ) : 
               
                (
                  <tr>
                    <td colSpan="3" className="align-top">
                      <center>
                        <div className="alert alert-secondary mt-4" role="alert">
                          <i className="fal fa-info-circle"></i> <span className="text-dark">There are no matching entries.</span>
                        </div>
                      </center>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </figure>
        </div>
      </div> 

      <EditNameTagModal user={user} data={currentItem} show={showEditModal} onHide={handleEditHide} />
      <DelNameTagModal user={user} data={currentItem} show={showDelModal} onHide={handleDelHide} />
    </div>
  );

}


export default function MyNotesAddress() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};

  const [showAddAddress, setShowAddAddress] = useState(false);
  const [showList, setShowList] = useState(true);
  
  
  const [state, setState] = useState({
    body: {
      start: '1',
      length: '10',
    },
  });
  
  const listMyNoteAddressRequest = useRequest(
    body => ({
      url: '/loginapi/sys/user/account/nameTagList',
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
        'X-Access-Token': (user.token ||'')
      },
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  const handleCurrentPage = (page) =>{     
    setState({...state, body: {...state.body, start:page + ''}}); 
  }

  useEffect(() => {
    listMyNoteAddressRequest.run(state.body);
  }, [state]);
  

 
  if (!user.token) {
    return (window.location.href = '/login');
  }

  
  const handleRefresh =()=>{
    listMyNoteAddressRequest.run(state.body);
  }
 
  const handleCreateHide = (isRefresh) => { 
    if(isRefresh){
      setShowList(false);
      listMyNoteAddressRequest.run(state.body);
      setTimeout(() => {
        setShowList(true);
      }, 100);
    } 
    setShowAddAddress(false);
  };

  if (listMyNoteAddressRequest.loading) {
    return <Loading />;
  }
 
  const listData = listMyNoteAddressRequest.data||[];
  const counts = listMyNoteAddressRequest.data?.counts || 0; 
  let totalPage = Math.ceil(Number(counts) / 10);
 
  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="mynotes_address" />
          <div className="col-md-9">
            <div className="card mb-4">
              <div className="card-header d-md-flex justify-content-between align-items-center">
                <div className="d-flex flex-wrap">
                  <div className="mr-2 mb-2 mb-md-0">
                    <h2 className="card-header-title mt-1">Address Private Name Tags</h2>
                  </div>        
                  <div className="mr-2 mb-2 mb-md-0">
                    { 
                      <div className="position-relative order-1 order-md-2">
                        <a
                          href="#!"
                          id="btnAddressAdd"
                          data-toggle="modal"
                          data-target="#myModal"
                          rel="tooltip"
                          title=""
                          className="btn btn-xss btn-primary rounded"
                          data-original-title="Add a new address to your watch list"
                          onClick={() => {
                            setShowAddAddress(!showAddAddress);
                          }}
                        >
                        <i className="fa fa-plus-square fa-lg mr-1" style={{marginLeft: '-3px', marginBottom: '3px'}}></i>Add
                        </a>
                      </div>  
                    } 
                  </div>

                </div>
                <div className="d-flex ml-auto"></div>
              </div>

              {showList ? <NameTagList user={user} listData={listData}  onRefresh={handleRefresh} /> : undefined}
              <div className="card-footer px-4 d-flex justify-content-md-end align-items-center text-secondary mb-2 mb-md-0 my-3">
                <div className="d-inline-block">
                  <Pager2 current={state.body.start} total={totalPage} pageChange= {handleCurrentPage}  />
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
      <CreateNameTagModal user={user} show={showAddAddress} onHide={handleCreateHide} />
     
    </main>
  );
}
