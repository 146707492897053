const tokensNft = { 
    'tokensNft.title': 'Non-Fungible Token Tracker', 
    'tokensNft.table.token': 'Token',
    'tokensNft.table.transfersTime': 'Transfers (24H)',
    'tokensNft.table.TransfersDay': 'Transfers (7D)', 
    'tokensNft.tip1': 'A total of',
    'tokensNft.tip2': 'Token Contracts found ', 
    'tokensNft.tip5': 'Show',
    'tokensNft.tip6': 'Records',

};

export default tokensNft;
