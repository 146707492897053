import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs'; 
import {useTranslation} from 'react-i18next';
import Pager from '../../components/Pager';
import Loading from '../../components/Loading';
import TimeTD from '../../components/TimeTD';
import LinkWithTooltip from '../../components/LinkWithTooltip';


export default function Blocks() {
  const location = useLocation();
  const {t} = useTranslation(['blocks']);
  
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      length:  window.localStorage.getItem('pageLength') || '50',
    },
  });
  const [type, setType] = useState('showAge'); 
  const handleChangePageSize = e => {
    window.localStorage.setItem('pageLength', e.target.value);
    setState({...state, body: {...state.body, length: e.target.value}});
  };

  const blocksRequest = useRequest(
    body => ({
      url: '/blockBrowser/blockChain/block/blockList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    blocksRequest.run(state.body);
  }, [state]);

  if (blocksRequest.loading) {
    return <Loading />;
  }

  const data = blocksRequest.data?.block_list || [];
  const counts = blocksRequest.data?.counts || 0;
  const firstBlock = data[0] || {};
  const lastBlock = data[data.length - 1] || {};
  const totalPage = Math.ceil(Number(counts) / state.body.length);

  return (
    <main id="content" role="main">
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center py-3">
          <div className="mb-1 mb-md-0">
            <h1 className="h4 font-weight-normal mb-0">
              {t('blocks.title')}&nbsp;<span className="small text-secondary"></span>
              <p className="mb-0 text-break small">
                <span className="small text-secondary"></span>
              </p>
            </h1>
          </div>
        </div>
      </div>
      <div className="container space-bottom-2">
        <div className="card">
          <div className="card-body">
            <div id="ContentPlaceHolder1_topPageDiv" className="d-md-flex justify-content-between mb-4">
              <p className="mb-2 mb-md-0">
                <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>
                {t('blocks.tip1')} #{firstBlock.block_no} {t('blocks.tip2')} #{lastBlock.block_no} ({t('blocks.tip3')} {counts} {t('blocks.tip4')})
              </p>
              <Pager path="/blocks" current={state.body.start} total={totalPage} />
            </div>
            <div className="table-responsive mb-2 mb-md-0">
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{t('blocks.table.block')}</th>
                    <th scope="col">
                    {
                          (type ==='showAge')?
                          <LinkWithTooltip placement="bottom" tooltip="Click to show Datetime Format">
                            <a href="#!" title="" onClick={e => {  e.preventDefault(); setType('showDate'); }} >
                            {t('blocks.table.age')}
                            </a>
                          </LinkWithTooltip>
                          :
                          <LinkWithTooltip placement="bottom" tooltip="Click to show Age Format"> 
                            <a href="#!" onClick={e => {  e.preventDefault(); setType('showAge'); }}  >
                            {t('blocks.table.block')}
                            </a>
                          </LinkWithTooltip>
                        }
                    </th>
                    <th scope="col">{t('blocks.table.txn')}</th>
                    <th scope="col"> {t('blocks.table.validator')}</th>
                    <th scope="col">{t('blocks.table.difficulty')} </th>
                    <th scope="col">{t('blocks.table.gasused')}</th>
                    <th scope="col">{t('blocks.table.gaslimit')}</th>
                    <th scope="col">{t('blocks.table.gasCollection')}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(item => {
                    const percent = (item.gas_used / item.gas_limit) * 100;

                    return (
                      <tr key={item.block_no}>
                        <td>
                          <a href={`/block/${item.block_no}`}>{item.block_no}</a>
                        </td>
                        <TimeTD time={item.block_time} interval={item.block_time_interval} type={type} />
                        <td>
                          <a href={`/txs?block=${item.block_no}`}>{item.block_trade_amount}</a>
                        </td>
                        <td>
                          <a href={`/address/${item.block_miner}`} className="hash-tag text-truncate" data-toggle="tooltip" title={item.block_miner}>
                            {item.block_miner}
                          </a>
                        </td>
                        <td>
                        {item.difficulty}
                        </td>
                        <td>
                          {item.gas_used} <span className="small text-secondary">({percent.toFixed(2)}%)</span>
                          <div className="progress mt-1" style={{height: '2px'}}>
                            <div
                              className="progress-bar bg-primary"
                              role="progressbar"
                              style={{width: `${percent.toFixed(2)}%`}}
                              aria-valuenow={percent.toFixed(2)}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </td>
                        <td>{item.gas_limit}</td>
                        <td>{item.block_reward} HPB</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div id="ContentPlaceHolder1_pageRecords">
              <form method="post" action="./blocks" id="ctl00">
                <div className="d-md-flex justify-content-between my-3">
                  <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                    {t('blocks.tip5')}
                    <select onChange={handleChangePageSize} className="custom-select custom-select-xs mx-2" value={state.body.length}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    {t('blocks.tip6')}
                  </div>
                  <Pager path="/blocks" current={state.body.start} total={totalPage} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
