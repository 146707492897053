import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';

import Pager from '../../components/Pager';
import Loading from '../../components/Loading';
import TimeTD from '../../components/TimeTD';
import LinkWithTooltip from '../../components/LinkWithTooltip';

import {useTranslation} from 'react-i18next';


export default function BlocksRewards() {
  const location = useLocation();
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});

  const {t} = useTranslation(['blocksRewards']); 

  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      length:  window.localStorage.getItem('pageLength') || '50',
    },
  });
  const [type, setType] = useState('showAge'); 
 
  const handleChangePageSize = e => {
    window.localStorage.setItem('pageLength', e.target.value);
    setState({...state, body: {...state.body, length: e.target.value}});
  };

  const blocksRequest = useRequest(
    body => ({
      url: '/blockBrowser/blockChain/block/blockRewardList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    blocksRequest.run(state.body);
  }, [state]);

  if (blocksRequest.loading) {
    return <Loading />;
  }

  const data = blocksRequest.data?.block_reward_list || [];
  const counts = blocksRequest.data?.counts || 0;
  const firstBlock = data[0] || {};
  const lastBlock = data[data.length - 1] || {};
  const totalPage = Math.ceil(Number(counts) / state.body.length);

  return (
    <main id="content" role="main">
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center py-3">
          <div className="mb-1 mb-md-0">
            <h1 className="h4 font-weight-normal mb-0">
              {t('blocksRewards.title')}&nbsp;<span className="small text-secondary"></span>
              <p className="mb-0 text-break small">
                <span className="small text-secondary"></span>
              </p>
            </h1>
          </div>
        </div>
      </div>
      <div className="container space-bottom-2">
        <div className="card">
          <div className="card-body">
            <div id="ContentPlaceHolder1_topPageDiv" className="d-md-flex justify-content-between mb-4">
              <p className="mb-2 mb-md-0">
                <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>
                {t('blocksRewards.tip1')} #{firstBlock.block_no}  {t('blocksRewards.tip2')} 
                 #{lastBlock.block_no} ( {t('blocksRewards.tip3')}  {counts}  {t('blocksRewards.tip4')} )
              </p>
              <Pager path="/blocks_rewards" current={state.body.start} total={totalPage} />
            </div>
            <div className="table-responsive mb-2 mb-md-0">
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{t('blocksRewards.table.Rank')}</th>
                    <th scope="col">{t('blocksRewards.table.Block')}</th> 
                    <th scope="col">{t('blocksRewards.table.BlockMiner')}</th> 
                    <th scope="col">
                    {
                          (type ==='showAge')?
                          <LinkWithTooltip placement="bottom" tooltip={t('blocksRewards.age.tip1')}>
                            <a href="#!" title="" onClick={e => {  e.preventDefault(); setType('showDate'); }} >
                            {t('blocksRewards.table.age')}
                            </a>
                          </LinkWithTooltip>
                          :
                          <LinkWithTooltip placement="bottom" tooltip={t('blocksRewards.age.tip2')}> 
                            <a href="#!" onClick={e => {  e.preventDefault(); setType('showAge'); }}  >
                            {t('blocksRewards.table.time')} (UTC)
                            </a>
                          </LinkWithTooltip>
                        }
                    </th> 
                    <th scope="col">{t('blocksRewards.table.BlockReward')}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(item => {
                    const percent = (item.gas_used / item.gas_limit) * 100;

                    return (
                      <tr key={item.block_no}>
                        <td>
                          {item.order_no}
                        </td>  
                        <td>
                          <a href={`/block/${item.block_no}`}>{item.block_no}</a>
                        </td> 
                        <td>
                            <LinkWithTooltip LinkWithTooltip placement="bottom" tooltip={`${item.block_miner}`}>  
                                <a className="hash-tag text-truncate" href={`/address/${item.block_miner}`} 
                                  data-toggle="tooltip" data-placement="bottom" title="" data-original-title={item.block_miner}>
                                  {item.block_miner}
                                </a>
                            </LinkWithTooltip>
                        </td>
                        
                        {/* <td>{item.block_time_interval}</td> */}
                        <TimeTD time={item.time_stamp} interval={item.block_time_interval} type={type} />
                        <td>
                          <a href={`/blockreward/${item.block_no}`}> {item.block_reward} HPB</a> 
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div id="ContentPlaceHolder1_pageRecords">
              <form method="post" action="./blocks" id="ctl00">
                <div className="d-md-flex justify-content-between my-3">
                  <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                    {t('blocksRewards.tip5')}
                    <select onChange={handleChangePageSize} className="custom-select custom-select-xs mx-2" value={state.body.length}>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    {t('blocksRewards.tip6')}
                  </div>
                  <Pager path="/blocks_rewards" current={state.body.start} total={totalPage} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
