import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';
import {useTranslation} from 'react-i18next';
import Pager from '../../components/Pager';
import Loading from '../../components/Loading';

import {compareObjAsc,compareObjDesc} from '../../commons/utils'

export default function TokentxnsNft() {
  const location = useLocation();

  const {t} = useTranslation(['tokensVolume']);

  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const [showSelectType, setShowSelectType] = useState(false);
  const [queryData, setQueryData] = useState([]); 
  const [sortBy, setSortBy] = useState('asc'); 
  const [sortType, setSortType] = useState('trade_volume'); 

  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      length: '50',
      time_interval: query.time_interval || 'all', // ['last_one_hour', 'last_one_day', 'last_seven_day', 'last_thirty_day']
    },
  });

  const handleChangePageSize = e => {
    setState({...state, body: {...state.body, length: e.target.value}});
  };

  const tokenListRequest = useRequest(
    body => ({
      url: '/blockBrowser/tokens/volume/tokenList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => { 
    tokenListRequest.run(state.body).then(res => { 
      setQueryData(res);
    });

  }, [state]);

  if (tokenListRequest.loading) {
    return <Loading />;
  }

  const data = queryData?.token_list || [];
  const counts = queryData?.counts || 0;
  const token_contracts_counts = queryData?.token_contracts_counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);

  const handleSortData = (e,data,type,sort) => { 
    e.preventDefault(); 
    if(sort === 'asc'){
      data.token_list.sort(compareObjAsc(type));
      setSortBy('desc');
    }else{ 
      data.token_list.sort(compareObjDesc(type));
      setSortBy('asc');
    }
    setSortType(type);
    setQueryData(data);
  }


  return (
    <main role="main">
      <div className="container py-3">
        <div className="d-sm-flex align-items-center">
          <div className="mb-2 mb-sm-0">
            <h1 id="ContentPlaceHolder1_h1Tag" className="h4 d-flex align-items-center mb-0">
               {t('tokensVolume.title')}
              <a
                className="u-label u-label--xs u-label--secondary rounded color-strong ml-2"
                href="https://docs.hpb.io/#/en-us/dev/hrc20"
                target="_blank"
                rel="nofollow"
                data-toggle="tooltip"
                title=""
                data-original-title="External link to HRC-20 Information"
              >
                HRC-20
              </a>
            </h1>
          </div>
        </div>
      </div>

      <div className="container space-bottom-2">
        <div className="card">
          <div className="card-header d-md-flex justify-content-between align-items-center">
            <div className="mb-1 mb-md-0">
              <h2 className="card-header-title">  {t('tokensVolume.title2')}</h2>
            </div>
          </div>
          <div className="card-body">
            <div id="ContentPlaceHolder1_divpagingpanel" className="d-md-flex justify-content-between align-items-lg-center mb-2">
              <p className="mb-2 mb-md-0">
                <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>
                {t('tokensVolume.tip1')} {token_contracts_counts} HRC-20  {t('tokensVolume.tip2')}
              </p>

              <nav className="mb-4 mb-md-0" aria-label="page navigation"></nav>
              {/* <div className="d-flex flex-wrap">
                <div className="position-relative mb-2 mb-md-0">
                  <a
                    id="selectType"
                    className="btn btn-xss btn-custom dropdown-toggle"
                    href="#"
                    role="button"
                    aria-controls="selectTypeButton"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-unfold-target="#selectTypeButton"
                    data-unfold-type="css-animation"
                    data-unfold-duration="300"
                    data-unfold-delay="300"
                    data-unfold-hide-on-scroll="false"
                    data-unfold-animation-in="slideInUp"
                    data-unfold-animation-out="fadeOut"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Select Interval (1h, 1d, 7d, 30d, All)"
                    onClick={e => {
                      e.preventDefault();
                      setShowSelectType(!showSelectType);
                    }}
                  >
                    Select Interval Type
                  </a>
                  <div
                    id="selectTypeButton"
                    className={`dropdown-menu dropdown-unfold u-unfold--css-animation ${showSelectType ? 'slideInUp' : 'u-unfold--hidden fadeOut'}`}
                    aria-labelledby="selectType"
                    style={{minWidth: '120px', animationDuration: '300ms', left: '0px'}}
                  >
                    <a className="dropdown-item" href="/tokens-volume" data-toggle="tooltip" data-original-title="" title="">
                      OverAll
                    </a>
                    <a className="dropdown-item" href="/tokens-volume?time_interval=last_one_hour" data-toggle="tooltip" data-original-title="" title="">
                      Last 1 Hour
                    </a>
                    <a className="dropdown-item" href="/tokens-volume?time_interval=last_one_day" data-toggle="tooltip" data-original-title="" title="">
                      Last 1 Day
                    </a>
                    <a className="dropdown-item" href="/tokens-volume?time_interval=last_seven_day" data-toggle="tooltip" data-original-title="" title="">
                      Last 7 Day
                    </a>
                    <a className="dropdown-item" href="/tokens-volume?time_interval=last_thirty_day" data-toggle="tooltip" data-original-title="" title="">
                      Last 30 Day
                    </a>
                  </div>
                </div>
                <div className="mr-2 mb-2 mb-md-0">
                  <h2 className="card-header-title"></h2>
                </div>
                <div className="mr-2 mb-2 mb-md-0"></div>
              </div> */}
            </div>
            <div className="table-responsive mb-2 mb-md-0">
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr role="row">
                    {/* <th scope="col" width="1" className="token-sticky-header sorting_disabled" rowSpan="1" colSpan="1" aria-label="#">
                      #
                    </th> */}
                    <th scope="col" className="token-sticky-header sorting_disabled" rowSpan="1" colSpan="1" aria-label="Token">
                      {t('tokensVolume.table.token')}
                    </th>
                    <th
                      scope="col"
                      className="text-nowrap sorting_desc"
                      tabIndex="0"
                      aria-controls="mytable1"
                      rowSpan="1"
                      colSpan="1"
                      aria-sort="descending"
                      aria-label="Transfer Volume: activate to sort column ascending"
                    >
                    <div className=""> 
                          <strong> 
                              {(sortType ==='trade_volume')?( sortBy==='asc'?<i className="fa fa-angle-down"></i>:<i className="fa fa-angle-up"></i>) :''} 
                          </strong>
                          &nbsp;
                          <a href="/#" data-toggle="tooltip"   onClick={((e) => handleSortData(e,queryData,'trade_volume',sortBy))}    data-original-title="Click for descending sort">
                          
                          {t('tokensVolume.table.transferVolume')}
                          </a>
                    </div>

                      
                    </th>
                    <th scope="col" className="text-nowrap sorting_disabled" rowSpan="1" colSpan="1" aria-label="Transfer Amount">
                     
                        <strong> 
                            {(sortType ==='trade_amount')?( sortBy==='asc'?<i className="fa fa-angle-down"></i>:<i className="fa fa-angle-up"></i>) :''} 
                        </strong>
                        &nbsp;
                        <a href="/#" data-toggle="tooltip"   onClick={((e) => handleSortData(e,queryData,'trade_amount',sortBy))}    data-original-title="Click for descending sort">
                        {t('tokensVolume.table.transferAmount')}
                        </a>
                    </th>
                    <th scope="col" className="text-nowrap sorting" tabIndex="0" aria-controls="mytable1" rowSpan="1" colSpan="1" aria-label="Unique Senders: activate to sort column ascending">
                     
                      {t('tokensVolume.table.senders')}
                    </th>
                    <th scope="col" className="text-nowrap sorting" tabIndex="0" aria-controls="mytable1" rowSpan="1" colSpan="1" aria-label="Unique Receivers: activate to sort column ascending">
                       {t('tokensVolume.table.receivers')}
                    </th>
                    <th scope="col" className="text-nowrap sorting" tabIndex="0" aria-controls="mytable1" rowSpan="1" colSpan="1" aria-label="Transfer Count: activate to sort column ascending">
                     
                      <strong> 
                            {(sortType ==='trade_count')?( sortBy==='asc'?<i className="fa fa-angle-down"></i>:<i className="fa fa-angle-up"></i>) :''} 
                        </strong>
                        &nbsp;
                        <a href="/#" data-toggle="tooltip"   onClick={((e) => handleSortData(e,queryData,'trade_count',sortBy))}    data-original-title="Click for descending sort">
                        {t('tokensVolume.table.transferCount')} 
                        </a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => {
                    return (
                      <tr role="row" key={i} className="odd">
                        {/* <td>{item.order_no}</td> */}
                        <td>
                          <div className="media">
                            <img className="u-xs-avatar mr-2" src={item.token_image ? item.token_image : '/images/main/empty-token.png'} />
                            <div className="media-body">
                              <h3 className="h6 mb-0">
                                <div className="token-wrap">
                                  <a className="text-primary" href={`/token/${item.token_address}`} data-toggle="tooltip" title="" data-original-title={item.token_address}>
                                    {item.token_name} ({item.token_symbol})
                                  </a>
                                </div>
                              </h3>
                            </div>
                          </div>
                        </td>
                        <td className="sorting_1">${item.trade_volume}</td>
                        <td>
                          {item.trade_amount} {item.token_symbol}
                        </td>
                        <td>{item.unique_senders}</td>
                        <td>{item.unique_receivers}</td>
                        <td>{item.trade_count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <form method="post">
              <div className="d-md-flex justify-content-between my-3">
                <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                  {t('tokensVolume.tip5')} 
                  <select onChange={handleChangePageSize} className="custom-select custom-select-xs mx-2" defaultValue="50">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  {t('tokensVolume.tip6')} 
                </div>
                <Pager path="/tokens-volume" current={state.body.start} total={totalPage} />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center text-secondary"></div>
                <div className="small text-muted"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}
