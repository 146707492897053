import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';
import {useParams} from 'react-router-dom';

import Pager from '../../components/Pager';
import Loading from '../../components/Loading';

export default function ValidatorSetSnapshot() {
  const {block_no} = useParams();
  const location = useLocation();
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const isValidator = block_no.indexOf('0x') === 0;
  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      length: '50',
      field: isValidator ? 'validator' : 'block_no',
      value: block_no,
    },
  });

  const handleChangePageSize = e => {
    setState({...state, body: {...state.body, length: e.target.value}});
  };

  const snapshotBlockRequest = useRequest(
    body => ({
      url: isValidator ? '/blockBrowser/validator/validatorSet/snapshotValidator' : '/blockBrowser/validator/validatorSet/snapshotBlock',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    snapshotBlockRequest.run(state.body);
  }, [state]);

  if (snapshotBlockRequest.loading) {
    return <Loading />;
  }

  const data = (isValidator ? snapshotBlockRequest.data?.snapshot_validator_list : snapshotBlockRequest.data?.snapshot_block_list) || [];
  const counts = snapshotBlockRequest.data?.counts || 0;
  const block_time = snapshotBlockRequest.data?.block_time || 0;
  const validators = snapshotBlockRequest.data?.validators || 0;
  const incoming = snapshotBlockRequest.data?.incoming || 0;
  const jailed = snapshotBlockRequest.data?.jailed || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);

  return (
    <main id="content" role="main">
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center py-3">
          <div className="mb-1 mb-md-0">
            <h1 className="h4 font-weight-normal mb-0">
              {isValidator ? 'Validators Set Info By Validator' : 'Validators Set Info By Block'}
              <p className="mb-0 text-break small">
                <span className="small text-secondary">
                  {isValidator ? (
                    <>
                      <i className="fal fa-user"></i> For Validator
                    </>
                  ) : (
                    'Snapshot taken at Block'
                  )}{' '}
                  <a href="/block/{block_no}">
                    <span className="text-primary text-break">{block_no}</span>
                  </a>
                  {isValidator ? undefined : <>({block_time})</>}
                </span>
              </p>
            </h1>
          </div>
        </div>
      </div>
      <div className="container space-bottom-2 mt-1">
        {isValidator ? undefined : (
          <div id="ContentPlaceHolder1_pnlStats" className="row">
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="small text-uppercase font-weight-medium text-secondary mr-1">
                      <i className="fal fa-user"></i> Validators
                    </h2>
                    <span className="btn btn-xs btn-soft-secondary btn-status py-1 px-2" title="Total Validator Count for this data snapshot">
                      <strong className="text-right">{validators} Validators</strong>{' '}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="small text-uppercase font-weight-medium text-secondary mr-1">
                      <i className="fal fa-inbox-in"></i> Incoming
                    </h2>
                    <span className="btn btn-xs btn-soft-secondary btn-status py-1 px-2" title="Total Incoming total of all validators">
                      <strong className="text-right">{incoming} HPB</strong>{' '}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="small text-uppercase font-weight-medium text-secondary mr-1">
                      <i className="fal fa-lock-alt"></i> Jailed
                    </h2>
                    <span className="btn btn-xs btn-soft-secondary btn-status py-1 px-2" title="Total validators jailed">
                      <strong className="text-right">{jailed} validators</strong>{' '}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="card">
          <div className="card-body">
            <div className="d-md-flex justify-content-between mb-4">
              <p className="mb-2 mb-md-0">
                <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>A total of {counts} validators set info found
              </p>

              <Pager current={state.body.start} total={totalPage} path={`/validatorset/snapshot/${block_no}`} />
            </div>
            <div className="table-responsive mb-2 mb-md-0">
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Consensus Address</th>
                    <th scope="col">Fee Address</th>
                    <th scope="col">Jailed</th>
                    <th scope="col">Incoming</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <a
                            href={`/validatorset/snapshot/${item.consensus_address}`}
                            className="hash-tag text-truncate"
                            data-toggle="tooltip"
                            title=""
                            data-original-title={`View Set Info for ${item.consensus_address}`}
                          >
                            <i className="fal fa-user"></i> {item.consensus_address}
                          </a>
                        </td>
                        <td>
                          <a href={`/address/${item.fee_address}`} className="hash-tag text-truncate" data-toggle="tooltip" title="" data-original-title={item.fee_address}>
                            {item.fee_address}
                          </a>
                        </td>
                        <td>{item.jailed === 0 ? 'No' : 'Yes'}</td>
                        <td>{item.incoming} HPB</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <form method="post" action="./{block_no}" id="ctl00">
              <div className="d-md-flex justify-content-between my-3">
                <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                  Show
                  <select className="custom-select custom-select-xs mx-2" defaultValue="50" onChange={handleChangePageSize}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  Records
                </div>
                <Pager current={state.body.start} total={totalPage} path={`/validatorset/snapshot/${block_no}`} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}
