import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';

import Pager from '../../components/Pager';
import Loading from '../../components/Loading';

export default function Validators() {
  const location = useLocation();
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      length: '50',
    },
  });

  const handleChangePageSize = e => {
    setState({...state, body: {...state.body, length: e.target.value}});
  };

  const validatorsListRequest = useRequest(
    body => ({
      url: '/blockBrowser/validator/validators/validatorsList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    validatorsListRequest.run(state.body);
  }, [state]);

  if (validatorsListRequest.loading) {
    return <Loading />;
  }

  const data = validatorsListRequest.data?.validators_list || [];
  const counts = validatorsListRequest.data?.counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);

  return (
    <main id="content" role="main">
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center py-3">
          <div className="mb-1 mb-md-0">
            <h1 className="h4 font-weight-normal mb-0">
              Validators Top Leaderboard (Blocks Validated)
              <p className="mb-0 text-break small">
                <span className="small text-secondary"></span>
              </p>
            </h1>
          </div>
        </div>
      </div>
      <div className="container space-bottom-2">
        <div className="card">
          <div className="card-body">
            <div className="table-responsive mb-2 mb-md-0">
              <div id="mytable1_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                <div className="row">
                  <div className="col-sm-12 col-md-6 mt-n2 mb-2 text-secondary">
                    <div className="dataTables_info" id="mytable1_info" role="status" aria-live="polite" style={{paddingTop: '0.85rem'}}>
                      Showing 1 to {counts} of {counts} validators found
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6"></div>
                </div>
                <table id="mytable1" className="table table-hover dataTable no-footer" aria-describedby="mytable1_info" role="grid">
                  <thead className="thead-light">
                    <tr role="row">
                      <th scope="col" className="text-right sorting_disabled" rowspan="1" colSpan="1" aria-label="Rank">
                        Rank
                      </th>
                      <th scope="col" className="sorting_disabled" rowspan="1" colSpan="1" aria-label="Address">
                        Address
                      </th>
                      <th
                        scope="col"
                        title="First block validated"
                        className="sorting"
                        tabindex="0"
                        aria-controls="mytable1"
                        rowspan="1"
                        colSpan="1"
                        aria-label="First Block: activate to sort column ascending"
                      >
                        First Block
                      </th>
                      <th
                        scope="col"
                        title="Latest block validated"
                        className="sorting"
                        tabindex="0"
                        aria-controls="mytable1"
                        rowspan="1"
                        colSpan="1"
                        aria-label="Last Block: activate to sort column ascending"
                      >
                        Last Block
                      </th>
                      <th
                        scope="col"
                        title="Count of blocks validated in the previous 1 day"
                        className="sorting"
                        tabindex="0"
                        aria-controls="mytable1"
                        rowspan="1"
                        colSpan="1"
                        aria-label="1 Day: activate to sort column ascending"
                      >
                        1 Day
                      </th>
                      <th
                        scope="col"
                        title="Count of blocks validated in the last 7 days"
                        className="sorting"
                        tabindex="0"
                        aria-controls="mytable1"
                        rowspan="1"
                        colSpan="1"
                        aria-label="7 Days: activate to sort column ascending"
                      >
                        7 Days
                      </th>
                      <th
                        scope="col"
                        title="Count of blocks validated in the last 30 days"
                        className="sorting_asc"
                        tabindex="0"
                        aria-controls="mytable1"
                        rowspan="1"
                        colSpan="1"
                        aria-sort="ascending"
                        aria-label="30 Days: activate to sort column descending"
                      >
                        30 Days
                      </th>
                      <th scope="col" className="sorting_disabled" rowspan="1" colSpan="1" aria-label="Active">
                        Active
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, i) => {
                      return (
                        <tr role="row" className="odd">
                          <td>
                            <div className="text-right text-secondary">
                              {i === 0 ? '🥇' : i === 1 ? '🥈' : i === 2 ? '🥉' : '#'} {i + 1}
                            </div>
                          </td>
                          <td>
                            <a href={`/address/${item.address}`} className="hash-tag text-truncate" data-toggle="tooltip" title="" data-original-title={item.address}>
                              {item.address}
                            </a>
                          </td>
                          <td>
                            <a href={`/block/${item.first_block}`}>{item.first_block}</a>
                          </td>
                          <td>
                            <a href={`/block/${item.last_block}`}>{item.last_block}</a>
                          </td>
                          <td>{item.one_days}</td>
                          <td>{item.seven_days}</td>
                          <td className="sorting_1">{item.thirty_days}</td>
                          <td>
                            <span className={`${item.active === '1' ? 'text-success' : 'text-danger'}`}>
                              <i className="fal fa-signal-alt-3"></i>
                              {item.active === '1' ? 'Yes' : 'No'}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <form method="post" action="./txsPending" id="ctl00">
                  <div className="d-md-flex justify-content-between my-3">
                    <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                      Show
                      <select className="custom-select custom-select-xs mx-2" onChange={handleChangePageSize} defaultValue="50">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      Records
                    </div>
                    <Pager current={state.body.start} total={totalPage} path="/validators" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
