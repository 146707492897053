const pending = {
    
    'pending.title': 'Pending Tx Query',
    'pending.palceholder.tip1': 'Please Input Tx Hash', 
    'pending.btnQuery': 'Query',
    'pending.tip1': 'The Transaction Has Not Entered The Node Or Has Been Excluded By MemPool Rules.',
    'pending.tip2': ' Go To The Link For A Solution.', 
    'pending.tip3': 'The Transaction Has Entered The Blockchain',
    

};

export default pending;
