import {useRequest} from 'ahooks';
import {useEffect, useState} from 'react';
import Loading from '../../components/Loading';
import Pager2 from '../../components/Pager2';
import {useTranslation} from 'react-i18next';
export default function Holders({a, token, overview}) {
  const {t} = useTranslation(['token']);
  const [state, setState] = useState({
    body: {
      start: '1',
      length: '25',
      field: a ? 'address' : undefined,
      value: a,
      token_address: token,
      token_types: overview.token_types,
    },
  });
  const tokenHolderRequest = useRequest(
    body => ({
      url: '/blockBrowser/tokens/address/tokenHolder',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  const handleCurrentPage = (page) =>{     
    setState({...state, body: {...state.body, start:page + ''}}); 
  }

  useEffect(() => {
    tokenHolderRequest.run(state.body);
  }, [state]);

  if (tokenHolderRequest.loading) {
    return <Loading />;
  }

  const data = tokenHolderRequest.data?.token_holder_list || [];
  const counts = tokenHolderRequest.data?.counts || 0;
  const token_holder_counts = tokenHolderRequest.data?.token_holder_counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);

  return (
    <div id="maintable">
     {/*  <div className="d-lg-flex justify-content-between align-items-center mb-2">
        <a id="linkTokenHoldersChart" className="btn btn-xss btn-soft-primary" href={`/token/tokenholderchart/${token}`} target="_parent">
          <i className="fa fa-chart-pie mr-1"></i>Token Holders Chart
        </a>
      </div> */}
      <div className="d-md-flex justify-content-between mb-4">
        <p className="mb-2 mb-md-0">
          <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>{t('token.holders.tip1')} {token_holder_counts} {t('token.holders.tip2')} 
        </p>
        <Pager2 current={state.body.start} total={totalPage} pageChange= {handleCurrentPage}  />
      </div>
      <div>
        <table className="table table-md-text-normal table-hover">
          <thead className="thead-light">
            <tr>
              <th scope="col" width="1">
              {t('token.holders.table.Rank')}
              </th>
              <th scope="col">{t('token.holders.table.Address')}</th>
              <th scope="col">{t('token.holders.table.Quantity')}</th>
              <th scope="col"> {t('token.holders.table.Percentage')}</th>
              <th id="col_analytics_Title" scope="col">
                {t('token.holders.table.Analytics')}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{item.order_no}</td>
                  <td>
                    <span>
                      {/** 持有人类型,1-account 2-contract,便于是否显示图标 */}
                      {item.holder_type === 2 ? <i className="far fa-file-alt text-secondary" data-toggle="tooltip" title="" data-original-title="Contract"></i> : undefined}
                      <a href={`/token/${token}?a=${item.holder_address}`} target="_parent">
                        {item.holder_address}
                      </a>
                    </span>
                  </td>
                  <td>{item.quantity}</td>
                  <td>
                    {item.percentage}
                    <div className="progress mt-1" style={{height: '2px'}}>
                      <div className="progress-bar bg-primary" role="progressbar" style={{width: item.percentage}} aria-valuenow="0.0000" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </td>
                  <td>
                    <a href={`/token/${token}?a=${item.holder_address}#tokenAnalytics`} target="_parent">
                      <i className="ml-2 fas fa-chart-line fa-lg"></i>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="d-flex justify-content-md-end align-items-center text-secondary mb-2 mb-md-0 my-3">
          <div className="d-inline-block">
          <Pager2 current={state.body.start} total={totalPage} pageChange= {handleCurrentPage}  />
          </div>
        </div>
      </div>
    </div>
  );
}
