import {useState, useEffect,useContext} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router';
import qs from 'qs';
import VerifyCode from '../../components/VerifyCode';
import UserContext from '../../UserContext';
import { encrypt } from '../../commons/utils';
import {useTranslation} from 'react-i18next';
/**
 * 
 * @returns 账号登录
 */
function UserLogin(){

  const {t} = useTranslation(['login']);
  const userContext = useContext(UserContext); 
  const [form, setForm] = useState({
    userName: '',
    password: '',
    verifyCode:'',
    captchaId: '',
    autologin: false,
  });

  const loginRequest = useRequest(

    body => ({
      url: '/loginapi/sys/login/signIn',
      method: 'post',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );

  const [errors, setErrors] = useState({});

  
  const handleUpdateVerifyCode = e => {
    setForm({...form, ...e});

    if (e.verify_code) {
      setErrors({...errors, verify_code: undefined});
    }
  };


  const handleForm = field => e => {
    setForm({...form, [field]: e.target.value});

    if (e.target.value) {
      setErrors({...errors, [field]: undefined});
    }
  }; 

  const handleBlur = key => () => {
    const _errors = {};

    Object.keys(form)
      .filter(f => f !== 'autologin')
      .forEach(field => {
        _errors[field] = !form[field];
      });

    if (Object.keys(_errors).some(e => !!_errors[e])) {
      if (_errors[key]) {
        setErrors(_errors);
      }
    }
  };

  const handleSubmit = () => {
    const _errors = {};

    Object.keys(form)
      .filter(f => f !== 'autologin')
      .forEach(field => {
        _errors[field] = !form[field];
      });

    if (Object.keys(_errors).some(e => !!_errors[e])) {
      setErrors(_errors);
    } else { 
      const formEncrpty = {
        userName: encrypt(form.userName),
        password: encrypt(form.password),
        verifyCode: encrypt(form.verifyCode),
        captchaId: encrypt(form.captchaId),
      }
      loginRequest.run({...formEncrpty, autologin: undefined}).then(res => { 
        if(res.code === 200){
          if (res.data) { 
            window.localStorage.setItem('token', res.data.token);
            window.location.href = '/';
          }
        }
       
      });
    }
  };

  const showError = typeof loginRequest.data?.code !== 'undefined' && loginRequest.data?.code !== 200;


  return (
    <div>

      <div className="alert alert-danger" style={{display: showError ? 'block' : 'none'}}>
            {loginRequest.data?.message}
      </div>


      <div className={`js-form-message form-group ${errors.userName ? 'u-has-error' : ''}`}>
        <span className="d-flex justify-content-between align-items-center">
          
          {t('login.userlogin.Username')} 
            <a className="link-muted font-size-1" href="/resendemail" tabIndex="0"> 
              {t('login.userlogin.ResendEmail')} 
            </a>
        </span>
        <input
          id="txtUserName"
          type="text"
          maxLength="50"
          tabIndex="1"
          className="form-control form-control-sm"
          required=""
          data-error-class="u-has-error"
          data-success-class="u-has-success"
          placeholder={t('login.userlogin.Username')} 
          data-msg="Username is required."
          value={form.userName}
          onChange={handleForm('userName')}
          onBlur={handleBlur('userName')}
        />
        <div className="invalid-feedback" style={{display: errors.userName ? 'block' : 'none'}}>
          {t('login.userlogin.Usernamerequired')} 
        </div>
      </div>
     
      <div className={`js-form-message form-group ${errors.password ? 'u-has-error' : ''}`}>
        <label className="d-block" htmlFor="txtPassword">
          <span className="d-flex justify-content-between align-items-center">
            
            {t('login.userlogin.Password')} 
          {/*   <a className="link-muted font-size-1" href="/lostpassword" tabIndex="0">
              Forgot your password?
            </a> */}
          </span>
        </label>
        <input
          type="password"
          maxLength="75"
          tabIndex="2"
          className="form-control form-control-sm"
          ria-label="********"
          required=""
          data-error-class="u-has-error"
          data-success-class="u-has-success"
          placeholder={t('login.userlogin.Username')} 
          data-msg="Your password is invalid. Please try again."
          value={form.password}
          onChange={handleForm('password')}
          onBlur={handleBlur('password')}
        />
        <div className="invalid-feedback" style={{display: errors.password ? 'block' : 'none'}}>
           
          {t('login.userlogin.Passwordinvalid')} 
        </div>
      </div>

      <div className={`js-form-message form-group ${errors.captcha ? 'u-has-error' : ''}`}>
        <label className="d-block" htmlFor="captcha">
          <span className="d-flex justify-content-between align-items-center">
          {t('login.userlogin.Captcha')} 
          </span>
        </label>
        <VerifyCode  tabIndex="3" value={form.verifyCode} onChange={handleUpdateVerifyCode} />
        <div className="invalid-feedback" style={{display: errors.verifyCode ? 'block' : 'none'}}>
            {t('login.userlogin.Captchainvalid')}
         </div>
      </div>


    
      <div className="d-flex justify-content-center my-2">
        <input name="g-recaptcha-response" style={{visibility: 'hidden'}} />
      </div>
      <div className="row align-items-center">
        <div className="col-5 col-sm-6">
          <span className="text-muted text-nowrap">  {t('login.userlogin.tip1')}</span>
          <a href="/register" className="text-nowrap">
            {t('login.userlogin.tip2')}
          </a>
        </div>
        <div className="col-7 col-sm-6 text-right">
          <input type="button" onClick={handleSubmit} disabled={loginRequest.loading} value={t('login.userlogin.LOGIN')} className="btn btn-sm btn-primary" />
        </div>
      </div>
        
    </div> 
  )
}

/**
 * 
 * @returns 随机数登录
 */
function RandomLogin(){
  const {t} = useTranslation(['login']);
  const [form, setForm] = useState({
    userName: '',
    random: '',
  });

  const loginRequest = useRequest(
    body => ({
      url: '/loginapi/sys/login/signRandom',
      method: 'post',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );

  const [errors, setErrors] = useState({});

  const handleForm = field => e => {
    setForm({...form, [field]: e.target.value});

    if (e.target.value) {
      setErrors({...errors, [field]: undefined});
    }
  }; 

  const handleBlur = key => () => {
    const _errors = {};
    Object.keys(form).filter(f => f !== 'autologin')
     .forEach(field => {
        _errors[field] = !form[field];
      });
    if (Object.keys(_errors).some(e => !!_errors[e])) {
      if (_errors[key]) {
        setErrors(_errors);
      }
    }

  };

  const handleSubmit = () => {
    const _errors = {};
    Object.keys(form).filter(f => f !== 'autologin')
      .forEach(field => {
        _errors[field] = !form[field];
      });

    if (Object.keys(_errors).some(e => !!_errors[e])) {
      setErrors(_errors);
    } else { 
      const formEncrpty = {
        userName: encrypt(form.userName),
        random: encrypt(form.random)
      }

      loginRequest.run({...formEncrpty, autologin: undefined}).then(res => {
        console.log(res);
        if(res.code ===200){
          if (res.data) {
            window.localStorage.setItem('token', res.data.token);
            window.location.href = '/';
          }
        }
       
      });
    }
  };


  const showError = typeof loginRequest.data?.code !== 'undefined' && loginRequest.data?.code !== 0;


  return (
    <div>
      <div className="alert alert-danger" style={{display: showError ? 'block' : 'none'}}>
            {loginRequest.data?.message}
      </div>

      <div className={`js-form-message form-group ${errors.userName ? 'u-has-error' : ''}`}>
        <label className="d-block" htmlFor="txtUserName">
        {t('login.userlogin.Username')}
        </label>
        <input
          type="text"
          maxLength="50"
          tabIndex="1"
          className="form-control form-control-sm"
          required=""
          data-error-class="u-has-error"
          data-success-class="u-has-success"
          placeholder={t('login.userlogin.Username')}
          data-msg="Username is required."
          value={form.userName}
          onChange={handleForm('userName')}
          onBlur={handleBlur('userName')}
        />
        <div className="invalid-feedback" style={{display: errors.userName ? 'block' : 'none'}}>
         {t('login.userlogin.Usernamerequired')}
        </div>
      </div>
      <div className={`js-form-message form-group ${errors.random ? 'u-has-error' : ''}`}>
        <label className="d-block" htmlFor="random">
          <span className="d-flex justify-content-between align-items-center">
             {t('login.random.Random')}
          </span>
        </label>
        <input type="text"  maxLength="75" tabIndex="3" className="form-control form-control-sm"   required=""
          data-error-class="u-has-error"   data-success-class="u-has-success"
          placeholder= {t('login.random.Random')}   data-msg="Your Random is invalid. Please try again."
          value={form.random}  onChange={handleForm('random')}  onBlur={handleBlur('random')}
        />
        <div className="invalid-feedback" style={{display: errors.random ? 'block' : 'none'}}>
         {t('login.random.Randominvalid')}
        </div>
      </div> 
      <div className="d-flex justify-content-center my-2">
        <input name="g-recaptcha-response" style={{visibility: 'hidden'}} />
      </div>
      <div className="row align-items-center">
        <div className="col-5 col-sm-6">
          <span className="text-muted text-nowrap">{t('login.userlogin.tip1')}</span>
          <a href="/register" className="text-nowrap">{t('login.userlogin.tip2')}</a>
        </div>
        <div className="col-7 col-sm-6 text-right">
          <input type="button" onClick={handleSubmit} disabled={loginRequest.loading} value={t('login.userlogin.LOGIN')} className="btn btn-sm btn-primary" />
        </div>
      </div>
    </div> 
  )
}


export default function Login() {
  const {t} = useTranslation(['login']);
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(location.hash.slice(1) || 'userLogin');  

  const userContext = useContext(UserContext);
  const user = userContext.user || {};

  const logOutRequest = useRequest(
    body => ({
      url: '/loginapi/sys/login/logout',
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
      'X-Access-Token': (user.token ||'')
      },
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );


  const contractTabsConst = [
    {
      key: 'userLogin',
      title: t('login.tab.title1'),
    },  
    {
      key: 'randomLogin',
      title:  t('login.tab.title2'),
    }
  ];

  useEffect(() => {
    const query = qs.parse(location.search, {ignoreQueryPrefix: true}); 
    if (query.cmd === 'logout') {
      logOutRequest.run().then(res => {  
        window.localStorage.removeItem('token');
        window.location.href = '/login';
      });  
    }

  }, []);


  return (
    <main id="content" role="main">
      <div className="container space-2">
        <form method="post" className="js-validate w-md-75 w-lg-50 mx-md-auto" noValidate="novalidate">
          <div className="mb-4">
            <h2 className="h3 text-primary font-weight-normal mb-0">
               <span className="font-weight-semi-bold">{t('login.title')}</span>
            </h2>
            <p>{t('login.title2')}</p>
          </div>
         
          <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center p-0">
            <ul className="nav nav-custom nav-borderless nav_tabs1" role="tablist">
              {contractTabsConst.filter(Boolean).map(tab => {
                return (
                  <li className="nav-item" key={tab.key}>
                    <a
                      className={`nav-link ${tab.key === currentTab ? 'active' : ''}`}
                      href={`#${tab.key}`}
                      data-toggle="tab"
                      onClick={() => {
                        setCurrentTab(tab.key);
                      }}
                    >
                      {tab.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="card-body">
            <div className="tab-content">
              {currentTab === 'userLogin' ?<UserLogin />:<RandomLogin />}                    
            </div>
          </div>
          </div>
        
   
        </form>
      </div>
    </main>
  );
}
