import { getWallet,switchNetWork } from '../../commons/utils';
import {useTranslation} from 'react-i18next';
export default function Footer() {
  const {t} = useTranslation(['common']);

  const addHpbNetWrod = e => { 
      e.preventDefault();
      getWallet(); 
  };

  return (
    
    <footer id="Footer" className="bg-img-hero-footer bg-dark py-4" style={{backgroundImage: 'url(/images/svg/components/bg-shape.svg?v=2)'}}>
      <div className="container">
        <div className="row justify-content-sm-between align-items-center">
          <div className="col-sm-6 mb-3 mb-sm-0">
            <div className="d-flex align-items-center">
              <img width="16" src="/images/logo.svg?v=1.3" alt="HPB Logo" />
              <span className="h5 text-white mb-0 ml-3">{t('common.footer.powered')}</span>
            </div>
          </div>
          <div className="col-sm-6 align-self-bottom">
            <div className="d-flex justify-content-sm-end align-items-center">
              <a className="btn btn-xss btn-soft-light mr-2" href="#!" onClick={addHpbNetWrod}>
                <img src="/images/svg/metamask.svg"  width="24px" height="24px" alt="hpb" />{t('common.footer.network')}  
              </a>
              <a className="btn btn-xss btn-soft-light mr-2" href="/settings">
                <i className="fa fa-cogs mr-1"></i>{t('common.footer.preferences')}
              </a>
               {/*
              <button id="darkModaBtn" type="button" data-toggle="tooltip" data-title="Day/Night Mode" className="btn btn-sm btn-icon btn-soft-light" data-original-title="" title="">
                <i id="darkModaBtnIcon" className="fa fa-moon"></i>
              </button> */}
            </div>
          </div>
        </div>
         
        <hr className="opacity-md" />
        <div className="row justify-content-between align-items-center font-size-1">
          <div className="col-md-6 mb-2 mb-md-0 d-flex">
            <p className="mb-0 text-white">
              {/* HPB © 2021 (hpb.io)
              <span className="mx-1">|</span> HPB Foundation. All Rights Reserved.{' '} */}
              <a className="text-primary" href="https://hpb.io/" target="_blank">
                <b> Copyright HScan Team. All Rights Reserved.</b>
              </a>
            </p>
          </div>
          {/* <div className="col-md-6 text-md-right">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <a className="unordered-list-text" rel="nofollow noopener" target="_blank" href="https://hpb.io/">
                  Network Status
                </a>
              </li>
              <li className="list-inline-item">
                <a className="btn btn-sm btn-icon btn-soft-light btn-pill" href="/contactus" data-toggle="tooltip" data-placement="top" title="" data-original-title="Contact Us">
                  <i className="far fa-envelope btn-icon__inner"></i>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </footer>
  );
}
