import {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
export default function Apis() {
  const {t} = useTranslation(['hpbApi']);
  const location = useLocation();
  const [hash, setHash] = useState(location.hash.slice(1));
  console.log(location);
  const updatehash = hash => e => {
    setHash(hash);
  };

  return (
    <main id="content" role="main">
      <div class="container py-5">
        <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-4">
          <h1 class="h2">{t('hpbApi.title')}</h1>
        </div>

        <div class="w-lg-75 mx-md-auto mb-4">
          <div class="row">
            <div class="col-6 col-sm-3 mb-5 mb-sm-0">
              <div class="text-center">
                <figure class="u-avatar mx-auto mb-3">
                  <img src="/images/svg/icons/icon-1.svg" alt="SVG" />
                </figure>
                <h3 class="h6 font-weight-normal">{t('hpbApi.tip1')}</h3>
              </div>
            </div>
            <div class="col-6 col-sm-3 mb-5 mb-sm-0">
              <div class="text-center">
                <figure class="u-avatar mx-auto mb-3">
                  <img src="/images/svg/icons/icon-21.svg" alt="SVG" />
                </figure>
                <h3 class="h6 font-weight-normal">{t('hpbApi.tip2')}</h3>
              </div>
            </div>
            <div class="col-6 col-sm-3">
              <div class="text-center">
                <figure class="u-avatar mx-auto mb-3">
                  <img src="/images/svg/icons/icon-2.svg" alt="SVG" />
                </figure>
                <h3 class="h6 font-weight-normal">{t('hpbApi.tip3')}</h3>
              </div>
            </div>
            <div class="col-6 col-sm-3">
              <div class="text-center">
                <figure class="u-avatar mx-auto mb-3">
                  <img src="/images/svg/icons/icon-25.svg" alt="SVG" />
                </figure>
                <h3 class="h6 font-weight-normal">{t('hpbApi.tip4')}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="list-group list-group-lg mb-3 nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <a
                class={`list-group-item list-group-item-action ${hash === '' ? 'active' : ''}`}
                id="tab-intro"
                data-toggle="tab"
                href="#intro"
                role="tab"
                aria-controls="intro"
                aria-selected="false"
                onClick={updatehash('')}
              >
                {t('hpbApi.Introduction')}
              </a>
              <a
                class={`list-group-item list-group-item-action ${hash === 'accounts' ? 'active' : ''}`}
                id="accounts-tab"
                data-toggle="tab"
                href="#accounts"
                role="tab"
                aria-controls="accounts"
                aria-selected="false"
                onClick={updatehash('accounts')}
              >
                 {t('hpbApi.Accounts')}
              </a>
              <a
                class={`list-group-item list-group-item-action ${hash === 'contracts' ? 'active' : ''}`}
                id="contracts-tab"
                data-toggle="tab"
                href="#contracts"
                role="tab"
                aria-controls="contracts"
                aria-selected="false"
                onClick={updatehash('contracts')}
              >
                 {t('hpbApi.Contracts')}
              </a>
              <a
                class={`list-group-item list-group-item-action ${hash === 'transaction' ? 'active' : ''}`}
                id="transaction-tab"
                data-toggle="tab"
                href="#transaction"
                role="tab"
                aria-controls="transaction"
                aria-selected="false"
                onClick={updatehash('transaction')}
              >
                 {t('hpbApi.Transaction')}
              </a>
              <a
                class={`list-group-item list-group-item-action ${hash === 'blocks' ? 'active' : ''}`}
                id="blocks-tab"
                data-toggle="tab"
                href="#blocks"
                role="tab"
                aria-controls="blocks"
                aria-selected="false"
                onClick={updatehash('blocks')}
              >
                {t('hpbApi.Block')} 
              </a>            
              
               <a
                class={`list-group-item list-group-item-action ${hash === 'logs' ? 'active' : ''}`}
                id="logs-tab"
                data-toggle="tab"
                href="#logs"
                role="tab"
                aria-controls="logs"
                aria-selected="false"
                onClick={updatehash('logs')}
              > 
                {t('hpbApi.Log')} 
              </a>              
              
              <a
                class={`list-group-item list-group-item-action ${hash === 'Proxy' ? 'active' : ''}`}
                id="Proxy-tab"
                data-toggle="tab"
                href="#Proxy"
                role="tab"
                aria-controls="Proxy"
                aria-selected="false"
                onClick={updatehash('Proxy')}
              >{t('hpbApi.Proxy')}  
              </a>

              <a
                class={`list-group-item list-group-item-action ${hash === 'tokens' ? 'active' : ''}`}
                id="tokens-tab"
                data-toggle="tab"
                href="#tokens"
                role="tab"
                aria-controls="tokens"
                aria-selected="false"
                onClick={updatehash('tokens')}
              >
                {t('hpbApi.Tokens')}  
              </a>
              <a
                class={`list-group-item list-group-item-action ${hash === 'stats' ? 'active' : ''}`}
                id="stats-tab"
                data-toggle="tab"
                href="#stats"
                role="tab"
                aria-controls="stats"
                aria-selected="false"
                onClick={updatehash('stats')}
              >    {t('hpbApi.Stats')}  
                
              </a>
              <a
                class={`list-group-item list-group-item-action ${hash === 'rpc' ? 'active' : ''}`}
                id="rpc-tab"
                data-toggle="tab"
                href="#rpc"
                role="tab"
                aria-controls="rpc"
                aria-selected="true"
                onClick={updatehash('rpc')}
              >
                {t('hpbApi.RPC')}    
              </a> 
              <a
                class={`list-group-item list-group-item-action ${hash === 'randomNumber' ? 'active' : ''}`}
                id="randomNumber-tab"
                data-toggle="tab"
                href="#randomNumber"
                role="tab"
                aria-controls="randomNumber"
                aria-selected="true"
                onClick={updatehash('randomNumber')}
              > {t('hpbApi.RandomNumber')}   
               
              </a>
            </div>
          </div>

          <div class="col-md-9">
            <div class="tab-content" id="v-pills-tabContent">
              <div class={`card tab-pane fade ${hash === '' ? 'active show' : ''}`} id="intro" role="tabpanel" aria-labelledby="tab-intro">
                <div class="card-header">
                  <h2 class="h5 mb-0">{t('hpbApi.Introduction')}</h2>
                </div>
                <div class="card-body px-4">
                  <br />
                  <p>
                    {t('hpbApi.Introduction.tip1')}
                  </p>
                  <p>{t('hpbApi.Introduction.tip2')}</p>
                  <br />
                </div>
              </div>

              <div class={`card tab-pane fade ${hash === 'accounts' ? 'active show' : ''}`} id="accounts" role="tabpanel" aria-labelledby="accounts-tab">
                <div class="card-header">
                  <h2 class="h5 mb-0">{t('hpbApi.Accounts')} APIs</h2>
                </div>
                <div class="card-body px-4">
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Accounts.tip1')}</h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=balance&address=0x8b62802d9f908a2ea380dbfc02a83fa74adf68f7&tag=latest&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=account&action=balance&address=0x8b62802d9f908a2ea380dbfc02a83fa74adf68f7&tag=latest&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Accounts.tip2')}</h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=balancemulti&address=0xc9121e476155ebf0b794b7b351808af3787e727d,0x63a9975ba31b0b9626b34300f7f627147df1f526,0x198ef1ec325a96cc354c7266a038be8b5c558f67&tag=latest&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                        https://hscan.org/api?module=account&action=balancemulti&address=0xc9121e476155ebf0b794b7b351808af3787e727d,0x63a9975ba31b0b9626b34300f7f627147df1f526,0x198ef1ec325a96cc354c7266a038be8b5c558f67&tag=latest&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                    <p class="mb-0">{t('hpbApi.Accounts.tip3')}</p>
                  </div>


                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Accounts.tip4')}</h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=balance&address=0x8b62802d9f908a2ea380dbfc02a83fa74adf68f7&tag=latest&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=account&action=balance&address=0x8b62802d9f908a2ea380dbfc02a83fa74adf68f7&tag=latest&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>  
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Accounts.tip5')}</h3>
                    <p class="mb-0">{t('hpbApi.Accounts.tip6')}</p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=txlist&address=0xf8975d8e203d5494739446377df4a1dc6a5cc516&startblock=13649070&endblock=13649110&apikey=9B64C60C3199621FE9108F5CCD87EC1F&sort=asc">
                      https://hscan.org/api?module=account&action=txlist&address=0xf8975d8e203d5494739446377df4a1dc6a5cc516&startblock=13649070&endblock=13649110&apikey=9B64C60C3199621FE9108F5CCD87EC1F&sort=asc
                      </a>
                    </pre> 
                    <p class="mb-0">({t('hpbApi.Accounts.tip7')})</p>
                    <p class="mb-0">
                      <strong>{t('hpbApi.Accounts.tip8')}</strong>
                    </p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=txlist&address=0xf8975d8e203d5494739446377df4a1dc6a5cc516&startblock=13649000&endblock=13649110&page=0&offset=100&sort=asc&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=account&action=txlist&address=0xf8975d8e203d5494739446377df4a1dc6a5cc516&startblock=13649000&endblock=13649110&page=0&offset=100&sort=asc&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                    <p class="mb-0">({t('hpbApi.Accounts.tip9')})</p>
                  </div>
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Accounts.tip10')}</h3>
                    <p class="mb-0">{t('hpbApi.Accounts.tip6')}</p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=txlistinternal&address=0x46755f3b26e86287ac564a1960acff317ba944af&startblock=13636400&endblock=13649110&sort=asc&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=account&action=txlistinternal&address=0x46755f3b26e86287ac564a1960acff317ba944af&startblock=13636400&endblock=13649110&sort=asc&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre> 
                    <p class="mb-0">({t('hpbApi.Accounts.tip7')})</p>
                    <p class="mb-0">
                      <strong>or</strong>
                    </p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=txlistinternal&address=0x46755f3b26e86287ac564a1960acff317ba944af&startblock=13636400&endblock=13649110&sort=asc&page=0&offset=10&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=account&action=txlistinternal&address=0x46755f3b26e86287ac564a1960acff317ba944af&startblock=13636400&endblock=13649110&sort=asc&page=0&offset=10&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                    <p class="mb-0">({t('hpbApi.Accounts.tip9')})</p>
                  </div>
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Accounts.tip11')}</h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=txlistinternal&txhash=0x9d3eb3db57dac4beca27e42139d39562cfed21c8ea943781b049875e27f57e50&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=account&action=txlistinternal&txhash=0x9d3eb3db57dac4beca27e42139d39562cfed21c8ea943781b049875e27f57e50&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre> 
                    <p class="mb-0">({t('hpbApi.Accounts.tip7')})</p>
                  </div>
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Accounts.tip12')}</h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=txlistinternal&startblock=13636400&endblock=13649110&sort=asc&page=0&offset=10&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=account&action=txlistinternal&startblock=13636400&endblock=13649110&sort=asc&page=0&offset=10&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                    <p class="mb-0">({t('hpbApi.Accounts.tip7')})</p>
                  </div>
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Accounts.tip13')}</h3>
                    <p class="mb-0">{t('hpbApi.Accounts.tip6')}k: ending blockNo to retrieve results</p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=tokentx&address=0x46755f3b26e86287ac564a1960acff317ba944af&startblock=13636400&endblock=13636500&sort=asc&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=account&action=tokentx&address=0x46755f3b26e86287ac564a1960acff317ba944af&startblock=13636400&endblock=13636500&sort=asc&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                    <p class="mb-0">({t('hpbApi.Accounts.tip7')})</p>
                    <p class="mb-0">
                      <strong>or</strong>
                    </p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=tokentx&address=0xe21a65491d925f8f66c4b254664a6b0bf08bb5a6&startblock=13654103&endblock=13654200&sort=asc&page=0&offset=2&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=account&action=tokentx&address=0xe21a65491d925f8f66c4b254664a6b0bf08bb5a6&startblock=13654103&endblock=13654200&sort=asc&page=0&offset=2&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                    <p class="mb-0">({t('hpbApi.Accounts.tip9')})</p>
                    <p class="mb-0">
                      <strong>or</strong>
                    </p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=tokentx&address=0x46755f3b26e86287ac564a1960acff317ba944af&contractaddress=0xcabf7e4c9d28f4957ba6f5558f81ef833fc2914e&startblock=13636400&endblock=13636500&sort=asc&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=account&action=tokentx&address=0x46755f3b26e86287ac564a1960acff317ba944af&contractaddress=0xcabf7e4c9d28f4957ba6f5558f81ef833fc2914e&startblock=13636400&endblock=13636500&sort=asc&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                    <p class="mb-0">({t('hpbApi.Accounts.tip14')})</p>
                  </div>





                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Accounts.tip15')}</h3>
                    <p class="mb-0">{t('hpbApi.Accounts.tip6')}</p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=tokennfttx&contractaddress=0x054b4afc2d71ca2042a80632cac472c06c14962d&startblock=13008800&endblock=13009000&sort=asc&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=account&action=tokennfttx&contractaddress=0x054b4afc2d71ca2042a80632cac472c06c14962d&startblock=13008800&endblock=13009000&sort=asc&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                    <p class="mb-0">({t('hpbApi.Accounts.tip7')})</p>
                    <p class="mb-0">
                      <strong>{t('hpbApi.Accounts.tip8')}</strong>
                    </p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=tokennfttx&contractaddress=0x054b4afc2d71ca2042a80632cac472c06c14962d&startblock=13008800&endblock=13109000&page=0&offset=10&sort=asc&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=account&action=tokennfttx&contractaddress=0x054b4afc2d71ca2042a80632cac472c06c14962d&startblock=13008800&endblock=13109000&page=0&offset=10&sort=asc&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                    <p class="mb-0">({t('hpbApi.Accounts.tip9')})</p> 
                  </div> 



                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Accounts.tip16')}</h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=getminedblocks&address=0xb4270c32b5d4a6b16f31883ddde380d1f5d83af3&blocktype=blocks&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=account&action=getminedblocks&address=0xb4270c32b5d4a6b16f31883ddde380d1f5d83af3&blocktype=blocks&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                    <p class="mb-0">
                      <strong>{t('hpbApi.Accounts.tip8')}</strong>
                    </p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=getminedblocks&address=0xb4270c32b5d4a6b16f31883ddde380d1f5d83af3&blocktype=blocks&page=1&offset=10&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                        https://hscan.org/api?module=account&action=getminedblocks&address=0xb4270c32b5d4a6b16f31883ddde380d1f5d83af3&blocktype=blocks&page=1&offset=10&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                    <p class="mb-0">({t('hpbApi.Accounts.tip9')})</p>
                    {/* <p class="mb-0">
                      ** type = <strong>blocks</strong> (full blocks only)
                    </p> */}
                  </div>
                </div>
              </div>

              <div class={`card tab-pane fade ${hash === 'contracts' ? 'active show' : ''}`} id="contracts" role="tabpanel" aria-labelledby="contracts-tab">
                <div class="card-header">
                  <h2 class="h5 mb-0">{t('hpbApi.Contracts')} APIs</h2>
                </div>
                <div class="card-body px-4">
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">
                      {t('hpbApi.Contracts.tip1')}
                    </h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=contract&action=getabi&address=0xefc5b320d4461f302190d458fd7e74cb8899cd1e&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                          https://hscan.org/api?module=contract&action=getabi&address=0xefc5b320d4461f302190d458fd7e74cb8899cd1e&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>

                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">
                        {t('hpbApi.Contracts.tip2')}
                    </h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=contract&action=getsourcecode&address=0xefc5b320d4461f302190d458fd7e74cb8899cd1e&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=contract&action=getsourcecode&address=0xefc5b320d4461f302190d458fd7e74cb8899cd1e&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>
                 
                  {/* <div class="mb-4">
                    <p class="mb-0">A simple sample for retrieving the contractABI using Web3.js and Jquery to interact with a contract</p>
                    <pre class="bg-light border rounded p-3 mb-2">
                      {`    var Web3 = require('web3');
    var web3 = new Web3(new Web3.providers.HttpProvider());
    var version = web3.version.api;
            
    $.getJSON('https://api.hpb.io/api?module=contract&action=getabi&address=0xc9121e476155ebf0b794b7b351808af3787e727d&apikey=YourApiKeyToken', function (data) {
    var contractABI = "";
        contractABI = JSON.parse(data.result);
        if (contractABI != ''){
            var MyContract = web3.eth.contract(contractABI);
            var myContractInstance = MyContract.at("0xc9121e476155ebf0b794b7b351808af3787e727d");
            var result = myContractInstance.memberId("0xfe8ad7dd2f564a877cc23feea6c0a9cc2e783715");
            console.log("result1 : " + result);
            var result = myContractInstance.members(1);
            console.log("result2 : " + result);
        } else {
            console.log("Error" );
        }
    });`}
                    </pre>
                  </div>
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">
                      Get Contract Source Code for <a href="/contractsVerified">Verified Contract Source Codes</a>
                    </h3>
                    <pre class="text-break-all">
                      {' '}
                      1.{' '}
                      <a href="//api.hpb.io/api?module=contract&action=getsourcecode&address=0xc9121e476155ebf0b794b7b351808af3787e727d&apikey=YourApiKeyToken">
                        https://api.hpb.io/api?module=contract&action=getsourcecode&address=0xc9121e476155ebf0b794b7b351808af3787e727d&apikey=YourApiKeyToken
                      </a>{' '}
                      (replace the address parameter with the actual contract address)
                      <br />
                      <br />
                      2. Terms of usage: Please see the <a href="source-code-usage-terms">usage terms policy</a>
                      <br />
                    </pre>
                  </div>
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">Verify Source Code (beta)</h3>
                    <pre class="text-break p-3 mb-2">
                      {' '}
                      1. Requires a valid HPB APIkey, will reject if otherwise
                      <br />
                      2. Current daily limit of 100 submissions per day per user (subject to change)
                      <br />
                      3. Only supports HTTP post due to max transfer size limitations for http get
                      <br />
                      4. Supports up to 10 different library pairs
                      <br />
                      5. Contracts that use "imports" will need to have the code concatenated into one file as we do not support "imports" in separate files. You can try using the{' '}
                      <a rel="nofollow noopener" href="https://github.com/BlockCatIO/solidity-flattener" target="_blank">
                        <span class="text-primary">Blockcat solidity-flattener</span>
                      </a>{' '}
                      or{' '}
                      <a href="https://github.com/DaveAppleton/SolidityFlattery" target="_blank" rel="nofollow">
                        <span class="text-primary">SolidityFlattery</span>
                      </a>
                      <br />
                      6. List of <a href="//hpb.io/solcversions">supported solc versions</a>, only solc version v0.4.11 and above is supported. Ex. v0.4.25+commit.59dbf8f1
                      <br />
                      7. Upon successful submission you will receive a GUID (50 characters) as a receipt.
                      <br />
                      8. You may use this GUID to track the status of your submission
                      <br />
                      9. Verified Source Codes will be displayed at <a href="/contractsVerified">contractsVerified</a>
                    </pre>
                  </div>
                  <div class="mb-4">
                    <p class="mb-0">
                      See Demo Source Verification Submission Code at{' '}
                      <a href="/sourcecode-demo.html" target="_blank">
                        Source Code Verification Sample <i class="fa fa-external-link"></i>
                      </a>
                    </p>
                  </div>
                  <div class="mb-4">
                    <p class="mb-0">Source Code Submission Gist (returns a guid as part of the result upon success):</p>
                    <pre class="bg-light border rounded p-3 mb-2">
                      {`    //Submit Source Code for Verification
    $.ajax({
        type: "POST",                       //Only POST supported  
        url: "//api.hpb.io/api", //Set to the  correct API url for Other Networks
        data: {
            apikey: $('#apikey').val(),                     //A valid API-Key is required        
            module: 'contract',                             //Do not change
            action: 'verifysourcecode',                     //Do not change
            contractaddress: $('#contractaddress').val(),   //Contract Address starts with 0x...     
            sourceCode: $('#sourceCode').val(),             //Contract Source Code (Flattened if necessary)
            codeformat: $('#codeformat').val(),             //solidity-single-file (default) or solidity-standard-json-input (for std-input-json-format support
            contractname: $('#contractname').val(),         //ContractName (if codeformat=solidity-standard-json-input, then enter contractname as ex: erc20.sol`}
                      <b>:</b>
                      {`erc20)
            compilerversion: $('#compilerversion').val(),   // see https://hpb.io/solcversions for list of support versions
            optimizationUsed: $('#optimizationUsed').val(), //0 = No Optimization, 1 = Optimization used (applicable when codeformat=solidity-single-file)
            runs: 200,                                      //set to 200 as default unless otherwise  (applicable when codeformat=solidity-single-file)        
            constructorArguements: $('#constructorArguements').val(),   //if applicable
            evmversion: $('#evmVersion').val(),             //leave blank for compiler default, homestead, tangerineWhistle, spuriousDragon, byzantium, constantinople, petersburg, istanbul (applicable when codeformat=solidity-single-file)
            licenseType: $('#licenseType').val(),           //Valid codes 1-12 where 1=No License .. 12=Apache 2.0, see https://hpb.io/contract-license-types
            libraryname1: $('#libraryname1').val(),         //if applicable, a matching pair with libraryaddress1 required
            libraryaddress1: $('#libraryaddress1').val(),   //if applicable, a matching pair with libraryname1 required
            libraryname2: $('#libraryname2').val(),         //if applicable, matching pair required
            libraryaddress2: $('#libraryaddress2').val(),   //if applicable, matching pair required
            libraryname3: $('#libraryname3').val(),         //if applicable, matching pair required
            libraryaddress3: $('#libraryaddress3').val(),   //if applicable, matching pair required
            libraryname4: $('#libraryname4').val(),         //if applicable, matching pair required
            libraryaddress4: $('#libraryaddress4').val(),   //if applicable, matching pair required
            libraryname5: $('#libraryname5').val(),         //if applicable, matching pair required
            libraryaddress5: $('#libraryaddress5').val(),   //if applicable, matching pair required
            libraryname6: $('#libraryname6').val(),         //if applicable, matching pair required
            libraryaddress6: $('#libraryaddress6').val(),   //if applicable, matching pair required
            libraryname7: $('#libraryname7').val(),         //if applicable, matching pair required
            libraryaddress7: $('#libraryaddress7').val(),   //if applicable, matching pair required
            libraryname8: $('#libraryname8').val(),         //if applicable, matching pair required
            libraryaddress8: $('#libraryaddress8').val(),   //if applicable, matching pair required
            libraryname9: $('#libraryname9').val(),         //if applicable, matching pair required
            libraryaddress9: $('#libraryaddress9').val(),   //if applicable, matching pair required
            libraryname10: $('#libraryname10').val(),       //if applicable, matching pair required
            libraryaddress10: $('#libraryaddress10').val()  //if applicable, matching pair required
        },
        success: function (result) {
            console.log(result);
            if (result.status == "1") {
                //1 = submission success, use the guid returned (result.result) to check the status of your submission.
                // Average time of processing is 30-60 seconds
                document.getElementById("postresult").innerHTML = result.status + ";" + result.message + ";" + result.result;
                // result.result is the GUID receipt for the submission, you can use this guid for checking the verification status
            } else {
                //0 = error
                document.getElementById("postresult").innerHTML = result.status + ";" + result.message + ";" + result.result;
            }
            console.log("status : " + result.status);
            console.log("result : " + result.result);
        },
        error: function (result) {
            console.log("error!");
            document.getElementById("postresult").innerHTML = "Unexpected Error"
        }
    });`}
                    </pre>
                  </div>
                  <div class="mb-4">
                    <p class="mb-0">Check Source code verification submission status:</p>
                    <pre class="bg-light border rounded p-3 mb-2">
                      {`    //Check Source Code Verification Status
    $.ajax({
        type: "GET",
        url: "//api.hpb.io/api",
        data: {
            apikey: $('#apikey').val(), 
            guid: 'ezq878u486pzijkvvmerl6a9mzwhv6sefgvqi5tkwceejc7tvn', //Replace with your Source Code GUID receipt above
            module: "contract",
            action: "checkverifystatus"
        },
        success: function (result) {
            console.log("status : " + result.status);   //0=Error, 1=Pass 
            console.log("message : " + result.message); //OK, NOTOK
            console.log("result : " + result.result);   //result explanation
            $('#guidstatus').html(">> " + result.result);
        },
        error: function (result) {
            alert('error');
        }
    });`}
                    </pre>
                  </div> */}



                </div>
              </div>

              <div class={`card tab-pane fade ${hash === 'transaction' ? 'active show' : ''}`} id="transaction" role="tabpanel" aria-labelledby="transaction-tab">
                <div class="card-header">
                  <h2 class="h5 mb-0">{t('hpbApi.Transaction')} APIs</h2>
                </div>
                <div class="card-body px-4">
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">
                    {t('hpbApi.Transaction.tip1')} 
                    </h3>
                    <p class="mb-0">{t('hpbApi.Transaction.tip2')} </p>
                    <pre class="text-break-all">                    
                      {' '}
                      <a href="//hscan.org/api?module=transaction&action=gettxreceiptstatus&txhash=0x7fe7b67d1ebce8291496b82fa82d761077c0492e4449b0e4bcda6408f2f4db2e&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                          https://hscan.org/api?module=transaction&action=gettxreceiptstatus&txhash=0x7fe7b67d1ebce8291496b82fa82d761077c0492e4449b0e4bcda6408f2f4db2e&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div> 
                </div>
              </div>


              <div class={`card tab-pane fade ${hash === 'blocks' ? 'active show' : ''}`} id="blocks" role="tabpanel" aria-labelledby="blocks-tab">
                <div class="card-header">
                  <h2 class="h5 mb-0">{t('hpbApi.Block')} APIs</h2>
                </div>
                <div class="card-body px-4">

                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">
                    {t('hpbApi.Block.tip1')} 
                    </h3>
                    <pre class="text-break-all"> 
                      {' '}
                      <a href="//hscan.org/api?module=block&action=getblockreward&blockno=13782596&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                        https://hscan.org/api?module=block&action=getblockreward&blockno=13782596&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div> 

                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">
                    {t('hpbApi.Block.tip2')}  
                    </h3>
                    <pre class="text-break-all"> 
                      {' '}
                      <a href="//hscan.org/api?module=block&action=getblockcountdown&blockno=13783596&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=block&action=getblockcountdown&blockno=13783596&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div> 

                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">
                    {t('hpbApi.Block.tip3')}  
                    </h3>
                    <p class="mb-0">{t('hpbApi.Block.tip4')}</p>
                    <pre class="text-break-all"> 

                      {' '}
                      <a href="//hscan.org/api.hecoinfo.com/api?module=block&action=getblocknobytime&timestamp=1653617978&closest=before&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api.hecoinfo.com/api?module=block&action=getblocknobytime&timestamp=1653617978&closest=before&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div> 


                </div>
              </div>


              <div class={`card tab-pane fade ${hash === 'logs' ? 'active show' : ''}`} id="logs" role="tabpanel" aria-labelledby="logs-tab">
                <div class="card-header">
                  <h2 class="h5 mb-0">{t('hpbApi.Log')} APIs</h2>
                </div>
                <div class="card-body px-4">

                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">
                    {t('hpbApi.Log.tip1')}
                    </h3>
                    <pre class="text-break-all"> 
                      {' '}
                      <a href="//hscan.org/api?module=log&action=getLogs&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                        https://hscan.org/api?module=log&action=getLogs&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                    <p class="mb-1">{t('hpbApi.Log.tip2')}:</p>
                    <br />
                    <h3 class="h6 font-weight-bold">
                    {t('hpbApi.Log.tip3')}
                    </h3>
                    <pre class="text-break-all"> 
                      {' '}
                      {`
                        {
                          "toBlock":13920300,
                          "fromBlock":13920200,
                          "address":"0x715aA09E6950ffDBda55Cea77f72dd7F52Ae1A62",
                          "topics":[
                              ["0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef"]
                          ]
                      }
                      `} 
                    </pre>

                    <h3 class="h6 font-weight-bold">
                    {t('hpbApi.Log.tip4')}
                    </h3>
                    {/* <pre class="text-break-all"> 
                      {' '}
                      {`
                        {
                            "toBlock":13920300,
                            "fromBlock":13920200,
                            "address":"0x715aA09E6950ffDBda55Cea77f72dd7F52Ae1A62",
                            "topics":[
                                [],
                                [],
                                ["0x00000000000000000000000075ecd1ab06c4e34763a47e1033e80de614d09fa4"]
                            ]
                        }   
                      `} 
                      </pre> */}
                      <p class="mb-1"> {t('hpbApi.Log.tip5')}</p>
                      
                  </div> 

                  


                </div>
              </div>

              <div class={`card tab-pane fade ${hash === 'Proxy' ? 'active show' : ''}`} id="Proxy" role="tabpanel" aria-labelledby="Proxy-tab">
                <div class="card-header">
                  <h2 class="h5 mb-0">{t('hpbApi.Proxy')} APIs</h2>
                </div>
                <div class="card-body px-4">
                  <p class="mb-0">{t('hpbApi.Proxy.tip1')} </p>
                   
                 

                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">eth_blockNumber</h3>
                    <p class="mb-0">{t('hpbApi.Proxy.tip2')}  </p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=proxy&action=blockNumber&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=proxy&action=blockNumber&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>
 

                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">eth_getBlockByNumber</h3>
                    <p class="mb-0">{t('hpbApi.Proxy.tip3')} </p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=proxy&action=getBlockByNumber&tag=0xd3dde8&boolean=true&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=proxy&action=getBlockByNumber&tag=0xd3dde8&boolean=true&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>

                  <div class="mb-4">
                    <h3 class="h6 font-weight-bold">eth_getBlockTransactionCountByNumber</h3>
                    <p class="mb-0">{t('hpbApi.Proxy.tip4')} </p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=proxy&action=getBlockTransactionCountByNumber&tag=0xd3dde8&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                          https://hscan.org/api?module=proxy&action=getBlockTransactionCountByNumber&tag=0xd3dde8&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>
                  
                  <div class="mb-4">
                    <h3 class="h6 font-weight-bold">eth_getTransactionByHash</h3>
                    <p class="mb-0">{t('hpbApi.Proxy.tip5')}</p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=proxy&action=getTransactionByHash&txhash=0x5d8703a18fc2028ab9a0cb8176bbb6c7113acd497ebe6c0d3dc7a8436415fc7d&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=proxy&action=getTransactionByHash&txhash=0x5d8703a18fc2028ab9a0cb8176bbb6c7113acd497ebe6c0d3dc7a8436415fc7d&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>
                  
                  <div class="mb-4">
                    <h3 class="h6 font-weight-bold">eth_getTransactionByBlockNumberAndIndex</h3>
                    <p class="mb-0">{t('hpbApi.Proxy.tip6')}</p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=proxy&action=getTransactionByBlockNumberAndIndex&tag=0xD3DDE8&index=0x0&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=proxy&action=getTransactionByBlockNumberAndIndex&tag=0xD3DDE8&index=0x0&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>
          
                  <div class="mb-4">
                    <h3 class="h6 font-weight-bold">eth_getTransactionCount</h3>
                    <p class="mb-0">{t('hpbApi.Proxy.tip7')}</p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=proxy&action=getTransactionCount&address=0x75ecd1ab06c4e34763a47e1033e80de614d09fa4&tag=latest&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=proxy&action=getTransactionCount&address=0x75ecd1ab06c4e34763a47e1033e80de614d09fa4&tag=latest&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>
                  
                  <div class="mb-4">
                    <h3 class="h6 font-weight-bold">eth_sendRawTransaction</h3>
                    <p class="mb-0">{t('hpbApi.Proxy.tip8')}</p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=proxy&action=sendRawTransaction&hex=0xf8ab1385ba43b7400082afc894715aa09e6950ffdbda55cea77f72dd7f52ae1a6280b84440c10f1900000000000000000000000075ecd1ab06c4e34763a47e1033e80de614d09fa4000000000000000000000000000000000000000000000000000000014b1a92c082023ea0fc44da2f85b9fc598124c81ed13a62b49e6edc6248d8c4940ed9a192f62717cea05832a187f1d413c0fa27669ad277f2bcaa390827c420e314af44d8ee194c0eb3&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=proxy&action=sendRawTransaction&hex=0xf8ab1385ba43b7400082afc894715aa09e6950ffdbda55cea77f72dd7f52ae1a6280b84440c10f1900000000000000000000000075ecd1ab06c4e34763a47e1033e80de614d09fa4000000000000000000000000000000000000000000000000000000014b1a92c082023ea0fc44da2f85b9fc598124c81ed13a62b49e6edc6248d8c4940ed9a192f62717cea05832a187f1d413c0fa27669ad277f2bcaa390827c420e314af44d8ee194c0eb3&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                    <p class="mb-0"> ({t('hpbApi.Proxy.tip9')})</p>
                  </div>

                  <div class="mb-4">
                    <h3 class="h6 font-weight-bold">eth_getTransactionReceipt</h3>
                    <p class="mb-0">{t('hpbApi.Proxy.tip10')}</p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=proxy&action=getTransactionReceipt&txhash=0x5d8703a18fc2028ab9a0cb8176bbb6c7113acd497ebe6c0d3dc7a8436415fc7d&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=proxy&action=getTransactionReceipt&txhash=0x5d8703a18fc2028ab9a0cb8176bbb6c7113acd497ebe6c0d3dc7a8436415fc7d&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>

                  <div class="mb-4">
                    <h3 class="h6 font-weight-bold">eth_call</h3>
                    <p class="mb-0">{t('hpbApi.Proxy.tip11')}</p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=proxy&action=call&to=0xbe05ac1fb417c9ea435b37a9cecd39bc70359d31&data=0x18160ddd&tag=latest&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=proxy&action=call&to=0xbe05ac1fb417c9ea435b37a9cecd39bc70359d31&data=0x18160ddd&tag=latest&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                    <p class="mb-0">({t('hpbApi.Proxy.tip12')})</p>
                  </div>

                  <div class="mb-4">
                    <h3 class="h6 font-weight-bold">eth_getCode</h3>
                    <p class="mb-0">{t('hpbApi.Proxy.tip13')}</p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=proxy&action=getCode&address=0xbe05ac1fb417c9ea435b37a9cecd39bc70359d31&tag=latest&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=proxy&action=getCode&address=0xbe05ac1fb417c9ea435b37a9cecd39bc70359d31&tag=latest&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>

                  <div class="mb-4">
                    <h3 class="h6 font-weight-bold">eth_getStorageAt</h3>
                    <p class="mb-0">{t('hpbApi.Proxy.tip14')}</p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=proxy&action=getStorageAt&address=0xbe05ac1fb417c9ea435b37a9cecd39bc70359d31&position=0x0&tag=latest&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=proxy&action=getStorageAt&address=0xbe05ac1fb417c9ea435b37a9cecd39bc70359d31&position=0x0&tag=latest&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>
                  
                  <div class="mb-4">
                    <h3 class="h6 font-weight-bold">eth_gasPrice</h3>
                    <p class="mb-0">{t('hpbApi.Proxy.tip15')}</p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=proxy&action=gasPrice&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=proxy&action=gasPrice&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>

                  <div class="mb-4">
                    <h3 class="h6 font-weight-bold">eth_estimateGas</h3>
                    <p class="mb-0">{t('hpbApi.Proxy.tip16')}</p>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=proxy&action=estimateGas&data=0x40c10f1900000000000000000000000075ecd1ab06c4e34763a47e1033e80de614d09fa4000000000000000000000000000000000000000000000000000000014b1a92c0&to=0x715aa09e6950ffdbda55cea77f72dd7f52ae1a62&value=0x0&gasPrice=0x430E23400&gas=0xF4240&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=proxy&action=estimateGas&data=0x40c10f1900000000000000000000000075ecd1ab06c4e34763a47e1033e80de614d09fa4000000000000000000000000000000000000000000000000000000014b1a92c0&to=0x715aa09e6950ffdbda55cea77f72dd7f52ae1a62&value=0x0&gasPrice=0x430E23400&gas=0xF4240&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                    <p class="mb-0">({t('hpbApi.Proxy.tip117')})</p>
                  </div>


                </div>
              </div>



              <div class={`card tab-pane fade ${hash === 'tokens' ? 'active show' : ''}`} id="tokens" role="tabpanel" aria-labelledby="tokens-tab">
                <div class="card-header">
                  <h2 class="h5 mb-0">{t('hpbApi.Tokens')} APIs</h2>
                </div>
                <div class="card-body px-4">
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Tokens.tip1')}</h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=stats&action=tokensupply&contractaddress=0xd378634119d2f7b3cf3d60e0b0f5e048e74ce3cf&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                         https://hscan.org/api?module=stats&action=tokensupply&contractaddress=0xd378634119d2f7b3cf3d60e0b0f5e048e74ce3cf&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Tokens.tip2')}</h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=stats&action=tokenCsupply&contractaddress=0xd378634119d2f7b3cf3d60e0b0f5e048e74ce3cf&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=stats&action=tokenCsupply&contractaddress=0xd378634119d2f7b3cf3d60e0b0f5e048e74ce3cf&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>
                  <div class="mb-4">
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Tokens.tip3')}</h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=account&action=tokenbalance&contractaddress=0xd378634119d2f7b3cf3d60e0b0f5e048e74ce3cf&address=0x62f446c1bfe201b5ecf69c735c8bbf3fe90e0528&tag=latest&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=account&action=tokenbalance&contractaddress=0xd378634119d2f7b3cf3d60e0b0f5e048e74ce3cf&address=0x62f446c1bfe201b5ecf69c735c8bbf3fe90e0528&tag=latest&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>
                </div>
              </div>

              <div class={`card tab-pane fade ${hash === 'stats' ? 'active show' : ''}`} id="stats" role="tabpanel" aria-labelledby="stats-tab">
                <div class="card-header">
                  <h2 class="h5 mb-0">{t('hpbApi.Stats')} APIs</h2>
                </div>
                <div class="card-body px-4">
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Stats.tip1')}</h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=stats&action=hpbsupply&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=stats&action=hpbsupply&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                        </a>
                    </pre>
                    <p class="mb-0">({t('hpbApi.Stats.tip2')})</p>
                  </div>

                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Stats.tip3')}</h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=stats&action=marketCap&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      https://hscan.org/api?module=stats&action=marketCap&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                        </a>
                    </pre> 
                  </div>

                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Stats.tip4')}</h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=stats&action=hpbprice&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                        https://hscan.org/api?module=stats&action=hpbprice&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>

                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Stats.tip5')}</h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=stats&action=hpbcsupply&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      http://hscan.org/api?module=stats&action=hpbcsupply&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>

                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.Stats.tip6')}</h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=stats&action=tokenprice&address=0xdfe4051195a09a67defc36ce6bbb5e6edea745cb&apikey=9B64C60C3199621FE9108F5CCD87EC1F">
                      http://hscan.org/api?module=stats&action=tokenprice&address=0xdfe4051195a09a67defc36ce6bbb5e6edea745cb&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>
                    </pre>
                  </div>




                </div>
              </div>

              <div class={`card tab-pane fade ${hash === 'rpc' ? 'active show' : ''}`} id="rpc" role="tabpanel" aria-labelledby="rpc-tab">
                <div class="card-header">
                  <h2 class="h5 mb-0">{t('hpbApi.RPC.tip1')}</h2>
                </div>
                <div class="card-body px-4">
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.RPC.tip2')}</h3>
                    <pre class="text-break-all">
                      {' '}
                      {t('hpbApi.RPC.tip3')}:
                      <br />
                      <br />
                      https://hpbnode.com 
                      {/* <br /> */}
                      {/* https://http-mainnet-node.huobichain.com (China Mainland) */}
                    </pre>
                  </div>
                 {/*  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">Testnet HPB RPC Nodes</h3>
                    <pre class="text-break-all">
                      {' '}
                      Testnet HPB RPC Endpoints (ChainID 269):
                      <br />
                      <br />
                      https://http-testnet.hecochain.com
                    </pre>
                  </div> */}
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.RPC.tip4')}:</h3>
                    <pre class="text-break-all">
                      {' '}
                      {t('hpbApi.RPC.tip5')}
                      <br />
                      {t('hpbApi.RPC.tip6')}
                      <br />
                      <br />
                      ## mainnet
                      <br />{t('hpbApi.RPC.tip7')} 
                      <br />
                      <br />
                    {/*   ## testnet
                      <br />
                      geth attach https://http-testnet.hecochain.com
                      <br />
                      <br /> */}
                      {t('hpbApi.RPC.tip8')}
                      <br />
                      {t('hpbApi.RPC.tip9')}:{' '}
                      <a href="https://documenter.getpostman.com/view/4117254/ethereum-json-rpc/RVu7CT5J?version=latest" target="_blank">
                        https://documenter.getpostman.com/view/4117254/ethereum-json-rpc/RVu7CT5J?version=latest
                      </a>{' '}
                      <br />
                    </pre>
                  </div>
                </div>
              </div>
           
           
           
              <div class={`card tab-pane fade ${hash === 'randomNumber' ? 'active show' : ''}`} id="randomNumber" role="tabpanel" aria-labelledby="randomNumber-tab">
                <div class="card-header">
                  <h2 class="h5 mb-0">{t('hpbApi.RandomNumber')}</h2>
                </div>
                
                <div class="card-body px-4">
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.RandomNumber.tip1')}</h3> 
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=block&action=getlatestrandom&apikey=9B64C60C3199621FE9108F5CCD87EC1F"> 
                      https://hscan.org/api?module=block&action=getlatestrandom&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a> 
                      
                    </pre> 
                    </div>
                </div>


                <div class="card-body px-4">
                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">{t('hpbApi.RandomNumber.tip2')}</h3>
                    <h3 class="h6">
                      {t('hpbApi.RandomNumber.tip3')} 
                    </h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=block&action=getrandombytime&timestamp=1644245532&format=dec&decimals=2&apikey=9B64C60C3199621FE9108F5CCD87EC1F"> 
                          https://hscan.org/api?module=block&action=getrandombytime&timestamp=1644245532&format=dec&decimals=2&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a>  
                      
                    </pre> 
                  </div>

                  <div class="mb-4">
                    <br />
                    <h3 class="h6 font-weight-bold">  {t('hpbApi.RandomNumber.tip4')} </h3>
                    <h3 class="h6">
                    {t('hpbApi.RandomNumber.tip5')}
                    </h3>
                    <pre class="text-break-all">
                      {' '}
                      <a href="//hscan.org/api?module=block&action=getrandombynumber&blockno=13782596&format=dec&decimals=10&apikey=9B64C60C3199621FE9108F5CCD87EC1F"> 
                        https://hscan.org/api?module=block&action=getrandombynumber&blockno=13782596&format=dec&decimals=10&apikey=9B64C60C3199621FE9108F5CCD87EC1F
                      </a> 
                     
                      
                    </pre> 
                  </div>



                </div>
              </div>
           
           

            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
