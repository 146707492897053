import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useParams} from 'react-router-dom';
import moment from 'moment'; 
 
import {Card, CardHead} from '../../components/Card';
import Loading from '../../components/Loading';
import LinkWithTooltip from '../../components/LinkWithTooltip';

import {useTranslation} from 'react-i18next';

window.moment = moment;
function Detail({data = {}, block, loading, error}) {

  const {t} = useTranslation(['block']);
  // 设置中文
  const defaultLNG = window.localStorage.getItem('lng') || 'en_US';
  moment.locale(defaultLNG);

  const [state, setState] = useState({
    showMore: false,
  });
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error</div>;
  }

  if (!data.block_no) {
    return (
      <div className="tab-content">
        <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="home-tab"></div>
        <div className="p-2">
          <br />
          <p>Unable to locate block #{block}</p>
        </div>
      </div>
    );
  }

  const block_no = Number(data.block_no);
  const timestamp = new Date(data.time_stamp * 1000).toString();
  const now = new Date().getTime();
  
  const time = moment(Number(data.time_stamp) * 1000)
    .startOf('minute')
    .fromNow();

  const handleCollapse = () => {
    setState({...state, showMore: !state.showMore});
  };

  const gasPercent = (Number(data.gas_used) / Number(data.gas_limit)) * 100;  
  return (
    <div className="tab-content">
      <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="home-tab">
        <div className="card-body">
          <div className="row align-items-center  mt-1">
            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
              <i
                className="fal fa-question-circle text-secondary mr-1"
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-original-title=""
                title=""
                data-content="Also known as Block Number. The block height, which indicates the length of the blockchain, increases after the addition of the new block."
              ></i>
              {t('block.BlockHeight')}:
            </div>
            <div className="col-md-9">
              <div className="d-flex">
                <span className="font-weight-sm-bold mr-2">{block_no}</span>
                <a className="btn btn-xs btn-icon btn-soft-info mr-1" href={`/block/${block_no - 1}`} data-toggle="tooltip" title="View previous block">
                  <i className="fa fa-chevron-left btn-icon__inner"></i>
                </a>
                <a className="btn btn-xs btn-icon btn-soft-info mr-1" href={`/block/${block_no + 1}`} data-toggle="tooltip" title="View next block">
                  <i className="fa fa-chevron-right btn-icon__inner"></i>
                </a>
              </div>
            </div>
          </div>
          <hr className="hr-space" />
          <div className="row align-items-center">
            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
              <i
                className="fal fa-question-circle text-secondary mr-1"
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-original-title=""
                title=""
                data-content="The date and time at which a block is validated."
              ></i>
               {t('block.Timestamp')}:
            </div>
            <div className="col-md-9">
              <i className="far fa-clock small mr-1"></i>
              {time} ({moment(data.time_stamp * 1000).local().format('MMM-D-YYYY HH:mm:ss ')})
            </div>
          </div>
          <div>
            <hr className="hr-space mb-2" />
            <div className="row align-items-center">
              <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                <i
                  className="fal fa-question-circle text-secondary mr-1"
                  data-container="body"
                  data-toggle="popover"
                  data-placement="top"
                  data-original-title=""
                  title=""
                  data-content="The number of transactions in the block. Internal transaction is transactions as a result of contract execution that involves HPB value."
                ></i>
                {t('block.Transactions')}:
              </div>
              <div className="col-md-9">
                <a className="u-label u-label--value u-label--primary rounded my-1" href={`/txs?block=${block_no}`} data-toggle="tooltip" title={t('block.ClickTransactions')}>
                  {data.trade_amount} {t('block.transactions')}
                </a>
                <span> and </span>
                {String(data.trade_contract_amount) !== '0' ? (
                  <a
                    className="u-label u-label--value u-label--primary rounded my-1"
                    href={`/txsInternal?block=${block_no}`}
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Click to view Internal Transactions"
                  >
                    {data.trade_contract_amount} {t('block.contractInteralTransactions')}
                  </a>
                ) : (
                  `${data.trade_contract_amount} ${t('block.contractInteralTransactions')}`
                )}

                <span>{t('block.thisblock')}</span>
              </div>
            </div>
          </div>
          <hr className="hr-space mt-2" />
          <div className="row align-items-center">
            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
              <i
                className="fal fa-question-circle text-secondary mr-1"
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-original-title=""
                title=""
                data-content="Validator who successfully include the block onto the blockchain."
              ></i>
               {t('block.Validated')}:
            </div>
            <div className="col-md-9">
              <a href={`/address/${data.miner}`}>{data.miner}</a> in 6 secs
            </div>
          </div>
          <hr className="hr-space" />
          <div className="row align-items-center">
            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
              <i
                className="fal fa-question-circle text-secondary mr-1"
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-original-title=""
                title=""
                data-content="For each block, the miner is rewarded with a finite amount of HPB on top of the fees paid for all transactions in the block."
              ></i>
              {t('block.blockReward')}:
            </div>
            <div className="col-md-9">{data.block_reward} HPB</div>
          </div>

          <hr className="hr-space" />
          <div className="row align-items-center">
            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
              <i
                className="fal fa-question-circle text-secondary mr-1"
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-original-title=""
                title=""
                data-content="For each block, the miner is rewarded with a finite amount of HPB on top of the fees paid for all transactions in the block."
              ></i>
             {t('block.Random')}:
            </div>
            <div className="col-md-9">{data.block_hardware_random}</div>
          </div>

          <hr className="hr-space" />
          <div className="row align-items-center">
            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
              <i
                className="fal fa-question-circle text-secondary mr-1"
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-original-title=""
                title=""
                data-content="For each block, the miner is rewarded with a finite amount of HPB on top of the fees paid for all transactions in the block."
              ></i> 
              <LinkWithTooltip placement="bottom" tooltip="Onchain Provable Random Number">
                  <span>{t('block.RandomNumber')}:</span>
              </LinkWithTooltip> 
            </div>
            <div className="col-md-9">{ Number.parseInt(data.block_hardware_random,16)}</div>
          </div>



          <hr className="hr-space" />
          <div className="row align-items-center  d-none">
            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
              <i
                className="fal fa-question-circle text-secondary mr-1"
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-original-title=""
                title=""
                data-content="An uncle block has a significantly lower reward than a normal block. Uncles reward is valid but rejected as it is not on the longest chain which is the working mechanism of the blockchain. Uncle block is important in HPB as it secures the blockchain."
              ></i>
              {t('block.UnclesReward')}:
            </div>
            <div className="col-md-9">0</div>
          </div>
          <hr className="hr-space d-none" />
          <div className="row align-items-center">
            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
              <i
                className="fal fa-question-circle text-secondary mr-1"
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-original-title=""
                title=""
                data-content="The amount of effort required to mine a new block. The difficulty algorithm may adjust according to time."
              ></i>
              {t('block.Difficulty')}:
            </div>
            <div className="col-md-9">{data.difficulty}</div>
          </div>
          <hr className="hr-space" />
          <div className="row align-items-center">
            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
              <i
                className="fal fa-question-circle text-secondary mr-1"
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-original-title=""
                title=""
                data-content="Total difficulty of the chain until this block."
              ></i>
                {t('block.TotalDiff')}:
            </div>
            <div className="col-md-9">{data.total_difficulty}</div>
          </div>
          <hr className="hr-space" />
          <div className="row align-items-center">
            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
              <i
                className="fal fa-question-circle text-secondary mr-1"
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-original-title=""
                title=""
                data-content="The block size is actually determined by the block&#39;s gas limit."
              ></i>
               {t('block.Size')}:
            </div>
            <div className="col-md-9">{data.size} bytes</div>
          </div>
          <hr className="hr-space" />
          <div className="row align-items-center">
            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
              <i
                className="fal fa-question-circle text-secondary mr-1"
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-original-title=""
                title=""
                data-content="The total gas used in the block and its percentage of gas filled in the block."
              ></i>
               {t('block.gasUsed')}:
            </div>
            <div className="col-md-9">
              {data.gas_used} ({gasPercent.toFixed(2)}%)
            </div>
          </div>
          <hr className="hr-space" />
          <div className="row align-items-center">
            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
              <i
                className="fal fa-question-circle text-secondary mr-1"
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-original-title=""
                title=""
                data-content="Total gas limit provided by all transactions in the block."
              ></i>
               {t('block.gasLimit')}:
            </div>
            <div className="col-md-9">{data.gas_limit}</div>
          </div>
          <hr className="hr-space" />
          <div className="row align-items-center">
            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
              <i
                className="fal fa-question-circle text-secondary mr-1"
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-original-title=""
                title=""
                data-content="Any data that can be included by the miner in the block."
              ></i>
             {t('block.extraData')}:
            </div>
            <div className="col-md-9">{data.extra_data}</div>
          </div>
          {/* <span>
            <hr className="hr-space" />
            <div className="row align-items-center">
              <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                <i
                  className="fal fa-question-circle text-secondary mr-1"
                  data-container="body"
                  data-toggle="popover"
                  data-placement="top"
                  data-original-title=""
                  title=""
                  data-content="Closing price of HPB on date of transaction (May-05-2021)"
                ></i>
                HPB Price:
              </div>
              <div className="col-md-9">${data.ht_price} / HPB</div>
            </div>
          </span> */}
          <hr className="hr-space" />
          <div className={`collapse ${state.showMore ? 'show' : ''}`}>
            <div className="row align-items-center">
              <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                <i
                  className="fal fa-question-circle text-secondary mr-1"
                  data-container="body"
                  data-toggle="popover"
                  data-placement="top"
                  data-original-title=""
                  title=""
                  data-content="The hash of the block header of the current block."
                ></i>
                {t('block.hash')}:
              </div>
              <div className="col-md-9">{data.hash}</div>
            </div>
            <hr className="hr-space" />
            <div className="row align-items-center">
              <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                <i
                  className="fal fa-question-circle text-secondary mr-1"
                  data-container="body"
                  data-toggle="popover"
                  data-placement="top"
                  data-original-title=""
                  title=""
                  data-content="The hash of the block from which this block was generated, also known as its parent block."
                ></i>
                {t('block.ParentHash')}:
              </div>
              <div className="col-md-9">
                <a href={`/block/${data.parent_hash}`}>{data.parent_hash}</a>
              </div>
            </div>
            <hr className="hr-space" />
            <div className="row align-items-center">
              <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                <i
                  className="fal fa-question-circle text-secondary mr-1"
                  data-container="body"
                  data-toggle="popover"
                  data-placement="top"
                  data-original-title=""
                  title=""
                  data-content="The mechanism which HPB Javascript RLP encodes an empty string."
                ></i>
                 {t('block.Sha3Uncles')}:
              </div>
              <div className="col-md-9">{data.sha3_uncles}</div>
            </div>
            <hr className="hr-space" />
            <div className="row align-items-center">
              <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                <i
                  className="fal fa-question-circle text-secondary mr-1"
                  data-container="body"
                  data-toggle="popover"
                  data-placement="top"
                  data-original-title=""
                  title=""
                  data-content="Block nonce is a value used during mining to demonstrate proof of work for a block."
                ></i>
                {t('block.Nonce')} :
              </div>
              <div className="col-md-9">{data.nonce}</div>
            </div>
            <hr className="hr-space" />
          </div>
          <span>
            <a
              className={`d-block ${state.showMore ? '' : 'collapsed'}`}
              data-toggle="collapse"
              href="#collapsePanel"
              role="button"
              aria-expanded="false"
              aria-controls="collapsePanel"
              onClick={handleCollapse}
            >
              <span> 
               {state.showMore ? <span className="card-arrow-less">{t('block.Overview.ClicktoseeLess')}</span> : <span className="card-arrow-more">{t('block.Overview.ClicktoseeMore')}</span>}
                <span className="card-btn-arrow card-btn-arrow--up ml-2">
                  <span className="fas fa-arrow-up small"></span>
                </span>
              </span>
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default function Block() {
  const {block, forked} = useParams();
  const {t} = useTranslation(['block']);
  console.log('forked', forked);
  const [state, setState] = useState({
    body: {
      field: block.indexOf('0x') === 0 ? 'block_hash' : 'block_no',
      value: block,
    },
  });

  const blockRequest = useRequest(
    body => ({
      url: forked === 'f' ? '/blockBrowser/blockChain/blocksForked/blocksForkedDetail' : '/blockBrowser/blockChain/block/blockDetail',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  const handleUpdateHash = () => {
    blockRequest.run(state.body);
  };

  useEffect(() => {
    blockRequest.run(state.body);
  }, []);

  // console.log(blockRequest.data);

  return (
    <main role="main">
      <div className="container py-3">
        <div className="d-sm-flex align-items-center">
          <div className="mb-2 mb-sm-0">
            <h1 className="h4 mb-0">
              {t('block.title')} <span className="small text-secondary">&nbsp;#{state.body.value}</span>
              <br />
            </h1>
          </div>
        </div>
      </div>
      <div className="container space-bottom-2">
        <Card>
          <CardHead>
            <ul className="nav nav-custom nav-borderless nav_tabs1" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" href="#overview" data-toggle="tab" onClick={handleUpdateHash}>
                {t('block.Overview')}
                </a>
              </li>
            </ul>
          </CardHead>
          <Detail
            block={block}
            data={blockRequest.data || {}}
            loading={blockRequest.loading || (typeof blockRequest.data === 'undefined' && typeof blockRequest.error === 'undefined')}
            error={blockRequest.error}
          />
        </Card>
      </div>
    </main>
  );
}
