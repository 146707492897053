const gasprice = { 
    'gasprice.title1': 'Gas Price Real-Time Query',
    'gasprice.title2': 'Refresh every 3 seconds according to the transfer data on the HPB chain',
   
    'gasprice.tip1': 'Fast ',
    'gasprice.tip2': 'Standard',
    'gasprice.tip3': 'Slow',
    

};

export default gasprice;
