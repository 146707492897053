import {useState, useContext,useEffect } from 'react';
import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext'; 
import {useRequest} from 'ahooks'; 
import {Modal } from 'react-bootstrap'; 
import LinkWithTooltip from '../../components/LinkWithTooltip';
import Copy from '../../components/Copy'; 
import Pager2 from '../../components/Pager2';
import Loading from '../../components/Loading';
 

function CreateAddressModal({user, show, onHide}) {

  const [fields, setFields] = useState({
    address: '',
    remark: '',
    notification: 0,
    chkTokenTransfer: 0,
    chkToken721: 0,
    chkToken1155: 0
  });
  const [errors, setErrors] = useState({});

  const handleForm = field => e => {
    const target = e.target; 
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;  
    setFields({...fields, [field]: value});

    if (e.target.value&& name === 'txtAddress') {
      setErrors({...errors, [field]: undefined});
    }
  }; 

  const handleBlur = key => () => {
    const _errors = {};
    Object.keys(fields).filter(f => f !== 'autologin')
     .forEach(field => {
        _errors[field] = !fields[field];
      });
    if (Object.keys(_errors).some(e => !!_errors[e])) {
      if (_errors[key]) {
        setErrors(_errors);
      }
    } 
  }; 
  
  const addressRequest = useRequest(
    body => ({
      url: '/loginapi/sys/user/account/addressCreate',
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
        'X-Access-Token': (user.token ||'')
      },
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );

  const handleHide=()=>{
    onHide(false)
  }
  const handleSubmit = () => { 
    const _errors = {};
 
    _errors['address'] =  !fields['address'];
    if(!!_errors['address']){
      setErrors(_errors);
    }else { 
      addressRequest.run(fields).then(res => { 
        if (res.code !== 200) {
          prompt.error(res.message); 
        } else { 
          prompt.inform('Add success')
          onHide(true);
        }
      },
      err=>{
        console.log(err);
      }); 
    }
   
  };

  return (
    <Modal show={show} onHide={handleHide} centered  aria-labelledby="contained-modal-title">
      <Modal.Header closeButton className="p-2">
          <h5 className="pl-3 modal-title h5 font-weight-bold text-break w-100 mt-2 mb-2"> Add New Address</h5> 
      </Modal.Header>
      <Modal.Body>

        <div className="mb-4">
          <div className="form-group">
            <label htmlFor="txtAddress">HPB Address</label>
            <div className="js-form-message">
              <input name="txtAddress" type="text" value={fields.address} maxLength="42" id="txtAddress"   className="form-control form-control-sm" required=""     placeholder="0x.."
               onChange={handleForm('address')}  onBlur={handleBlur('address')} />
                <div className="invalid-feedback" style={{display: errors.address ? 'block' : 'none'}}>
                  Please enter eth address
                </div>
            </div>
            <div className="input-group input-group-sm">
                <span id="revAddress" style={{color:"red",fontSize:"Smaller",display: errors.valiadateAddress ? 'block' : 'none'}} >*Invalid Address</span>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="txtDescription">Description <span className="text-secondary">(Optional)</span></label>
            <input name="txtRemark" type="text" value={fields.remark} maxLength="42" id="txtRemark" 
               onChange={handleForm('remark')}    className="form-control form-control-sm"   placeholder="Short description.." /> 
            <span className="form-text small text-secondary">Max 100 character limit.</span>
          </div>
        </div>
      
        <h4 className="h6 font-weight-bold">Notification Methods</h4>
        <p>You can monitor and receive an alert to your email when an address on your watchlist sends or receives any transactions.</p>
      
        <div className="mb-4">
          <div className="custom-control custom-radio mb-2">
            <input   name="NotificationType" type="radio" id="RadioButton0"  checked={fields.notification ===0? 'checked':''}   onChange={e => { setFields({...fields, notification: 0}); }} 
               className="custom-control-input" />
            <label className="custom-control-label" htmlFor="RadioButton0">No Notification</label>
          </div>
          <div className="custom-control custom-radio mb-2">
            <input   name="NotificationType" type="radio" id="RadioButton1"  className="custom-control-input"    checked={fields.notification ===1?'checked':''}  onChange={e => { setFields({...fields, notification: 1}); }}   />
            <label className="custom-control-label" htmlFor="RadioButton1">Notify on Incoming &amp; Outgoing Txns</label>
          </div>
          <div className="custom-control custom-radio mb-2">
            <input   name="NotificationType" type="radio" id="RadioButton2"     className="custom-control-input"  checked={fields.notification ===2?'checked':''}  onChange={e => { setFields({...fields, notification: 2}); }} />
            <label className="custom-control-label" htmlFor="RadioButton2">Notify on Incoming (Receive) Txns Only</label>
          </div>
          <div className="custom-control custom-radio mb-2">
            <input   name="NotificationType" type="radio" id="RadioButton3"  className="custom-control-input"   checked={fields.notification ===3?'checked':''} onChange={e => { setFields({...fields, notification: 3}); }} />
            <label className="custom-control-label" htmlFor="RadioButton3">Notify on Outgoing (Sent) Txns Only</label>
          </div>
        </div>

        <h4 className="h6 text-secondary">Other Options</h4>
       
        <div className="custom-control custom-checkbox">
          <input name="chkTokenTransfer" type="checkbox" id="chkTokenTransfer" className="custom-control-input" 
            onChange={handleForm('chkTokenTransfer')} 
            checked={fields.chkTokenTransfer}    />
          <label className="custom-control-label" htmlFor="chkTokenTransfer">
            Also Track ERC20 Token Transfers
          </label>
        </div>
       
        <div className="custom-control custom-checkbox">
          <input name="chkToken721Transfer" type="checkbox" id="chkToken721Transfer"  className="custom-control-input"  
            onChange={handleForm('chkToken721')} 
            checked={fields.chkToken721}    />
          <label className="custom-control-label" htmlFor="chkToken721Transfer">
            Also Track ERC721 Token Transfers
          </label>
        </div>

        <div className="custom-control custom-checkbox">
          <input name="chkToken1155Transfer" type="checkbox" id="chkToken1155Transfer" className="custom-control-input"
            onChange={handleForm('chkToken1155')} 
           checked={fields.chkToken1155}
          />
          <label className="custom-control-label" htmlFor="chkToken1155Transfer">
            Also Track ERC1155 Token Transfers
          </label>
        </div>


      </Modal.Body>
      <Modal.Footer>
        <input type="button" value="Add" className="btn btn-sm btn-primary btn-xs" onClick={handleSubmit} />
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={handleHide}>
          Cancel
        </button>
       
      </Modal.Footer>
    </Modal>
  );
  
}

function EditAddressModal({data, user, show, onHide}) {
 
  const [errors, setErrors] = useState({});

  const [fields, setFields] = useState({}); 
  

  const handleForm = field => e => {
    const target = e.target; 
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;  
    setFields({...fields, [field]: value});

    if (e.target.value&& name === 'txtAddress') {
      setErrors({...errors, [field]: undefined});
    }
  }; 

  const handleBlur = key => () => {
    const _errors = {};
    Object.keys(fields).filter(f => f !== 'autologin')
     .forEach(field => {
        _errors[field] = !fields[field];
      });
    if (Object.keys(_errors).some(e => !!_errors[e])) {
      if (_errors[key]) {
        setErrors(_errors);
      }
    } 
  }; 
  const handleHide=()=>{
    onHide(false);
  }
  const keyEditRequest = useRequest(
    body => ({
      url: '/loginapi/sys/user/account/addressEdit',
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
        'X-Access-Token': (user.token ||'')
      },
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  ); 

  useEffect(() => {
    const id = data?.id||0;
    const address = data?.address||'';
    const remark = data?.remark||'';
    const notification = data?.notification||0;
    const chkTokenTransfer =Number(data?.others?.substring(0,1))||0; 
    const chkToken721 = Number(data?.others?.substring(1,2))||0;
    const chkToken1155 = Number(data?.others?.substring(2,3))||0; 
    setFields({
      id:id,
      address: address,
      remark: remark,
      notification: notification,
      chkTokenTransfer:chkTokenTransfer,
      chkToken721:chkToken721,
      chkToken1155:chkToken1155,
    });
  }, [data]);
 
  const handleEdit = () => { 
    const _errors = {}; 
    _errors['address'] =  !fields['address']; 
    if(!!_errors['address']){ 
      setErrors(_errors); 
    }else {  
      keyEditRequest.run(fields).then(res => {
        console.log(res);
        if (res.code !== 200) {
          prompt.error(res.message); 
        } else { 
          prompt.inform('Add success')
          onHide(true);
        }
      },
      err=>{
        console.log(err);
      }); 
    }
 
  }; 
 
  return (
    <Modal show={show} onHide={handleHide}  centered  aria-labelledby="contained-modal-title">
      <Modal.Header closeButton className="p-2">
        <Modal.Title>
        <h5 className="pl-3 modal-title h5 font-weight-bold text-break w-100 mt-2 mb-2">Edit API-KEY Options</h5> 
        </Modal.Title>
        
      </Modal.Header>
      <Modal.Body>
         
        <div className="mb-4">
          <div className="form-group">
            <label htmlFor="txtAddress">HPB Address</label>
            <div className="js-form-message">
              <input name="txtAddress" type="text" value={fields.address} maxLength="42" id="txtAddress"  readonly  className="form-control form-control-sm" required=""     placeholder="0x.." onChange={handleForm('address')}  onBlur={handleBlur('address')} />
                <div className="invalid-feedback" style={{display: errors.address ? 'block' : 'none'}}>
                  Please enter eth address
                </div>
            </div>
            <div className="input-group input-group-sm">
                <span id="revAddress" style={{color:"red",fontSize:"Smaller",display: errors.valiadateAddress ? 'block' : 'none'}} >*Invalid Address</span>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="txtDescription">Description <span className="text-secondary">(Optional)</span></label>
            <input name="txtRemark" type="text" value={fields.remark} maxLength="42" id="txtRemark" 
               onChange={handleForm('remark')}    className="form-control form-control-sm"   placeholder="Short description.." /> 
            <span className="form-text small text-secondary">Max 100 character limit.</span>
          </div>
        </div>
      
        <h4 className="h6 font-weight-bold">Notification Methods</h4>
        <p>You can monitor and receive an alert to your email when an address on your watchlist sends or receives any transactions.</p>
      
        <div className="mb-4">
          <div className="custom-control custom-radio mb-2">
            <input   name="NotificationType" type="radio" id="RadioButton0"  checked={fields.notification ===0? 'checked':''}   onChange={e => { setFields({...fields, notification: 0}); }} 
               className="custom-control-input" />
            <label className="custom-control-label" htmlFor="RadioButton0">No Notification</label>
          </div>
          <div className="custom-control custom-radio mb-2">
            <input   name="NotificationType" type="radio" id="RadioButton1"  className="custom-control-input"    checked={fields.notification ===1?'checked':''}  onChange={e => { setFields({...fields, notification: 1}); }}   />
            <label className="custom-control-label" htmlFor="RadioButton1">Notify on Incoming &amp; Outgoing Txns</label>
          </div>
          <div className="custom-control custom-radio mb-2">
            <input   name="NotificationType" type="radio" id="RadioButton2"     className="custom-control-input"  checked={fields.notification ===2?'checked':''}  onChange={e => { setFields({...fields, notification: 2}); }} />
            <label className="custom-control-label" htmlFor="RadioButton2">Notify on Incoming (Receive) Txns Only</label>
          </div>
          <div className="custom-control custom-radio mb-2">
            <input   name="NotificationType" type="radio" id="RadioButton3"  className="custom-control-input"   checked={fields.notification ===3?'checked':''} onChange={e => { setFields({...fields, notification: 3}); }} />
            <label className="custom-control-label" htmlFor="RadioButton3">Notify on Outgoing (Sent) Txns Only</label>
          </div>
        </div>

        <h4 className="h6 text-secondary">Other Options</h4>
       
        <div className="custom-control custom-checkbox">
          <input name="chkTokenTransfer" type="checkbox" id="chkTokenTransfer" className="custom-control-input" 
            onChange={handleForm('chkTokenTransfer')} 
            checked={fields?.chkTokenTransfer}    />
          <label className="custom-control-label" htmlFor="chkTokenTransfer">
            Also Track ERC20 Token Transfers 
          </label>
        </div>
       
        <div className="custom-control custom-checkbox">
          <input name="chkToken721Transfer" type="checkbox" id="chkToken721Transfer"  className="custom-control-input"  
            onChange={handleForm('chkToken721')} 
            checked={fields?.chkToken721}    />
          <label className="custom-control-label" htmlFor="chkToken721Transfer">
            Also Track ERC721 Token Transfers 
          </label>
        </div>

        <div className="custom-control custom-checkbox">
          <input name="chkToken1155Transfer" type="checkbox" id="chkToken1155Transfer"
           className="custom-control-input"  onChange={handleForm('chkToken1155')}    checked={fields?.chkToken1155} />
          <label className="custom-control-label" htmlFor="chkToken1155Transfer">
            Also Track ERC1155 Token Transfers
          </label>
        </div>


      </Modal.Body>
      <Modal.Footer>
        <div className="d-sm-flex justify-content-end" style={{width: '100%'}}>
          <div className="d-flex">
            <div>
              <input type="button" value="Save Changes" className="btn btn-primary btn-xs" onClick={handleEdit} />
            </div>
            <div className="px-2">
              <input type="button" value="Cancel" className="btn btn-secondary btn-xs" onClick={handleHide} />
            </div>
          </div> 
        </div>
      </Modal.Footer>
    </Modal>
  );
}


function DelAddressTagModal({data, user, show, onHide}){ 
  const keyDeleteRequest = useRequest(
    body => ({
      url: '/loginapi/sys/user/account/addressDelete',
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
        'X-Access-Token': (user.token ||'')
      },
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );

  const handleDelItem = () => {  
    if (!data.id) {
      return;
    }
    const formEncrpty = { 
      id: data.id,
    }
    keyDeleteRequest.run(formEncrpty).then(res => {
      if (res.code === 200) { 
        prompt.inform('Delete success')
        onHide(true);
      } else  {
        prompt.error(res.message) 
      }  
    });

  }; 

  const handleHide=()=>{
    onHide(false);
  }

  return (
    <Modal show={show} onHide={handleHide}   aria-labelledby="contained-modal-title">
      <Modal.Header closeButton className="p-2">
        <Modal.Title>
        <h5 className="pl-3 modal-title h5 font-weight-bold text-break w-100 mt-2 mb-2">Confirmation Required</h5> 
        </Modal.Title>
        
      </Modal.Header>
      <Modal.Body>         
        <div className="mb-4">
          <div className="form-group">           
            <div className="js-form-message">
            Are you sure you wish to unlink the address?
            </div>
            <div className="js-form-message">
              {data.address}
            </div>            
          </div>
        </div> 
      </Modal.Body>
      <Modal.Footer>
        <div className="d-sm-flex justify-content-end" style={{width: '100%'}}>
          <div className="d-flex">
            <div>
              <input type="button" value="Remove" className="btn btn-primary btn-xs" onClick={handleDelItem} />
            </div>
            <div className="px-2">
              <input type="button" value="Cancel" className="btn btn-secondary btn-xs" onClick={handleHide} />
            </div>
          </div> 
        </div>
      </Modal.Footer>
    </Modal>
  );
}



function AddressList({user,listData,onRefresh}) {

  const [currentItem, setCurrentItem] = useState({
    Id:0,
    address: '',
    remark: '',
    notification: 0,
    chkTokenTransfer: 0,
    chkToken721: 0,
    chkToken1155: 0
  });
  const [showEditModal, setShowEditModal] = useState(false); 
  const [showDelModal, setShowDelModal] = useState(false); 
 
  const keyDeleteRequest = useRequest(
    body => ({
      url: '/loginapi/sys/user/account/addressDelete',
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
        'X-Access-Token': (user.token ||'')
      },
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );

  const handleEditHide = (isRefresh) => { 
    setShowEditModal(false);
     if(isRefresh){
      onRefresh()
     }
    
     
  };    
  
  const handleDelHide = (isRefresh) => {  
    setShowDelModal(false);
     if(isRefresh){
      onRefresh()
     } 
  };   


  const data = listData?.myaddress_list || [];
  const counts = listData?.counts || 0;
  const limit_counts = listData?.limit_counts || 0; 

  return (
    <div className="card-body">
      <p className="text-dark mb-4">An Email notification can be sent to you when an address on your watch list sends or receives any transactions.</p>
     
      <div className="d-md-flex justify-content-between align-items-center mb-4">
        <p className="mb-2 mb-lg-0">  
            {counts !==limit_counts  ?  `${counts} address selected(Out of ${limit_counts} max limit)`  : ` Limit of  ${limit_counts} address reached`} 
        </p> 
      </div>
      
      <div className="table-responsive mb-2 mb-md-0">
        <div className="mx-md-auto">
          <figure className="mb-7 ie-data-report">
            <table className="table table-text-normal table-align-middle table-lg-x table-hover">
              <thead className="thead-light">
                <tr> 
                  <th scope="col">Address</th>
                  <th scope="col">Private Nametag</th>
                  <th scope="col">
                      Notification
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) && data.length !== 0 ? (
                  data.map((item, index) => {
                    return (
                      <tr key={index}>
                     
                        <td className="align-top">  
                            <div className="d-flex">
                              <div>
                                  <div className="d-flex align-items-center">
                                      <a className="mr-2" href={`/address/${item.address}`}  title={item.address}>
                                        <span className="text-primary" >
                                          {item.address}
                                        </span>
                                      </a> 
                                      <Copy text={item.address} title="Copy block miner  to clipboard" />
                                  </div>
                                  <div className="font-size-1 mt-1">
                                      <span className="text-secondary">Added on:</span> {item.createTime}
                                  </div> 
                                  <div className="font-size-1 mt-1" style={{maxWidth:'20rem'}}>
                                    <span className="text-secondary">Note: </span>
                                    <span>{item.remark}</span>
                                  </div>
                              </div>
                            </div>
                        </td> 
                        <td className="align-center">--</td>
                        <td className="align-center">
                          
                          {item.notification ===0?
                          <div className="d-flex align-items-baseline"  style={{minWidth:'10rem'}}>
                            <i className="fal fa-bell-slash fa-fw mr-2" data-toggle="tooltip" 
                            title="" data-original-title="Notifications Disabled"></i> None</div>
                            : 
                           <div className="d-flex align-items-baseline" style={{minWidth:'10rem'}}>
                            <i className="fal fa-envelope fa-fw mr-2"></i> Email
                           </div>
                          }
                          
                        </td>

                        <td className="align-center text-nowrap">
                          <LinkWithTooltip placement="top" tooltip="Edit Address"> 
                              <a  className="btn btn-xs btn-white border text-nowrap mr-1"
                                href="#!"    onClick={e => {
                                  e.preventDefault();
                                  setCurrentItem(item);
                                  setShowEditModal(true);
                                }}  >
                                Edit <i className="fal fa-pen ml-1"></i>
                              </a>
                          </LinkWithTooltip> 

                          <LinkWithTooltip placement="top" tooltip="Remove Address"> 
                              <a  className="btn btn-xs btn-white border text-nowrap mr-1"
                                href="#!"    onClick={e => {
                                  e.preventDefault();
                                  setCurrentItem(item);
                                  setShowDelModal(true);
                                }}>
                                  Remove <i className="fal fa-trash text-danger"></i>
                              </a>
                          </LinkWithTooltip> 
                        </td>

                      </tr>
                    );
                  })
                ) : 
                // listApiKeyRequest.loading ? (
                //   <tr />
                // ) : 
                (
                  <tr>
                    <td colSpan="3" className="align-top">
                      <center>
                        <div className="alert alert-secondary mt-4" role="alert">
                          <i className="fal fa-info-circle"></i> <span className="text-dark">There are no matching entries.</span>
                        </div>
                      </center>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </figure>
        </div>
      </div> 

      <EditAddressModal user={user} data={currentItem} show={showEditModal} onHide={handleEditHide} />
      <DelAddressTagModal user={user} data={currentItem} show={showDelModal} onHide={handleDelHide} />
    </div>
  );
}

export default function MyAddress() { 

  const userContext = useContext(UserContext);
  const user = userContext.user || {};

  const [showAddAddress, setShowAddAddress] = useState(false);
  const [showList, setShowList] = useState(true);
  
  
  const [state, setState] = useState({
    body: {
      start: '1',
      length: '10',
    },
  });
  
  const listMyAddressRequest = useRequest(
    body => ({
      url: '/loginapi/sys/user/account/addressList',
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
        'X-Access-Token': (user.token ||'')
      },
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  const handleCurrentPage = (page) =>{     
    setState({...state, body: {...state.body, start:page + ''}}); 
  }

  useEffect(() => {
    listMyAddressRequest.run(state.body);
  }, [state]);
  

 
  if (!user.token) {
    return (window.location.href = '/login');
  }

  
  const handleRefresh =()=>{
    listMyAddressRequest.run(state.body);
  }
 
  const handleCreateHide = (isRefresh) => { 
    if(isRefresh){
      setShowList(false);
      listMyAddressRequest.run(state.body);
      setTimeout(() => {
        setShowList(true);
      }, 10);
    } 
    setShowAddAddress(false);
  };

  if (listMyAddressRequest.loading) {
    return <Loading />;
  }
 
  const listData = listMyAddressRequest.data||[];
  const counts = listMyAddressRequest.data?.counts || 0; 
  let totalPage = Math.ceil(Number(counts) / 10);

  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="myaddress" />
          <div className="col-md-9">
            <div className="card mb-4">
              <div className="card-header d-md-flex justify-content-between align-items-center">
                <div className="d-flex flex-wrap">
                  <div className="mr-2 mb-2 mb-md-0">
                    <h2 className="card-header-title mt-1">My Watch List</h2>
                  </div>       

                  <div className="mr-2 mb-2 mb-md-0">
                    { 
                      <div className="position-relative order-1 order-md-2">
                        <a
                          href="#!"
                          id="btnWatchAdd"
                          data-toggle="modal"
                          data-target="#myModal"
                          rel="tooltip"
                          title=""
                          className="btn btn-xss btn-primary rounded"
                          data-original-title="Add a new address to your watch list"
                          onClick={() => {
                            setShowAddAddress(!showAddAddress);
                          }}
                        >
                        <i className="fa fa-plus-square fa-lg mr-1" style={{marginLeft: '-3px', marginBottom: '3px'}}></i>Add
                        </a>
                      </div>  
                    } 
                  </div>

                </div>
                <div className="d-flex ml-auto"></div>
              </div>

              {showList ? <AddressList user={user} listData={listData}  onRefresh={handleRefresh} /> : undefined}
              <div className="card-footer px-4 d-flex justify-content-md-end align-items-center text-secondary mb-2 mb-md-0 my-3">
                <div className="d-inline-block">
                  <Pager2 current={state.body.start} total={totalPage} pageChange= {handleCurrentPage}  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <CreateAddressModal user={user} show={showAddAddress} onHide={handleCreateHide} />
    </main>
  );
}
