import {useTranslation} from 'react-i18next';
export default function Header({data}) {
  const {t} = useTranslation(['token']);
  return (
    <div className="container py-3">
      <div className="d-lg-flex align-items-center">
        <div className="mb-3 mb-lg-0">
          <h1 className="h4 media align-items-center text-dark">
            <img className="u-sm-avatar mr-2" src={data.token_image ? data.token_image : '/images/main/empty-token.png'}/>
            <div className="media-body">
              {t('token.title')} <span className="text-secondary small">{data.token_name}</span>
            </div>
          </h1>
          <div className="mt-1">
            <a
              className="u-label u-label--xs u-label--secondary"
              href="/tokens/label/heco-pegged"
              data-toggle="tooltip"
              data-title="Wrapped tokens that are backed by tokens vaults in HPB."
              data-original-title=""
              title=""
            >
              {data.token_name} <i className="fa fa-info-circle text-secondary ml-1"></i>
            </a>
            &nbsp;
          </div>
        </div>

        <div className="d-flex flex-wrap ml-lg-auto"></div>
      </div>
    </div>
  );
}
