import {useEffect} from 'react';
import {useRequest} from 'ahooks';  
import {useTranslation} from 'react-i18next';
export default function VerifyCode({value, onChange}) {
  const {t} = useTranslation(['login']);
  const verfiyCodeRequest = useRequest(
    body => ({
      url: '/loginapi/sys/login/getCaptcha',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    verfiyCodeRequest.run();
  }, []);

  const handleRefresh = () => {
    verfiyCodeRequest.run();
  }; 
 
  return (
    <div style={{position: 'relative'}}>
      <input maxLength="100"  className="form-control form-control-sm"
        type="text"  placeholder={t('login.userlogin.Captcha')}   tabIndex="20"    data-msg="Please enter a valid captcha."   value={value}
        onChange={e => {
          onChange({
            captchaId: verfiyCodeRequest.data?.captcha_id,
            verifyCode: e.target.value,
          });
        }}
      />
      {verfiyCodeRequest.data?.image_data ? (
        <img src={verfiyCodeRequest.data?.image_data} onClick={handleRefresh} style={{position: 'absolute', top: '1px', right: '1px', height: '39px', borderRadius: '4px'}} />
      ) : undefined}
    </div>
  );
}
