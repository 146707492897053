import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import Loading from '../../components/Loading';
import Pager2 from '../../components/Pager2';
import {HexToDec} from '../../commons/utils';
import {useTranslation} from 'react-i18next';
export default function Inventory({a, token, overview}) {
  const {t} = useTranslation(['token']);
  const [state, setState] = useState({
    body: {
      start: '1',
      length: '50',
      field: a ? 'address' : undefined,
      value: a,
      token_address: token,
    },
  });

  const tokenInventoryRequest = useRequest(
    body => ({
      url: '/blockBrowser/tokens/address/tokenInventory',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  const handleCurrentPage = (page) =>{     
    setState({...state, body: {...state.body, start:page + ''}}); 
  }


  useEffect(() => {
    tokenInventoryRequest.run(state.body);
  }, [state]);

  if (tokenInventoryRequest.loading) {
    return <Loading />;
  }

  const data = tokenInventoryRequest.data?.token_inventory_list || [];
  const counts = tokenInventoryRequest.data?.counts || 0;
  const token_inventory_counts = tokenInventoryRequest.data?.token_inventory_counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);

  return (
    <div className="tab-pane fade show active">
      <div className="d-md-flex justify-content-between mb-4">
        <p className="mb-2 mb-md-0">
          <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>
          &nbsp;{t('token.Inventory.tip1')} {token_inventory_counts} {t('token.Inventory.tip2')} {counts} {t('token.Inventory.tip3')})
        </p>
        <Pager2 current={state.body.start} total={totalPage} pageChange= {handleCurrentPage}  />
      </div>
      <div id="resulttable">
        <table className="table table-hover">
          <thead className="thead-light">
            <tr>
              <th scope="col" width="1">
              {t('token.Inventory.Token_id')}
              </th>
              <th scope="col">{t('token.Inventory.Owner')} </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => {
              return (
                <tr key={i}>
                  <td>
                    <a href={`/token/${token}?a=${item.token_id}`} target="_parent">
                      {HexToDec(item.token_id)}
                    </a>
                  </td>
                  <td>
                    <a href={`/token/${token}?a=${item.holders_address}`} target="_parent">
                      {item.holders_address}
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-md-end align-items-center text-secondary mb-2 mb-md-0 my-3">
        <div className="d-inline-block">
        <Pager2 current={state.body.start} total={totalPage} pageChange= {handleCurrentPage}  />
        </div>
      </div>
    </div>
  );
}
