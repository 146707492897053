import {useContext,useState} from 'react';
import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext'; 
 
import {Modal } from 'react-bootstrap';


function AddMyNotesTx({value, show, onHide}){
  const userContext = useContext(UserContext);
  const user = userContext.user || {}; 

  const handleContinue = () => {
     alert(1)
  };

   
  return (
    <Modal show={show} onHide={onHide}  aria-labelledby="contained-modal-title-vcenter" >
      <Modal.Header closeButton className="p-2">
        <h5 className="pl-3 modal-title h6 font-weight-bold text-break w-100 mt-2 mb-2">Add a new Txn hash note</h5>
      </Modal.Header>
      <Modal.Body> 

        <div className="form-group row">
          <label className="col-sm-12 mt-12" for="Txnhash">&nbsp;Txn Hash :</label>
          <div className="js-form-message input-group input-group-sm col-sm-12">
            <input name="ctl00$ContentPlaceHolder1$txtTxnHash" type="text" maxlength="66" id="ContentPlaceHolder1_txtTxnHash"
             className="form-control" required="" data-msg="Please enter a valid Txn hash" data-error-class="u-has-error"
              data-success-class="u-has-success" placeholder="Enter a Txn hash" />
            <span id="ContentPlaceHolder1_rfvTxnHash" style={{"color":"red","fontSize":"Smaller","display":"none"}}><br /> *Invalid Txn hash</span>
            <p></p>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-12 mt-12" for="Txnhash">&nbsp;View / Update Private Note :</label>
          <div className="input-group input-group-sm col-sm-12">
            <textarea name="ctl00$ContentPlaceHolder1$txtPrivateNoteArea" rows="2" cols="20" id="ContentPlaceHolder1_txtPrivateNoteArea" spellcheck="false" class="form-control" onkeyup="javascript:Count(this);" onchange="javascript:Count(this);"></textarea>
          </div>
          <div className="small text-secondary mt-1 ml-3">
            <span id="privatenotetip"></span>
          </div>
        </div>
 

      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Cancel
        </button> 
        <input type="button" value="Continue" className="btn btn-sm btn-primary btn-xs" onClick={handleContinue} />
      </Modal.Footer>
    </Modal>
  );
}
 


export default function MyNotesTx() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};

  
  const [showAddMyNotesTx, setShowAddMyNotesTx] = useState(false);

  const hideAddMyNotesTx = () => {
    setShowAddMyNotesTx(false);
  }; 
  //-----

  if (!user.token) {
    return (window.location.href = '/login');
  }

  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="mynotes_tx" />
          <div className="col-md-9">
            <div className="card">
              <div className="card-header d-md-flex justify-content-between align-items-center">
                <div className="d-flex flex-wrap">
                  <div className="mr-2 mb-2 mb-md-0">
                    <h2 className="card-header-title mt-1">My Transactions Private Notes</h2>
                  </div>
                  <div className="mr-2 mb-2 mb-md-0">
                    <a   href="/#" id="ContentPlaceHolder1_addnew"   data-toggle="modal"  data-target="#responsive"
                      rel="tooltip"   title=""   className="btn btn-xss btn-primary rounded" 
                     data-original-title ="Add a new transaction private note"
                      onClick={e => {   e.preventDefault(); setShowAddMyNotesTx(true); }} 
                    >
                      <i className="fa fa-plus-square fa-lg mr-1" style={{marginLeft: '-3px', marginBottom: '3px'}}></i>Add
                    </a>
                  </div>
                </div>
                <div className="d-flex ml-auto">
                  <div className="position-relative order-1 order-md-2"> 

                    <a  id="searchFilterInvoker"  className="btn btn-sm btn-icon btn-primary"
                        href="/#"  role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"   >
                        <i className="fa fa-search btn-icon__inner"></i>
                    </a>
                    <div id="searchFilter"  className="dropdown-menu dropdown-menu-right p-2"  aria-labelledby="searchFilterInvoker"   style={{width: '265px', animationDuration: '300ms'}} >
                      <form action="/mynotes_tx" method="get" className="js-focus-state input-group input-group-sm w-100" autoComplete="off">
                            <input id="q" name="q" type="search" defaultValue=""   className="form-control" placeholder="Search Txn Private Notes" />
                            <div className="input-group-append">
                              <button type="submit" className="btn btn-primary" data-toggle="tooltip" title="" data-original-title="Search Txn Private Notes">
                                Find
                              </button>
                            </div>
                        </form>
                    </div> 
                
                    {/* <a
                      id="searchFilterInvoker"  className="btn btn-sm btn-icon btn-primary"
                      href="javascript:;"   role="button"   aria-controls="searchFilter"
                      aria-haspopup="true" aria-expanded="false"
                      data-unfold-target="#searchFilter"   data-unfold-type="css-animation"
                      data-unfold-duration="300" data-unfold-delay="300" data-unfold-hide-on-scroll="false"
                      data-unfold-animation-in="slideInUp"  data-unfold-animation-out="fadeOut"
                    >
                      <i className="fa fa-search btn-icon__inner"></i>
                    </a>
                    <div id="searchFilter" className="dropdown-menu dropdown-unfold dropdown-menu-sm-right p-2 u-unfold--css-animation u-unfold--hidden fadeOut" aria-labelledby="searchFilterInvoker"   style={{width: '265px', animationDuration: '300ms'}} >
                      <form action="/mynotes_tx" method="get" className="js-focus-state input-group input-group-sm w-100" autocomplete="off">
                        <input id="q" name="q" type="search" value="" className="form-control" placeholder="Search Txn Private Notes" />
                        <div className="input-group-append">
                          <button type="submit" className="btn btn-primary" data-toggle="tooltip" title="" data-original-title="Search Txn Private Notes">
                            Find
                          </button>
                        </div>
                      </form>
                    </div> */}

                  </div>
                </div>
              </div>
              <div className="card-body">
                <span id="spanResult"></span>
                <div className="d-md-flex justify-content-between align-items-center mb-4">
                  <p className="mb-2 mb-lg-0">
                    <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse" style={{display: 'none', marginTop: '4px', marginRight: '4px'}}>
                      &nbsp;
                    </i>
                    &nbsp;2 used (Out of 1000 max quota)
                  </p>
                  <nav className="mb-4 mb-md-0" aria-label="page navigation">
                    <ul className="pagination pagination-sm mb-0">
                      <li className="page-item disabled">
                        <span className="page-link">First</span>
                      </li>
                      <li className="page-item disabled">
                        <span className="page-link">
                          <i className="fa fa-chevron-left small"></i>
                        </span>
                        <span className="sr-only">Previous</span>
                      </li>
                      <li className="page-item disabled">
                        <span className="page-link text-nowrap">
                          Page <strong className="font-weight-medium">1</strong> of <strong className="font-weight-medium">1</strong>
                        </span>
                      </li>
                      <li className="page-item disabled">
                        <span className="page-link">
                          <i className="fa fa-chevron-right small"></i>
                        </span>
                        <span className="sr-only">Previous</span>
                      </li>
                      <li className="page-item disabled">
                        <span className="page-link">Last</span>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="table-responsive mb-2 mb-md-0">
                  <div className="mx-md-auto">
                    <figure id="SVGdataReport1" className="mb-7 ie-data-report">
                      <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Action</th>
                            <th scope="col">Transaction Private Note</th>
                            <th scope="col" data-toggle="tooltip" title="" data-original-title="UTC TimeZone (yyyy-mm-dd)">
                              Created
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                         
                        </tbody>
                      </table>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddMyNotesTx show={showAddMyNotesTx} user={user} onHide={hideAddMyNotesTx} value={111}   />
       
    
    </main>
  );
}
