export default function Vyper() {
  return (
    <main id="content" role="main">
      <div className="container">
        <div className="border-bottom py-3 mb-3">
          <div className="d-flex mb-2 mb-sm-0">
            <h1 className="h4 mb-0">Vyper Online Compiler (Experimental)</h1>
          </div>
        </div>
        <p className="mb-0">Compiles Vyper source code and outputs the ABI, ByteCode and Runtime Bytecode</p>
        <p className="mb-0">
          <span id="spinwheel"  style={{display: 'none'}}>
            <img src="/images/spinnergear.svg" width="40" />
            Please wait..
          </span>
        </p>
      </div>
      <div className="container space-bottom-2">
        <div className="py-4">
          <form method="post" action="./vyper" id="ctl00" className="js-validate" novalidate="novalidate">
            <div className="js-form-message form-group">
              <label for="ddlCompilerVersions" id="lblCompilerVersion">
                [Step 1] Select Compiler Version
              </label>
              <div className="js-focus-state input-group input-group-sm mb-2 w-50">
                <select
                  name="ctl00$ContentPlaceHolder1$ddlCompilerVersions"
                  id="ContentPlaceHolder1_ddlCompilerVersions"
                  className="custom-select custom-select-sm"
                  required=""
                  data-msg="Compiler Version Required"
                  data-error-class="u-has-error"
                  data-success-class="u-has-success"
                >
                  <option value="">[Please Select]</option>
                  <option value="vyper:0.2.12">vyper:0.2.12</option>
                  <option value="vyper:0.2.11">vyper:0.2.11</option>
                  <option value="vyper:0.2.8">vyper:0.2.8</option>
                  <option value="vyper:0.2.7">vyper:0.2.7</option>
                  <option value="vyper:0.2.5">vyper:0.2.5</option>
                  <option value="vyper:0.2.4">vyper:0.2.4</option>
                  <option value="vyper:0.2.3">vyper:0.2.3</option>
                  <option value="vyper:0.2.2">vyper:0.2.2</option>
                  <option value="vyper:0.2.1">vyper:0.2.1</option>
                  <option value="vyper:0.2.0">vyper:0.2.0</option>
                  <option value="vyper:0.1.0b17">vyper:0.1.0b17</option>
                  <option value="vyper:0.1.0b16">vyper:0.1.0b16</option>
                  <option value="vyper:0.1.0b15">vyper:0.1.0b15</option>
                  <option value="vyper:0.1.0b14">vyper:0.1.0b14</option>
                  <option value="vyper:0.1.0b13.hotfix">vyper:0.1.0b13.hotfix</option>
                  <option value="vyper:0.1.0b13">vyper:0.1.0b13</option>
                  <option value="vyper:0.1.0b12">vyper:0.1.0b12</option>
                  <option value="vyper:0.1.0b11">vyper:0.1.0b11</option>
                  <option value="vyper:0.1.0b10">vyper:0.1.0b10</option>
                  <option value="vyper:0.1.0b9">vyper:0.1.0b9</option>
                  <option value="vyper:0.1.0b8">vyper:0.1.0b8</option>
                  <option value="vyper:0.1.0b7">vyper:0.1.0b7</option>
                  <option value="vyper:0.1.0b4">vyper:0.1.0b4</option>
                </select>
              </div>
            </div>
            <div className="js-form-message form-group mb-3">
              <label for="txtSourceCode" id="lblSourceCode">
                [Step 2] Enter Source Code Below{' '}
              </label>
              <div className="js-focus-state">
                <textarea
                  name="ctl00$ContentPlaceHolder1$txtSourceCode"
                  rows="10"
                  cols="20"
                  id="ContentPlaceHolder1_txtSourceCode"
                  className="form-control w-100"
                  required=""
                  data-msg="Please enter Source Code"
                  data-error-class="u-has-error"
                  data-success-class="u-has-success"
                  placeholder="Please enter Source Code"
                ></textarea>
              </div>
            </div>
            <p className="font-size-1">
              <strong>Tip:</strong> Try compiling the sample
              <font color="blue">
                <a
                  rel="nofollow noopener"
                  href="https://raw.githubusercontent.com/prysmaticlabs/prysm/62f304e668bc78c4078cd376ba141b58eea777ef/contracts/deposit-contract/depositContract.v.py"
                  target="_blank"
                >
                  Prysmaticlabs Contract
                </a>
              </font>
              (for vyper version 0.1.0b7)
            </p>
            <div className="text-md-right">
              <input
                type="submit"
                name="ctl00$ContentPlaceHolder1$btnSubmit"
                value="Compile Vyper"
                onclick="var isValid = validatecheck(''); if (isValid) {var $btn = $('#spinwheel'); $btn.show(); window.location.hash = '#'; buttonclicked = true;};"
                id="ContentPlaceHolder1_btnSubmit"
                className="btn btn-sm btn-primary mr-2"
              />
              <input
                type="submit"
                name="ctl00$ContentPlaceHolder1$btnReset"
                value="Reset"
                id="ContentPlaceHolder1_btnReset"
                className="btn btn-sm btn-soft-secondary"
                formnovalidate="formnovalidate"
              />
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}
