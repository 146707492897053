import {useState, useContext, useEffect} from 'react';
import {useRequest} from 'ahooks';
import Modal from 'react-bootstrap/Modal';

import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext'; 
import React from 'react';
import LinkWithTooltip from '../../components/LinkWithTooltip';
import Copy from '../../components/Copy';
import { encrypt } from '../../commons/utils';
import {useTranslation} from 'react-i18next';
function CreateApiKeyModal({user, show, onHide}) {

  const {t} = useTranslation(['myApiKey']);

  const [fields, setFields] = useState({
    api_key_name: '',
    autologin: false
  });
  const [error, setError] = useState();

  const keyCreateRequest = useRequest(
    body => ({
      url: '/loginapi/sys/user/apiKey/keyCreate',
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
        'X-Access-Token': (user.token ||'')
      },
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );

  const handleAddApiKey = () => {

    const _errors = {}; 
    Object.keys(fields)
      .filter(f => f !== 'autologin')
      .forEach(field => {
        _errors[field] = !fields[field];
      });


    if(Object.keys(_errors).some(e => !!_errors[e])) {
      setError(`${t('myApiKey.error')}`);
      return;
    } else { 
      const formEncrpty = {
        apiKeyName: encrypt(fields.api_key_name),
      }
      keyCreateRequest.run(formEncrpty).then(res => {
        console.log(res);
        if (res.code !== 200) {
          setError(res.message);
        } else {
          fields.api_key_name = '';
          prompt.inform(`${t('myApiKey.addtip')}`)
          onHide();
        }
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered  aria-labelledby="contained-modal-title">
      <Modal.Header closeButton className="p-2">
          <h5 className="pl-3 modal-title h5 font-weight-bold text-break w-100 mt-2 mb-2">{t('myApiKey.Create')}</h5> 
      </Modal.Header>
      <Modal.Body>
        <div className="form-group row">
          <div className="alert alert-danger" style={{display: error ? 'block' : 'none', width: '100%'}}>
            {error}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-3" htmlFor="AppName">
          {t('myApiKey.AppName')}  :
          </label>
          <div className="input-group input-group-sm col-sm-9">
            <input
              type="text"
              maxLength="30"
              className="form-control"
              placeholder={t('myApiKey.AppName')}
              value={fields.api_key_name}
              onChange={e => {
                setFields({...fields, api_key_name: e.target.value});
              }}
            />
              
            <p></p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <input type="button" value={t('myApiKey.Add')} className="btn btn-sm btn-primary btn-xs" onClick={handleAddApiKey} />
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
        {t('myApiKey.Cancel')}
        </button>
       
      </Modal.Footer>
    </Modal>
  );
}

function EditApiKeyModal({data = {}, user, show, onHide}) {
  const {t} = useTranslation(['myApiKey']);
  const [error, setError] = useState();
  // const [showSuccess, setShowSuccess] = useState(false);

  const [fields, setFields] = useState({
    api_key_id: data.apiKeyId || '',
    api_key_name: data.apiKeyName || '',
  }); 
  
  const keyEditRequest = useRequest(
    body => ({
      url: '/loginapi/sys/user/apiKey/keyEdit',
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
        'X-Access-Token': (user.token ||'')
      },
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );

  const keyDeleteRequest = useRequest(
    body => ({
      url: '/loginapi/sys/user/apiKey/keyDelete',
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
        'X-Access-Token': (user.token ||'')
      },
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );
  useEffect(() => {
    setTimeout(() => {
      setFields({
        api_key_id: data.apiKeyId,
        api_key_name: data.apiKeyName,
      });
    }, 100);
  }, [data]);

  const handleEdit = () => { 
    const _errors = {}; 
    Object.keys(fields)
      .filter(f => f !== 'autologin')
      .forEach(field => {
        _errors[field] = !fields[field];
      });


    if(Object.keys(_errors).some(e => !!_errors[e])) {
      setError(`${t('myApiKey.error')}`); 
      return;
    } else { 
      const formEncrpty = {
        apiKeyName: encrypt(fields.api_key_name),
        apiKeyId: encrypt(fields.api_key_id),
      }
      keyEditRequest.run(formEncrpty).then(res => {
        if (res.code !== 200) {
          setError(res.message);
        } else { 
          prompt.inform(`${t('myApiKey.edittip')}`)
          fields.api_key_name = '';
          onHide();
        }
      });
    }  
 
  };

  const handleDel = (e)=>{ 
    e.preventDefault();  
    if (!fields.api_key_name) {
      return;
    }
    const formEncrpty = { 
      apiKeyId: encrypt(fields.api_key_id),
    }
    keyDeleteRequest.run(formEncrpty).then(res => {
      if (res.code === 200) {
        fields.api_key_name = ''; 
        prompt.inform(`${t('myApiKey.deltip')}`)
        onHide();
      } else  {
        setError(res.message);
      }  
    });
    
  }
  // const showError = typeof keyEditRequest.data?.code !== 'undefined' && keyEditRequest.data?.code !== 200;
  // const showSuccess = typeof keyEditRequest.data?.code !== 'undefined' && keyEditRequest.data?.code === 200;

  return (
    <Modal show={show} onHide={onHide}  centered  aria-labelledby="contained-modal-title">
      <Modal.Header closeButton className="p-2">
        <Modal.Title>
        <h5 className="pl-3 modal-title h5 font-weight-bold text-break w-100 mt-2 mb-2">{t('myApiKey.editOption')}</h5> 
        </Modal.Title>
        
      </Modal.Header>
      <Modal.Body>
        <form method="post" className="mx-md-auto">
          <div className="alert alert-danger" style={{display: error ? 'block' : 'none', width: '100%'}}>
            {error}
          </div>
          {/* <div className="alert alert-danger" style={{display: showError ? 'block' : 'none'}}>
            {keyEditRequest.data?.message}
          </div> */}  
          {/* <div className="alert alert-success" style={{display: showSuccess ? 'block' : 'none'}}>
            <strong>Status:</strong> Successfully Updated APIKey <strong>{data.apiKeyToken}</strong> options
          </div> */}

          <div className="form-group row">
            <label className="col-sm-3" htmlFor="Address">
              {t('myApiKey.APIKey')}
            </label>
            <div className="col-sm-9">
              <strong>{data.apiKeyToken}</strong>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3" htmlFor="Description">
            {t('myApiKey.AppName')}
            </label>
            <div className="input-group input-group-sm col-sm-9">
              <input
                type="text"
                value={fields.api_key_name || ''}
                className="form-control"
                onChange={e => { setFields({...fields, api_key_name: e.target.value}); }}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-sm-flex justify-content-between" style={{width: '100%'}}>
          <div className="d-flex">
            <div>
              <input type="button" value={t('myApiKey.save')} className="btn btn-primary btn-xs" onClick={handleEdit} />
            </div>
            <div className="px-2">
              <input type="button" value={t('myApiKey.return')} className="btn btn-secondary btn-xs" onClick={onHide} />
            </div>
          </div>
          <div className="ml-auto">
            <a href="#!" className="btn btn-danger btn-xs"   onClick={handleDel} >
              <i className="far fa-trash-alt" data-toggle="tooltip" title="" data-original-title="Unlink Address from Watch List"></i>&nbsp; {t('myApiKey.delete')}
            </a>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function ApiKeyList({user,listData,onRefresh}) {
  const {t} = useTranslation(['myApiKey']);
  const [currentApiKey, setCurrentApiKey] = useState();
  const [showEditModal, setShowEditModal] = useState(false); 

  // const [state, setState] = useState({
  //   body: {
  //     start: '1',
  //     length: '50',
  //   },
  // });
  // const listApiKeyRequest = useRequest(
  //   body => ({
  //     url: '/loginapi/sys/user/apiKey/keyList',
  //     method: 'post',
  //     headers: { 
  //       'Content-Type': 'application/json',
  //       'X-Access-Token': (user.token ||'')
  //     },
  //     body: JSON.stringify(body),
  //   }),
  //   {manual: true},
  // );
  // useEffect(() => {
  //   listApiKeyRequest.run(state.body);
  // }, []);

  const handleEditHide = () => {
      // debugger
      // listApiKeyRequest.run(state.body);
      setShowEditModal(false);
      onRefresh()
  };
 
  const data = listData?.api_key_list || [];
  const counts = listData?.counts || 0;
  const limit_counts = listData?.limit_counts || 0;
  // const totalPage = Math.ceil(Number(counts) / state.body.length); 

  return (
    <div className="card-body">
      <div className="d-md-flex justify-content-between align-items-center mb-4">
        <p className="mb-2 mb-lg-0">
            {counts !==limit_counts  ?  `${counts} ${t('myApiKey.tip2')}`  : `  ${t('myApiKey.tip3')}`} 
        </p>
        {/* <nav className="mb-4 mb-md-0" aria-label="page navigation">
          <div>
            <Pager current={state.body.start} total={totalPage} path="/txs" />
          </div>
        </nav> */}
      </div>
      <div className="table-responsive mb-2 mb-md-0">
        <div className="mx-md-auto">
          <figure className="mb-7 ie-data-report">
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th scope="col">{t('myApiKey.table.Action')}</th>
                  <th scope="col">{t('myApiKey.table.apikey')}</th>
                  <th scope="col">{t('myApiKey.table.visit')}</th>
                  <th scope="col" data-toggle="tooltip" title="" data-original-title="UTC TimeZone (yyyy-mm-dd)">
                  {t('myApiKey.table.Created')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) && data.length !== 0 ? (
                  data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-top text-nowrap">
                          <LinkWithTooltip placement="top" tooltip={t('myApiKey.Modify')}> 
                              <a  className="btn btn-outline-secondary btn-xss mr-1"
                                href="#!"    onClick={e => {
                                  e.preventDefault();
                                  setCurrentApiKey(item);
                                  setShowEditModal(true);
                                }}  >
                                {t('myApiKey.Edit')}
                              </a>
                          </LinkWithTooltip> 

                        </td>
                        <td className="align-top"> 
                            <span className="font-size-2 text-black mr-2">{item.apiKeyToken}</span> 
                            <Copy text={item.apiKeyToken} title="Copy block miner  to clipboard" />
                          <span className="d-block text-muted">{t('myApiKey.AppName')}: {item.apiKeyName}</span>
                        </td>
                        <td className="align-top">{item.visitCount}</td>
                        <td className="align-top">{item.createTime}</td>
                      </tr>
                    );
                  })
                ) : 
                // listApiKeyRequest.loading ? (
                //   <tr />
                // ) : 
                (
                  <tr>
                    <td colSpan="3" className="align-top">
                      <center>
                        <div className="alert alert-secondary mt-4" role="alert">
                          <i className="fal fa-info-circle"></i> <span className="text-dark">You have yet to create an Api-Key Token.</span>
                        </div>
                      </center>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </figure>
        </div>
      </div>
      <EditApiKeyModal user={user} data={currentApiKey} show={showEditModal} onHide={handleEditHide} />
    </div>
  );
}

export default function MyApiKey() {
  const {t} = useTranslation(['myApiKey']);
  const userContext = useContext(UserContext);
  const user = userContext.user || {}; 
  const [show, setShow] = useState(false);
  const [showList, setShowList] = useState(true);

  const [state, setState] = useState({
    body: {
      start: '1',
      length: '50',
    },
  });
  
  const listApiKeyRequest = useRequest(
    body => ({
      url: '/loginapi/sys/user/apiKey/keyList',
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
        'X-Access-Token': (user.token ||'')
      },
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    listApiKeyRequest.run(state.body);
  }, [state]);
  

  if (!user.token) {
    return (window.location.href = '/login');
  }

  const handleRefresh =()=>{
    listApiKeyRequest.run(state.body);
  }

 
  const handleCreateHide = () => {
    setShowList(false);
    listApiKeyRequest.run(state.body);
    setTimeout(() => {
      setShowList(true);
    }, 10);
    setShow(false);
  };

  const listData = listApiKeyRequest.data||[];
  const counts = listApiKeyRequest.data?.counts || 0;
  const limit_counts = listApiKeyRequest.data?.limit_counts || 0;


  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="myapikey" />
          <div className="col-md-9">
            <div className="card">
              <div className="card-header d-md-flex justify-content-between align-items-center">
                <div className="d-flex flex-wrap">
                  <div className="mr-2 mb-2 mb-md-0">
                    <h2 className="card-header-title mt-1">{t('myApiKey.title')}</h2>
                  </div>
                 
                  <div className="mr-2 mb-2 mb-md-0">
                    {
                      ((limit_counts !==0) &&(counts !==limit_counts))?
                      <div className="position-relative order-1 order-md-2">
                        <a
                          href="#!"
                          id="ContentPlaceHolder1_addnew"
                          data-toggle="modal"
                          data-target="#myModal"
                          rel="tooltip"
                          title=""
                          className="btn btn-xss btn-primary rounded"
                          data-original-title="Create a new API-KEY token"
                          onClick={() => {
                            setShow(!show);
                          }}
                        >
                          <i className="fa fa-plus-square fa-lg mr-1" style={{marginLeft: '-3px', marginBottom: '3px'}}></i>{t('myApiKey.Add')}
                        </a>
                      </div> 
                      :''
                    }
                     
                   
                  </div>
                </div>
                <div className="d-flex ml-auto"></div>
              </div>
              {showList ? <ApiKeyList user={user} listData={listData} onRefresh={handleRefresh} /> : undefined}
            </div>
          </div>
        </div>
      </div>
      <CreateApiKeyModal user={user} show={show} onHide={handleCreateHide} />
    </main>
  );
}
