const blocks = { 
    'blocks.title': '区块 ', 
    'blocks.table.block': '区块',
    'blocks.table.age': '块龄',
    'blocks.table.time': '日期时间(UTC)',
    'blocks.table.txn': '交易',
    'blocks.table.validator': '验证者',
    'blocks.table.difficulty': '难度值',
    'blocks.table.gasused': '燃料用量',
    'blocks.table.gaslimit': '燃料限制',
    'blocks.table.gasCollection': '燃料收集', 
    'blocks.tip1': ' 区块 ',
    'blocks.tip2': ' 至',
    'blocks.tip3': '总共',
    'blocks.tip4': '个区块',
    'blocks.tip5': '显示',
    'blocks.tip6': '记录', 

};

export default blocks;
