const gasprice = { 
    'gasprice.title1': '燃料价格实时查询',
    'gasprice.title2': '根据HPB链上的转移数据每3秒刷新一次',
   
    'gasprice.tip1': '快',
    'gasprice.tip2': '标准',
    'gasprice.tip3': '慢',
    

};

export default gasprice;
