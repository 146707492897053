import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import moment from 'moment';
import Loading from '../../components/Loading';
import Pager2 from '../../components/Pager2';
import TimeTD from '../../components/TimeTD';
import LinkWithTooltip from '../../components/LinkWithTooltip';
import {HexToDec} from '../../commons/utils';

import {useTranslation} from 'react-i18next';
export default function Transactions({a, token, overview,queryA}) {
  const defaultLNG = window.localStorage.getItem('lng') || 'en_US';
  moment.locale(defaultLNG);
  
  const {t} = useTranslation(['token']);
  const [state, setState] = useState({
    body: {
      start: '1',
      length:'25',
      field: a ? 'address' : undefined,
      value: queryA,
      token_address: token,
      token_types: overview.token_types,
    },
  });

  const [type, setType] = useState('showAge');   

  const tokenTransactionsRequest = useRequest(
    body => ({
      url: '/blockBrowser/tokens/address/tokenTransactions',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  const handleCurrentPage = (page) =>{     
    setState({...state, body: {...state.body, start:page + ''}}); 
  } 

  useEffect(() => {
    tokenTransactionsRequest.run(state.body);
  }, [state]);

  if (tokenTransactionsRequest.loading) {
    return <Loading />;
  }

  let data = tokenTransactionsRequest.data?.token_transactions_list || [];
  let counts = tokenTransactionsRequest.data?.counts || 0;
  let token_transactions_counts = tokenTransactionsRequest.data?.token_transactions_counts || 0;
  let totalPage = Math.ceil(Number(token_transactions_counts) / state.body.length);

  return (
    <div className="tab-pane fade show active">
      <div style={{display: 'none'}}>
        <div className="py-3 text-center">
          <img src="/images/main/loadingblock.svg" alt="Loading" />
        </div>
      </div>
      <div className="table-responsive" style={{visibility: 'visible'}}>
        <div>
          <div className="d-md-flex justify-content-between mb-4">
            <p className="mb-2 mb-md-0">
              <i className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>
              <i className="fa fa-sort-amount-desc mr-1" rel="tooltip" data-toggle="tooltip" title="" data-original-title="Oldest First"></i>
              <span>{t('token.Transactions.tip1')}{counts} {t('token.Transactions.tip2')}</span>
              <span className="d-block small text-secondary">({t('token.Transactions.tip3')}{token_transactions_counts} {t('token.Transactions.tip4')})</span>
            </p>
            <Pager2 current={state.body.start} total={totalPage} pageChange= {handleCurrentPage}  />
          </div>
          <div className="table-responsive mb-2 mb-md-0">
            <table className="table table-md-text-normal table-hover mb-4">
              <thead className="thead-light">
                <tr>
                  <th scope="col">{t('token.Transactions.table.txnHash')}</th>
                  <th scope="col"> 
                    {
                    (type ==='showAge')?
                    <LinkWithTooltip placement="bottom" tooltip={t('token.age.tip1')}>
                      <a href="#!" title="" onClick={e => {  e.preventDefault(); setType('showDate'); }} >
                      {t('token.Transactions.table.age')}
                      </a>
                    </LinkWithTooltip>
                    :
                    <LinkWithTooltip placement="bottom" tooltip={t('token.age.tip2')}> 
                      <a href="#!" onClick={e => {  e.preventDefault(); setType('showAge'); }}  >
                      {t('token.Transactions.table.time')} (UTC)
                      </a>
                    </LinkWithTooltip>
                    }

                  </th>
                  <th scope="col">{t('token.Transactions.table.From')}</th>
                  <th scope="col" width="30"></th>
                  <th scope="col">{t('token.Transactions.table.To')}</th>
                  <th scope="col">{(overview.token_types ===1) ?t('token.Transactions.table.Quantity'):t('token.Transactions.table.TokenID')}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, i) => {
                  const now = new Date().getTime();
                  const time = moment(now - Number(item.trade_time * 1000))
                    .startOf('minute')
                    .fromNow();
                  return (
                    <tr key={i}>
                      <td>
                        <span className="hash-tag text-truncate">
                          <a href={`/tx/${item.trade_hash}`} target="_parent">
                            {item.trade_hash}
                          </a>
                        </span>
                      </td>
                      <TimeTD time={item.trade_time} interval={item.trade_time_interval}   type={type}/>
                      <td>
                          <LinkWithTooltip placement="bottom" tooltip={item.trade_from}>
                            <span className="hash-tag text-truncate" data-toggle="tooltip" title="" data-original-title={item.trade_from}>
                              {queryA === item.trade_from ? item.trade_from:
                                <a href={`${token}?a=${item.trade_from}`} target="_parent" >{item.trade_from}</a>
                              }
                            </span>
                          </LinkWithTooltip>
                       {/*  <a className="hash-tag text-truncate" href={`${token}?a=${item.trade_from}`} target="_parent" data-toggle="tooltip" title="" data-original-title={item.trade_from}>
                          {item.trade_from}
                        </a> */}
                      </td>
                      <td>
                          <span className="btn btn-xs btn-icon btn-soft-success rounded-circle">
                              <i className="fas fa-long-arrow-alt-right btn-icon__inner"></i>
                          </span>

                       {/*  {item.trade_types === 1 ? (
                          <span className="btn btn-xs btn-icon btn-soft-success rounded-circle">
                            <i className="fas fa-long-arrow-alt-right btn-icon__inner"></i>
                          </span>
                        ) : (
                          <span className="btn btn-xs btn-icon btn-soft-warning rounded-circle">
                            <i className="fas fa-long-arrow-alt-left btn-icon__inner"></i>
                          </span>
                        )} */}
                      </td>
                      <td>
                          <LinkWithTooltip placement="bottom" tooltip={item.trade_to}>
                            <span className="hash-tag text-truncate" data-toggle="tooltip" title="" data-original-title={item.trade_to}>
                                 {
                                 queryA === item.trade_to  ? item.trade_to:
                                <a href={`${token}?a=${item.trade_to}`} target="_parent" >{item.trade_to}</a>  
                                }
                            </span>
                          </LinkWithTooltip>
                        {/* <a className="hash-tag text-truncate" href={`${token}?a=${item.trade_to}`} target="_parent" data-toggle="tooltip" title="" data-original-title={item.trade_to}>
                          {item.trade_to}
                        </a> */}
                      </td>
                      <td>

                        <a className="hash-tag text-truncate" style={{maxWidth: '135px'}} href={`/token/${token}?a=${item.token_id}`} target="_parent">
                         <LinkWithTooltip placement="top" tooltip={ (overview.token_types ===1)?item.quantity: HexToDec(item.token_id)}> 
                          <span >
                            {(overview.token_types ===1)?item.quantity: HexToDec(item.token_id)}
                          </span>
                          </LinkWithTooltip>
                        </a>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-md-end align-items-center text-secondary mb-2 mb-md-0 my-3">
              <div className="d-inline-block">
                <Pager2 current={state.body.start} total={totalPage} pageChange= {handleCurrentPage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    {/*   <div className="row mb-4">
        <div className="col text-right">
          <span className="float-right" data-toggle="tooltip" title="" data-original-title="Export records in CSV format">
            <font size="1">
              [ Download{' '}
              <a href={`/exportData?type=tokentxns&contract=${data.token_address}&a=&decimal=18`} target="_blank">
                <b> CSV Export</b>
              </a>
              &nbsp;
              <span className="fas fa-download text-secondary"></span> ]
            </font>
            &nbsp;
          </span>
        </div>
      </div> */}
    </div>
  );
}
