import { useRequest } from 'ahooks';
import { useEffect, useRef, useState, useMemo } from 'react';
import moment from 'moment'; 
import 'moment/locale/zh-cn'; 
import useWebSocket, { ReadyState } from 'react-use-websocket'; 
import SearchBox from '../../components/SearchBox';
import LinkWithTooltip from '../../components/LinkWithTooltip';
import {HexToDec} from '../../commons/utils';
import {useTranslation} from 'react-i18next';

function RenderHighChart (container, data = [],t) { 
 
  window.Highcharts.chart(container, {
    chart: {
      spacingTop: 10,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 10,
    },

    title: {
      text: '',
      align: 'left',
    },

    xAxis: {
      title: { text: '' },
      lineWidth: 0,
      minorTickLength: 0,
      tickLength: 0,
      labels: {
        step: 7,
      },
      categories: data.map(v => {
        const ret = moment(v.dt * 1000).format('L');
        return ret;
      }),
    },

    yAxis: {
      labels: {
        enabled: true,
      },
      gridLineWidth: 0,
      title: {
        text: null,
      },
    },

    legend: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    tooltip: {
      formatter: function () {
        const ret = moment(this.point.dt * 1000).format('L');
        
        return (
          '<span style="font-size:10px">' +
          ret +
          '</span><br><table><tr><td style="padding:0">' +
          '<span style="color:' +
          this.series.color +
          '">'+t('home.dashboard.transactions')+': </a></span><b>' +
          this.point.y +
          '</b><br>' +
          '</td></tr></table>'
        );
      },
    },

    plotOptions: {
      series: {
        color: '#1e2022',
        enableMouseTracking: true,
        lineWidth: 1,
        shadow: false,
        animation: false,
        cursor: 'pointer',
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        marker: {
          radius: 0,
        },
        point: {
          events: {
            select: function (e) {
              window.location.href = '/txs?dt=' + this.options.dt;
            },
          },
        },
      },
      column: {
        pointPadding: 0.1,
        borderWidth: 0,
      },
    },

    series: [
      {
        name: t('home.dashboard.transactions'),
        type: 'spline',
        data: data,
        allowPointSelect: true,
        pointStart: 0,
      },
    ],
  });
}

function TopHead ({ data = [] }) {
 
  const {t} = useTranslation(['home']);
  let flag =false
  let blockno = '1112222'
  let block_random = ''
  if (Array.isArray(data) && data.length) {
    flag = true 
    blockno = data[0].block_no
    let tempNumber = Number.parseInt(data[0].block_hardware_random,16)
    tempNumber = tempNumber.toString()
    let tempIndex  = tempNumber.toString().indexOf('e+')
    block_random = tempNumber.toString().substr(0,tempIndex)
    block_random = block_random.replace('.','')
    // console.log(block_random,2222)
  }
  return (
    <section className="bg-img-hero-main bg-dark" style={{ backgroundImage: 'url(/images/svg/components/bg-shape.svg?v=2)' }}>
      <div className="container space-top-2 space-bottom-3">
        <div className="row justify-content-between align-items-center mb-4">
          <div className="col-md-12 col-lg-7">
            <div className="pr-lg-4 pr-xll-5">
              <h1 className="h4 text-white mb-3">{t('home.banner.title')}</h1>
              <SearchBox />
              <p className="text-white-70 mb-0" style={{ minHeight: '22px' }}></p>
            </div>
          </div>
          <div className="col-md-12 col-lg-5">
            <div className="d-lg-block text-center pl-lg-4 pl-xll-5" style={{display:'flex'}}>
              {flag ? 
                <div style={{backgroundColor:'#fff',borderRadius:'0.8rem',flex:'1 1 auto'}}>
                    <div className="row mx-gutters-md-1 align-items-center">
                      <div className="col-2 col-lg-2 ml-3">
                          <img src="/images/svg/random.svg" className="random-image" alt="" />
                      </div> 
                      <div className="col-9 col-lg-9 font-size-3" >  
                          <h3 style={{marginTop:'10px'}}><span style={{color:'#233370',fontWeight:'600'}}>{t('home.random.latestblock')}:{blockno}</span></h3>
                          <h5 className="hrng-fontsize" style={{color:'#000000',fontWeight:'600'}}>
                            <span style={{fontWeight:'bold'}}>HRNG </span>
                            ({t('home.random.provable')})
                            </h5>
                          <h3 style={{fontWeight:'bold',color:'#233370'}}>{block_random}</h3> 
                      </div>
                    </div>

                </div>
               :''
               }
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Dashboard ({ data = {} }) {

  const {t} = useTranslation(['home']);

  const containerRef = useRef();
  const tradeHistoryChartRequest = useRequest(
    {
      url: '/blockBrowser/index/txnHistoryChart',
      method: 'post',
    },
    { manual: true },
  );

  const percent = ((data.current_price - data.open_price) / data.open_price) * 100;

  useEffect(() => {
    tradeHistoryChartRequest.run().then((res = []) => {
      // console.log(res);
      const r = res || [];
      RenderHighChart(containerRef.current, r.reverse(),t);
    });
  }, [containerRef]);

  return (
    <div className="card mb-4">
      <div className="card-body p-4">
        <div className="row mx-gutters-md-1 align-items-center">
          <div className="col-md-6 col-lg-4">
            <div className="media align-items-center">
              <figure className="mr-2">
                <img className="u-sm-avatar" src="/images/svg/brands/hpb-icon.svg" alt="HPB" />
              </figure>
              <div className="media-body">
                <h2 className="font-size-1 text-uppercase text-secondary mb-0">{t('home.dashboard.hpbprice')}</h2>
                <LinkWithTooltip placement="bottom" tooltip={t('home.dashboard.viewhistoryprice')}>
                  <a className="text-size-1 text-link" href="/chart/htprice" >
                    ${data.current_price}
                    <span className="text-secondary"> @ {data.ht_btc} {t('home.dashboard.btc')}</span>
                    <span className="text-danger small"> ({percent.toFixed(2)}%)</span>
                  </a> 
                </LinkWithTooltip>
              </div>
            </div>
            <hr className="hr-space-lg" />
            <div className="media align-items-center">
              <figure className="u-sm-avatar mr-2">
                <img src="/images/svg/icons/icon-8.svg" alt="Market Cap" />
              </figure>
              <div className="media-body">
                <h2 className="font-size-1 text-uppercase text-secondary mb-0">{t('home.dashboard.hpbmarketcap')}</h2>
                
                <LinkWithTooltip placement="bottom" tooltip={t('home.dashboard.hpbsupplytip')}>
                  <a className="text-size-1 text-link" href="/#"  >
                    ${data.market_value} 
                    <span className="text-secondary">({data.csupply_content} / {data.price_content})</span> 
                  </a>
                </LinkWithTooltip> 
              </div>
            </div>
            <hr className="d-block d-md-none hr-space-lg" />
          </div>
          <div className="col-md-6 col-lg-4 u-ver-divider u-ver-divider--left u-ver-divider--none-md">
            <div className="media align-items-center">
              <figure className="u-sm-avatar mr-2">
                <img src="/images/svg/icons/icon-2-1.svg?v=1.3" alt="Network Difficulty" />
              </figure>
              <div className="media-body">
                <h2 className="font-size-1 text-uppercase text-secondary mb-0">{t('home.dashboard.lastestBlock')}</h2>
                <LinkWithTooltip placement="bottom" tooltip={t('home.dashboard.lastestBlockIips')}>
                  <a className="text-size-1 text-link" href="blocks" >
                    <span>{data.latest_block}</span>
                  </a>
                </LinkWithTooltip>
                &nbsp;
                <LinkWithTooltip placement="right" tooltip={t('home.dashboard.lastestBlockIips2')}>
                  <a href="/chart/blocktime"  className="small text-secondary  text-link" > 
                    <span>6.0s</span>
                  </a>
                </LinkWithTooltip>
              </div>
              <div className="text-right">
                <h2 className="font-size-1 text-uppercase text-secondary mb-0">{t('home.dashboard.transactions')}</h2>
 
                <LinkWithTooltip placement="left" tooltip={t('home.dashboard.transactionsTips')}>
                  <a  href="/txs"  className="text-size-1 text-link"  >
                    {data.trade_total/1000} K
                  </a> 
                </LinkWithTooltip>

                <LinkWithTooltip placement="left" tooltip={t('home.dashboard.transactionsTips2')}>
                  <span className="text-secondary small" >
                    &nbsp; ({data.trade_total_tps} TPS)
                  </span>
                </LinkWithTooltip>
               
              </div>
            </div>
            <hr className="hr-space-lg" />
            <div className="media align-items-center">
              <figure className="u-sm-avatar mr-2">
                <img src="/images/svg/icons/icon-51.svg?v=1.5" alt="SVG" />
              </figure>
              <div className="media-body">
                <h2 className="font-size-1 text-uppercase text-secondary mb-0">{t('home.dashboard.ActiveValidator')}</h2>
               
                <a className="text-size-1 text-link" data-toggle="tooltip" data-placement="left" data-title="View Difficulty Growth Chart" href="/chart/difficulty" data-original-title="" title=""></a>
                <LinkWithTooltip placement="bottom" tooltip={t('home.dashboard.TotalActiveValidator')}>
                  <a className="text-size-1 text-link" href=" https://vote.hpbnode.com" target="_blank" >
                    {data.active_verifier}
                  </a>
                </LinkWithTooltip>

              </div>
              <div className="text-right">
                <h2 className="font-size-1 text-uppercase text-secondary mb-0">{t('home.dashboard.TOTALSTAKED')}</h2>
                {data.staked} HPB
              </div>
            </div>
            <hr className="d-block d-md-none hr-space-lg" />
          </div>
          <div className="col-md-12 col-lg-4 u-ver-divider u-ver-divider--left u-ver-divider--none-lg">
            <hr className="d-none d-md-block d-lg-none my-4" />
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="font-size-1 text-uppercase text-secondary mb-0">{t('home.dashboard.view14dayhistoryprice')}</h2>
            {/*   <div className="position-relative float-right z-index-2">
                <a
                  className="btn btn-xs btn-icon btn-soft-secondary"
                  href="#"
                  role="button"
                  aria-controls="customcharts"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-unfold-event="click"
                  data-unfold-target="#customcharts"
                  data-unfold-type="css-animation"
                  data-unfold-duration="300"
                  data-unfold-delay="300"
                  data-unfold-hide-on-scroll="true"
                  data-unfold-animation-in="slideInUp"
                  data-unfold-animation-out="fadeOut"
                >
                  <i className="fa fa-ellipsis-v btn-icon__inner"></i>
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right dropdown-unfold u-unfold--css-animation u-unfold--hidden fadeOut"
                  aria-labelledby="customchartsinvoker"
                  style={{ animationDirection: '300ms' }}
                >
                  <a className="dropdown-item" href="/chart/tx">
                    View Detailed Chart
                  </a>
                </div>
              </div> */}
            </div>
            <div>
              <div ref={containerRef} className="" style={{ height: '106px' }} data-highcharts-chart="0"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function LatestBlock ({ data = [] }) {
  const {t} = useTranslation(['home']);

  return (
    <div className="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside" style={{ maxHeight: 'none', tabindex: '0' }}>
      <div className="mCSB_container" style={{ position: 'relative', top: 0, left: 0 }} dir="ltr">
        {data.map((item, index) => {
          const now = new Date().getTime(); 
          const time = moment(now - Number(item.block_time_interval) * 1000)
            .startOf('minute')
            .fromNow(); 
          return (
            <div key={item.block_no}>
              {index !== 0 ? <hr className="hr-space" /> : undefined}
              <div key={item.block_no} className="row">
                <div className="col-sm-4">
                  <div className="media align-items-sm-center mr-4 mb-1 mb-sm-0">
                    <div className="d-none d-sm-flex mr-2">
                      <span className="btn btn-icon btn-soft-secondary">
                        <span className="btn-icon__inner text-dark">Bk</span>
                      </span>
                    </div>
                    <div className="media-body">
                      <span className="d-inline-block d-sm-none">Block</span> <a href={`/block/${item.block_no}`}>{item.block_no}</a>
                      <span className="d-sm-block small text-secondary ml-1 ml-sm-0 text-nowrap" data-countdown="5000">
                        {time}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="d-flex justify-content-between">
                    <div className="text-nowrap">
                      <span className="d-block mb-1 mb-sm-0">
                        {t('home.dashboard.ValidatedBy')}&nbsp;
                        <a className="hash-tag text-truncate" style={{"maxWidth":"260px"}} href={`/address/${item.block_miner}`}>
                          {item.block_miner}
                        </a>
                      </span>
                      <a href={`/txs?block=${item.block_no}`} title="Transactions in this Block">
                        {item.block_trade_amount} txns
                      </a>
                      &nbsp;
                      <span className="small text-secondary">in {item.block_interval_time} secs</span>
                      <span className="d-inline-block d-sm-none">
                        <span className="u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap" title="Block Reward">
                          {item.block_reward} HPB
                        </span>
                      </span>
                    </div>
                    {/* <div className="d-none d-sm-block">
                      <span className="u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap" title="Block Reward">
                        {item.block_reward} HPB
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function LatestTrade ({ data = [] }) {
  const {t} = useTranslation(['home']);
  return (
    <div className="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside" tabIndex="0" style={{ maxHeight: 'none' }}>
      <div className="mCSB_container" style={{ position: 'relative', top: 0, left: 0 }} dir="ltr">
        {data.map((item, index) => {
          const now = new Date().getTime();
          const time = moment(now - Number(item.trade_time_interval) * 1000)
            .startOf('minute')
            .fromNow();
          return (
            <div key={item.trade_hash}>
              <div className="row">
                <div className="col-sm-4">
                  <div className="media align-items-sm-center mr-4 mb-1 mb-sm-0">
                    <div className="d-none d-sm-flex mr-2">
                      <span className="btn btn-icon btn-soft-secondary rounded-circle">
                        <span className="btn-icon__inner text-dark">Tx</span>
                      </span>
                      &nbsp;
                    </div>
                    <div className="media-body">
                      <span className="d-inline-block d-sm-none mr-1">Tx#</span>
                      <a className="hash-tag hash-tag--xs hash-tag-xs-down--md text-truncate" href={`/tx/${item.trade_hash}`}>
                        {item.trade_hash}
                      </a>
                      <span className="d-none d-sm-block small text-secondary">
                        <div data-countdown="5000">{time}</div>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="d-sm-flex justify-content-between">
                    <div className="text-nowrap mr-4 mb-1 mb-sm-0">
                      <span>
                        {t('home.lasteTransactions.from')}&nbsp;
                        <a className="hash-tag text-truncate" href={`/address/${item.trade_from}`}>
                          {item.trade_from}
                        </a>
                      </span>
                      <span className="d-sm-block">
                      {t('home.lasteTransactions.to')}&nbsp;
                        <a href={`/address/${item.trade_to}`} className="hash-tag hash-tag--sm text-truncate">
                          {item.trade_to}
                        </a>
                      </span>
                    </div>
                    <div>
                      &nbsp;
                      <span className="u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap" title="Amount">
                        {item.trade_amount} HPB
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="hr-space" />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default function Welcome () {

  const {t} = useTranslation(['home']);
  // 设置中文
  const defaultLNG = window.localStorage.getItem('lng') || 'en_US';
  moment.locale(defaultLNG);

  const [dashboard, setDashboard] = useState();
  const [latestBlock, setLatestBlock] = useState();
  const [latestTrade, setLatestTrade] = useState();
  const messageHistory = useRef([]);

  const { lastMessage, readyState } = useWebSocket('wss://hscan.org/blockBrowser/index/ws');

  messageHistory.current = useMemo(() => lastMessage);
  const dashboardRequest = useRequest(
    {
      url: '/blockBrowser/index/dashboard',
      method: 'post',
    },
    { manual: true },
  );
  const latestBlockRequest = useRequest(
    {
      url: '/blockBrowser/index/latestBlock',
      method: 'post',
    },
    { manual: true },
  );
  const latestTradeRequest = useRequest(
    {
      url: '/blockBrowser/index/latestTrade',
      method: 'post',
    },
    { manual: true },
  );

  useEffect(() => {
    async function fetch () {
      dashboardRequest.run().then(res => {
        setDashboard(res);
      });
      latestBlockRequest.run().then(res => {
        setLatestBlock(res);
      });
      latestTradeRequest.run().then(res => {
        setLatestTrade(res);
      });
    }

    fetch();
  }, []);

  useEffect(() => {
    try {
      const data = JSON.parse(lastMessage.data);
      if (Array.isArray(data.block) && data.block.length) {
        const latest = data.block[0];

        if (Array.isArray(latestBlock) && latestBlock.every(block => block.block_no !== latest.block_no)) {
          let blocks = [...latestBlock];
          blocks.unshift(latest);
          blocks.pop();
          setLatestBlock(blocks);
        }
        // block = data.block;
      }
      if (data.trade) {
        setLatestTrade(data.trade);
      }
    } catch (error) { }
  }, [lastMessage]);

  let block = latestBlock; 
  let trade = latestTrade;

  return (
    <main>
      <TopHead data={block || []} />

      <div className="container space-bottom-1 mt-n5">
        <Dashboard data={dashboard} />
        <div className="row mb-5">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="card h-100">
              <div className="card-header">
                <h2 className="card-header-title">{t('home.lasteblock.title')}</h2>
              </div>
              <div className="js-scrollbar card-body  mCustomScrollbar _mCS_1 mCS-autoHide" style={{ height: '400px', position: 'relative', overflowY: 'scroll' }}>
                <LatestBlock data={block || []} />
                <div className="mCSB_scrollTools mCSB_1_scrollbar mCS-minimal-dark mCSB_scrollTools_vertical" style={{ display: 'block' }}>
                  <div className="mCSB_draggerContainer">
                    <div className="mCSB_dragger" style={{ position: 'absolute', minHeight: '50px', display: 'block', height: '224px', maxHeight: '366px', top: '0px' }}>
                      <div className="mCSB_dragger_bar" style={{ lineHeight: '50px' }}></div>
                    </div>
                    <div className="mCSB_draggerRail"></div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <a className="btn btn-xs btn-block btn-soft-primary" href="/blocks">
                 {t('home.lasteblock.viewBlocks')}
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card h-100">
              <div className="card-header">
                <h2 className="card-header-title">{t('')}</h2>
              </div>
              <div className="js-scrollbar card-body  mCustomScrollbar _mCS_2 mCS-autoHide" style={{ height: '400px', position: 'relative', overflow: 'scroll' }}>
                <LatestTrade data={trade} />
                <div className="mCSB_scrollTools mCSB_2_scrollbar mCS-minimal-dark mCSB_scrollTools_vertical" style={{ display: 'block' }}>
                  <div className="mCSB_draggerContainer">
                    <div className="mCSB_dragger" style={{ position: 'absolute', minHeight: '50px', display: 'block', height: '224px', maxHeight: '366px', top: 0 }}>
                      <div className="mCSB_dragger_bar" style={{ lineHeight: '50px' }}></div>
                    </div>
                    <div className="mCSB_draggerRail"></div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <a className="btn btn-xs btn-block btn-soft-primary" href="/txs">
                  {t('home.lasteblock.viewTransactions')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
