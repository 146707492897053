import {useRequest} from 'ahooks';
import {useLocation, useParams} from 'react-router-dom';
import {useEffect, useState, useRef} from 'react';
import {Metamask} from 'ethpay.core';

import Loading from '../../components/Loading';

import CardTabsHeader from './CardTabsHeader';
import Header from './Header';
import Holders from './Holders';
import Overview from './Overview';
import TokenHolder from './TokenHolder';
import ProfileSummary from './ProfileSummary';
import Transactions from './Transactions';
import TokenInfo from './TokenInfo';
import TokenExchange from './TokenExchange';
import Inventory from './Inventory';
import ReadContract from './ReadContract';
import WriteContract from './WriteContract';
import TokenAnalytics from './TokenAnalytics';
import qs from 'qs';
import {useTranslation} from 'react-i18next';

let web3 = undefined;

export default function Token() {

  const {t} = useTranslation(['token']);
 
  const {token} = useParams();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(location.hash.slice(1) || 'transactions'); // transactions, tokentxns, contracts, events, uncle, analytics
  const [currentContract, setCurrentContract] = useState();
  const [currentAccount, setCurrentAccount] = useState();
 
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});   
  const tokenDetailRequest = useRequest({
    url: '/blockBrowser/tokens/address/tokenDetail',
    method: 'post',
    body: JSON.stringify({
      field: 'address',
      value: token,
    }),
  });
  const contractQueryByAddressRequest = useRequest({
    url: '/blockBrowser/contract/contractQueryByAddress',
    method: 'post',
    body: JSON.stringify({
      contract_address: token,
    }),
  });

  const tabsConst = [
    {
      key: 'transactions',
      title: t('token.tab.Transfers'),
    },
    {
      key: 'balances',
      title: t('token.tab.Holders'),
    },
    {
      key: 'inventory',
      title:  t('token.tab.Inventory') ,
    },
    // {
    //   key: 'tokenInfo',
    //   title: 'Info',
    // },
    // {
    //   key: 'tokenExchange',
    //   title: 'Exchange',
    // },
    //   {
    //     key: 'tokenTrade',
    //     title: '',
    //   },
    {
      key: 'readContract',
      title:  t('token.tab.readContract') ,
    },
    {
      key: 'writeContract',
      title: t('token.tab.writeContract'),
    },
    {
      key: 'tokenAnalytics',
      title: t('token.tab.tokenAnalytics'),
    },
  ];

  if (tokenDetailRequest.loading || contractQueryByAddressRequest.loading) {
    return <Loading />;
  }
  // console.log(tokenDetailRequest.data)
  const overview = tokenDetailRequest.data?.overview || {};
  const profile_summary = tokenDetailRequest.data?.profile_summary || {};

  const detail = contractQueryByAddressRequest.data?.results || {};

  const handleConnect = async e => {
    e.preventDefault(); 
    if(detail.Abi===''){
      prompt.error('Please verify the contract first.') 
      return;
    }
    if (!web3) {
      const provider = await Metamask.getProvider();

      web3 = new window.Web3(provider);
      const pool = new web3.eth.Contract(JSON.parse(detail.Abi), token);

      Metamask.requestAccounts().then(result => {
        setCurrentAccount(Array.isArray(result) ? result[0] : undefined);
      });

      setCurrentContract(pool);
    }
  };

  return (
    <main role="main">
      <Header data={overview} />
      <div className="container space-bottom-2">
        <div className="row mb-4">
          <div className="col-md-6 mb-3 mb-md-0">
            <Overview data={overview} />
          </div>
          <div className="col-md-6">
            <ProfileSummary data={profile_summary} />
          </div>
        </div>
        {query.a?
          <div className="row mb-4">
             <div className="col-md-12">
            <TokenHolder token_name = {overview.token_name} token_address={token} account_address={query.a}  /> 
            </div>
          </div>
        :'' 
        }
        <div className="card">
          <CardTabsHeader
            data={profile_summary}
            tabs={tabsConst}
            current={currentTab}
            onChange={e => {
              setCurrentTab(e);
            }}
            queryA = {query.a}
          />
          <div className="card-body">
            <div className="tab-content">
              {currentTab === 'transactions' ? <Transactions token={token} overview={overview} queryA={query.a} /> : undefined}
              {currentTab === 'balances' ? <Holders token={token} overview={overview} profile_summary={profile_summary} /> : undefined}
              {currentTab === 'tokenInfo' ? <TokenInfo token={token} overview={overview} profile_summary={profile_summary} /> : undefined}
              {currentTab === 'tokenExchange' ? <TokenExchange token={token} overview={overview} profile_summary={profile_summary} /> : undefined}
              {currentTab === 'inventory' ? <Inventory token={token} overview={overview} profile_summary={profile_summary} /> : undefined}
              {currentTab === 'readContract' ? (
                <ReadContract account={currentAccount} contract={currentContract} onConnect={handleConnect} token={token} overview={overview} profile_summary={profile_summary} />
              ) : undefined}
              {currentTab === 'writeContract' ? (
                <WriteContract account={currentAccount} contract={currentContract} onConnect={handleConnect} token={token} overview={overview} profile_summary={profile_summary} />
              ) : undefined}
              {currentTab === 'tokenAnalytics' ? <TokenAnalytics token={token} overview={overview} profile_summary={profile_summary} /> : undefined}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
