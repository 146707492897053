// import Web3 from 'Web3'
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

export default function PushTx() {  
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({
        content: ''     
    });
    const {t} = useTranslation(['pushTx']);

    const provider = new window.Web3.providers.HttpProvider("https://hpbnode.com/");
    // const provider = new window.Web3.providers.HttpProvider("https://http-testnet.hecochain.com/");
    const web3 = new window.Web3(provider); 
 

    const[showError,setShowError]=useState(false)
    const[errorMsg,setErrorMsg]=useState('')
    const[showSuc,setShowSuc]=useState(false)
    const[sucData,setSucData]=useState('') 

   /*  const handleSign=()=>{
        web3.eth.accounts.signTransaction({
            nonce: 16,  // Use the right nonce here, just hardcoding at 1 for the example.
            from: '0x1D6a1f60282dA161F6705D87c3b85e150C0132ea',
            to: '0x0335dc2E445D864F8076f5C16C3D545666997Cc6',
            value: '1',
            gas: 2000000
        }, '')
        .then(tx => {
            var rawTx = tx.rawTransaction;
            console.log(rawTx);
            setForm({content:rawTx})
        });
    } */
    const handleSubmit = () => { 
        const _errors = {}; 
        Object.keys(form)
        .forEach(field => {
            _errors[field] = !form[field];
        });

        if (Object.keys(_errors).some(e => !!_errors[e])) {
            setErrors(_errors);
          } else { 
            prompt.inform("Transaction Submitted, Please wait......",true)
            web3.eth.sendSignedTransaction(form.content)
               .on('receipt', function(data){ 
                   prompt.clean();
                   prompt.inform('success' )
                   const msg = `{"jsonrpc":"2.0","id":1,"result":"${data.transactionHash}"}`
                   setSucData({msg,tx:data.transactionHash})
                   setShowSuc(true);
                   setShowError(false)
                   console.log(data,'----------------->',data);
               })
               .on("error", function(err){  
                   prompt.clean();
                   prompt.error('Error! ')
                   const msg = `{"jsonrpc":"2.0","id":1,"error":{"code":-32000,"message":"${err.message}"}}` 
                   setShowSuc(false);
                   setShowError(true);
                   setErrorMsg(msg);
                //    console.log(err,'-------------------',err);
               }); 
          }
         
    };
    const handleContent  = field => e =>  {
        setForm({...form, [field]: e.target.value});
        if (e.target.value) {
            setErrors({...errors, [field]: undefined});
        } 
    }; 
    const handleBlur = key => () => {
        const _errors = {};
        Object.keys(form)
            .forEach(field => {
                _errors[field] = !form[field];
            });
        if (Object.keys(_errors).some(e => !!_errors[e])) {
            if (_errors[key]) {
                setErrors(_errors);
            }
        }
    };

    const handleHidden = field => () => {
        if (field === 'error') {
            setShowError(false)
        } else {
            setShowSuc(false)
        }
    }

  return (
    <main role="main"> 
         <div className="container">
            <div className="border-bottom p y-3 mb-3">
                <div className="mb-2 mb-sm-0">
                    <h1 className="h4 mb-0">{t('pushTx.title')}</h1>
                </div>
            </div>
            <p className="mb-0">{t('pushTx.tip1')}</p>
        </div>

        <div className="container space-bottom-2">     
            <div className="py-4">
                <div className="alert alert-success" style={{display: showSuc ? 'block' : 'none'}}>
                     <button type="button" className="close"  onClick={handleHidden('success')}  ><span aria-hidden="true">×</span></button>
                    <strong>{t('pushTx.tip2')}</strong>
                    {sucData.msg}
                   <span className="d-block">
                        <i className="fa fa-caret-right"></i> 
                        {t('pushTx.tip3')}
                        <a href={`/tx/${sucData.tx}` } target="_blank" rel="noreferrer">
                        <span className="text-primary"></span>
                        </a>
                        <a href={`/tx/${sucData.tx}`} target="_blank" rel="noreferrer" >
                            {sucData.tx}
                        </a>
                   </span>
                </div> 
                <div className="alert alert-danger" style={{display: showError ? 'block' : 'none'}}>
                    <button type="button" className="close"  onClick={handleHidden('error')}  ><span aria-hidden="true">×</span></button>
                    <strong>{t('pushTx.tip4')}</strong>
                    {t('pushTx.tip5')}
                    {errorMsg}
                </div>

                <form method="post" action="./pushTx" id="ctl00" className="js-validate" noValidate="novalidate">  
                    <div className="js-form-message mb-1">
                        <textarea name="txtRawTx" rows="8" cols="20" id="txtRawTx" 
                        className="form-control w-100" required="" 
                        data-error-class="u-has-error" 
                        data-success-class="u-has-success"
                         placeholder={`${t('pushTx.tip6')}(0x..)`}
                         data-msg="Please enter signed transaction hex" 
                         value={form.content}
                         onChange={handleContent('content')} 
                         onBlur={handleBlur('content')}
                         ></textarea>
                    </div>
                    <div className="invalid-feedback" style={{display: errors.content ? 'block' : 'none'}}>
                        {t('pushTx.tip6')}
                    </div>
                    <p className="font-size-1"><strong>{t('pushTx.tip7')}</strong>{t('pushTx.tip8')}</p>
                    <div className="text-md-right">
                        {/* <input type="button"   value="Sign Transaction" className="btn btn-sm btn-primary" onClick={handleSign}  /> */}
                        <input type="button"   value={t('pushTx.tip9')} className="btn btn-sm btn-primary" onClick={handleSubmit}  />
                    </div>
                </form>
            </div> 
        </div>

     </main>
  ); 
  }
  