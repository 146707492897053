import {useContext} from 'react';
import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext';

export default function MyTokenIgnore() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};

  if (!user.token) {
    return (window.location.href = '/login');
  }

  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="mytokenignore" />
          <div className="col-md-9">
            <div className="card">
              <div className="card-header d-md-flex justify-content-between align-items-center">
                <div className="d-flex flex-wrap">
                  <div className="mr-2 mb-2 mb-md-0">
                    <h2 className="card-header-title mt-1">My Token Ignore List</h2>
                  </div>
                  <div className="mr-2 mb-2 mb-md-0">
                    <div className="position-relative order-md-2">
                      <a
                        href="#"
                        id="ContentPlaceHolder1_addnew"
                        data-toggle="modal"
                        data-target="#myModal"
                        title="Create a new Ignore Token entry"
                        className="btn btn-xss btn-primary rounded"
                        onclick="newaddress();"
                      >
                        <i className="fa fa-plus-square fa-lg mr-1" style={{marginLeft: '-3px', marginBottom: '3px'}}></i>Add
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="alert alert-info" id="success-alert" style={{display: 'none'}}>
                  <button type="button" className="close" data-dismiss="alert">
                    x
                  </button>
                  <span id="actionResult"></span>
                </div>
                <div className="d-md-flex justify-content-between align-items-center mb-4">
                  <p className="mb-2 mb-lg-0">&nbsp;0 used (Out of 20 max quota)</p>
                  <nav className="mb-4 mb-md-0" aria-label="page navigation">
                    <div id="ContentPlaceHolder1_PagingPanel">
                      <ul className="pagination pagination-sm mb-0">
                        <li className="page-item disabled">
                          <span className="page-link">First</span>
                        </li>
                        <li className="page-item disabled">
                          <span className="page-link">
                            <i className="fa fa-chevron-left small"></i>
                          </span>
                          <span className="sr-only">Previous</span>
                        </li>
                        <li className="page-item disabled">
                          <span className="page-link text-nowrap">
                            Page <strong className="font-weight-medium">1</strong> of <strong className="font-weight-medium">1</strong>
                          </span>
                        </li>
                        <li className="page-item disabled">
                          <span className="page-link">
                            <i className="fa fa-chevron-right small"></i>
                          </span>
                          <span className="sr-only">Previous</span>
                        </li>
                        <li className="page-item disabled">
                          <span className="page-link">Last</span>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="table-responsive mb-2 mb-md-0">
                  <div className="mx-md-auto">
                    <figure id="SVGdataReport1" className="mb-7 ie-data-report">
                      <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Action</th>
                            <th scope="col">Token Contract Address</th>
                            <th scope="col" data-toggle="tooltip" title="" data-original-title="UTC TimeZone (yyyy-mm-dd)">
                              Created
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="align-top" colSpan="4">
                              <center>
                                <div className="alert alert-secondary mt-4" role="alert">
                                  <i className="fal fa-info-circle"></i> <span className="text-dark">You have yet to create a Token Ignore entry.</span>
                                </div>
                              </center>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
