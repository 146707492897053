const login = {
    
    'login.title': 'Welcome Login (Beta)',
    'login.tab.title1': 'UserName Login',
    'login.tab.title2': 'Random Login',
    'login.title2': 'Login to your account',
    'login.userlogin.Username': 'Username',
    'login.userlogin.ResendEmail': 'Resend Email',
    'login.userlogin.Usernamerequired': 'Username is required.',
    'login.userlogin.Password': 'Password',
    'login.userlogin.Passwordinvalid': 'Your password is invalid. Please try again.',
    'login.userlogin.Captcha': 'Captcha',
    'login.userlogin.Captchainvalid': 'Captcha is invalid.',
    'login.userlogin.tip1': 'Don not have an account?',
    'login.userlogin.tip2': 'Click to Register a Account.',
    'login.userlogin.LOGIN': 'LOGIN',

    'login.random.Random': 'Random',
    'login.random.Randominvalid': ' Your random is invalid. Please try again.',

     

};

export default login;
