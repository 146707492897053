import {useEffect,useState, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';
import {useLocation} from 'react-router-dom';

import {mediaquerys} from '../../constant';
import SearchBox from '../SearchBox';

import UserContext from '../../UserContext';
import { useRequest } from 'ahooks';
import './style.css';

function Logo() {
  return (
    <a className="navbar-brand pt-md-0" href="/" target="_parent" aria-label="your">
      <img id="logo-header" width="177" src="/images/hpbscan-logo.svg" alt="HPB Logo" />
      {/* <span className="logo-title">HPB</span> */}
    </a>
  );
}

function ToggleNavigation({expanded, onClick}) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="navbar-toggler btn u-hamburger"
      aria-label="Toggle navigation"
      aria-expanded={String(expanded)}
      aria-controls="navBar"
      data-toggle="collapse"
      data-target="#navBar"
    >
      <span id="hamburgerTrigger" className="u-hamburger__box">
        <span className="u-hamburger__inner"></span>
      </span>
    </button>
  );
}

function Menu({title, path, user, active, minWidth = '230px', className = '', aClassName = '', ulClassName = '', children}) {
  const isMinMD = useMediaQuery({query: mediaquerys.md});
  const [hover, setHover] = useState(false);

  const hasChildren = Array.isArray(children) && children.length;

  const handleClick = e => {
    if (!path) {
      e.preventDefault();
    }
  };

  const events = {};

  if (!isMinMD) {
    events.onClick = () => setHover(!hover);
  } else {
    events.onMouseEnter = () => setHover(true);
    events.onMouseLeave = () => setHover(false);
  }

  return (
    <li {...events} className={`nav-item ${hasChildren ? 'hs-has-sub-menu' : ''} u-header__nav-item ${hover ? 'hs-sub-menu-opened' : ''} ${active ? 'active' : ''} ${className}`}>
      <a
        onClick={handleClick}
        className={`nav-link u-header__nav-link ${hasChildren ? 'u-header__nav-link-toggle' : ''} ${aClassName}`}
        href={path || '#'}
        aria-haspopup="true"
        aria-expanded={String(hover)}
        aria-labelledby="pagesSubMenu"
      >
        {typeof title === 'function' ? title(user) : title}
      </a>
      {hasChildren ? (
        <ul
          id="blockchainSubMenu"
          className={`hs-sub-menu u-header__sub-menu u-header__sub-menu--spacer animated ${hover ? 'slideInUp' : 'fadeOut'} ${ulClassName}`}
          aria-labelledby="blockchainMegaMenu"
          style={{minWidth: minWidth, display: hover ? 'block' : 'none'}}
        >
          {children.map((c, i) => {
            if (c.type === 'divider') {
              return <li key={i} className={`dropdown-divider ${c.className}`}></li>;
            }  
            return (
              <li key={i} className={c.className || ''}>
                {c.component ? (
                  c.component()
                ) : (
                  <a href={c.path} className="nav-link u-header__sub-menu-nav-link">
                    {typeof c.title === 'function' ? c.title() : c.title}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      ) : undefined}
    </li>
  );
}

function Menu2({title, path, user, active, minWidth = '230px', className = '', aClassName = '', ulClassName = '', children}) {
  const {t} = useTranslation(['common']);
  const isMinMD = useMediaQuery({query: mediaquerys.md});
  const [hover, setHover] = useState(false);

  const hasChildren = Array.isArray(children) && children.length;

  const handleClick = e => {
    if (!path) {
      e.preventDefault();
    }
  };

  const events = {};

  if (!isMinMD) {
    events.onClick = () => setHover(!hover);
  } else {
    events.onMouseEnter = () => setHover(true);
    events.onMouseLeave = () => setHover(false);
  }

  return (
    <li {...events} className={`nav-item  u-header__nav-item ${hover ? 'hs-sub-menu-opened' : ''} ${active ? 'active' : ''} ${className}`}>
      <a  onClick={handleClick}  className={`nav-link u-header__nav-link ${hasChildren ? 'u-header__nav-link-toggle' : ''} ${aClassName}`}
        href='!#'  aria-haspopup="true"  aria-expanded={String(hover)}   aria-labelledby="pagesSubMenu" >
        {typeof title === 'function' ? title(user) : title}
      </a> 
      <div    className={`hs-sub-menu u-header__sub-menu  hs-position-left u-header__sub-menu--spacer animated ${hover ? 'slideInUp' : 'fadeOut'} ${ulClassName}`} aria-labelledby="moreMegaMenu" 
       style={{ width:'900px',left:'auto',right:0,display: hover ? 'block' : 'none'}}>
        <div className="row u-header__mega-menu-wrapper" style={{padding:'0.8rem'}}>
          <div className="col-sm-6 col-md-4 col-lg mb-4 mb-lg-0">
            <span className="u-header__sub-menu-title">{t('common.more.nodeStatus')}</span>
            <ul className="u-header__sub-menu-nav-group mb-3">
              <li id="li1"> 
                <a className="nav-link u-header__sub-menu-nav-link" href="/nodestatus">
                  <i className="fa fa-angle-right small mr-1"></i>{t('common.more.hpbNodeMonitor')}
                  <sup>
                    <span className="badge badge-danger font-size-default ml-1"> Beta</span>
                  </sup>
                </a>
              </li>
              <li id="li2">
                <a className="nav-link u-header__sub-menu-nav-link" href="/blocks_rewards">
                  <i className="fa fa-angle-right small mr-1"></i>{t('common.more.blockReward')}
                  <sup>
                    <span className="badge badge-danger font-size-default ml-1"> Beta</span>
                  </sup>
                </a>
              </li>
              <li id="li3">
                <a className="nav-link u-header__sub-menu-nav-link" href="/blockmonitor"> 
                  <i className="fa fa-angle-right small mr-1"></i>{t('common.more.blockMonitor')}
                
                </a> 
              </li> 
            </ul>
          </div>
          <div className="col-sm-6 col-md-4 col-lg mb-4 mb-lg-0">
            <span className="u-header__sub-menu-title">{t('common.dataexplore.dataexplore')}</span>
            <ul className="u-header__sub-menu-nav-group mb-3">
              <li id="li21">
                <a className="nav-link u-header__sub-menu-nav-link" href="/Leaderboard">
                  <i className="fa fa-angle-right small mr-1"></i>{t('common.dataexplore.Leaderboard')}</a>
              </li>
              <li id="li22">
                <a className="nav-link u-header__sub-menu-nav-link" href="/pending">
                  <i className="fa fa-angle-right small mr-1"></i>{t('common.dataexplore.PendingtxQuery')}</a>
              </li>
                  <li id="li23">
                <a className="nav-link u-header__sub-menu-nav-link" href="/gasprice">
                  <i className="fa fa-angle-right small mr-1"></i>{t('common.dataexplore.gasPrice')}</a>
              </li>
              <li id="li24">
                <a className="nav-link u-header__sub-menu-nav-link" href="/cftxs">
                  <i className="fa fa-angle-right small mr-1"></i>{t('common.dataexplore.Confidential')}
                  <sup>
                    <span className="badge badge-danger font-size-default ml-1"> Beta</span>
                  </sup>
                </a>
              </li>
              
            </ul>
          </div> 
          <div className="col-sm-6 col-md-4 col-lg mb-4 mb-lg-0">
            <span className="u-header__sub-menu-title">{t('common.developer.tools')}</span>
            <ul className="u-header__sub-menu-nav-group mb-3">
              <li id="li31">
                <a className="nav-link u-header__sub-menu-nav-link" href="/verifyContract">
                  <i className="fa fa-angle-right small mr-1"></i>{t('common.developer.verifyContract')}</a>
              </li>
              <li id="li32">
                <a className="nav-link u-header__sub-menu-nav-link" href="/contractsVerified">
                  <i className="fa fa-angle-right small mr-1"></i>{t('common.developer.contractsVerified')}</a>
              </li>
              <li id="li33">
                <a className="nav-link u-header__sub-menu-nav-link" href="/hpbapis">
                  <i className="fa fa-angle-right small mr-1"></i>APIs
                </a>
              </li>
              <li id="LI43">
                <a className="nav-link u-header__sub-menu-nav-link" href="https://hscan.org/faucet/"  target="_blank">
                  <i className="fa fa-angle-right small mr-1"></i>{t('common.developer.HPBFaucet')}
                  <sup>
                    <span className="badge badge-danger font-size-default ml-1"> Beta</span>
                  </sup>
                </a>
              </li>      
              <li id="li34">
                <a className="nav-link u-header__sub-menu-nav-link" href="https://batch.hscan.org/"  target="_blank">
                  <i className="fa fa-angle-right small mr-1"></i>HPB MainNet Disperse
                 
                </a>
              </li>    
              
              <li id="li35">
                <a className="nav-link u-header__sub-menu-nav-link" href="https://token.hscan.org/" target="_blank">
                  <i className="fa fa-angle-right small mr-1"></i>HPB Token Generator
                  <sup>
                    <span className="badge badge-danger font-size-default ml-1"> Beta</span>
                  </sup>
                </a>
              </li>    
              <li id="li36">
                <a className="nav-link u-header__sub-menu-nav-link" href="/pushTx">
                  <i className="fa fa-angle-right small mr-1"></i>{t('common.developer.pushTx')}
                  <sup>
                    <span className="badge badge-danger font-size-default ml-1"> Beta</span>
                  </sup>
                </a> 
              </li>          
            </ul>
          </div>  
          <div className="col-sm-6 col-md-4 col-lg mb-4 mb-lg-0">
             <span className="u-header__sub-menu-title">{t('common.tools.tool')}</span>
             <ul className="u-header__sub-menu-nav-group mb-3">
              <li id="li41">
                <a className="nav-link u-header__sub-menu-nav-link" href="/unitconverter">
                  <i className="fa fa-angle-right small mr-1"></i>{t('common.tools.unitconverter')} </a>
              </li> 
              <li id="li42">
                <a className="nav-link u-header__sub-menu-nav-link" href="/nodelogs">
                  <i className="fa fa-angle-right small mr-1"></i>{t('common.nav.nodeLogs')}
                  <sup>
                    <span className="badge badge-danger font-size-default ml-1"> Beta</span>
                  </sup>
                </a>
              </li>  
            </ul>
          </div>  
        </div>
      </div>
    </li>
  );
}

export default function Header() {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const isMinMD = useMediaQuery({query: mediaquerys.md});
  const {t} = useTranslation(['common']);
  const [expandNavBar, setExpandNavBar] = useState(false);
  const isHome = location.pathname === '/';
  const user = userContext.user || {};

  console.log('userContext', user.user_name);

  const dashboardRequest = useRequest(
    {
      url: '/blockBrowser/index/dashboard',
      method: 'post',
    },
    { manual: true },
  );
  const [dashboard, setDashboard] = useState({});

  const [state] = useState({
    navs: {
      home: {
        title: t('common.nav.home'),
        path: '/',
        children: [],
      },
      blockchain: {
        title: t('common.nav.blockchain'),
        children: [
          {
            title: t('common.nav.topaccounts'),
            path: '/accounts',
          },
          {
            type: 'divider',
          },
          {
            title: t('common.nav.txns'),
            path: '/txs',
          },
          {
            title:  t('common.nav.txsPending'),
            path: '/txsPending',
          },
          {
            title: t('common.nav.txsInternal'),
            path: '/txsInternal',
          },
          {
            type: 'divider',
          },
          {
            title: t('common.nav.blocks'),
            path: '/blocks',
          }, 
          // {
          //   title: 'View Block Rewards (Beta)',
          //   path: '/blocks_rewards',
          // },
          // {
          //   title: 'Forked Blocks (Reorgs)',
          //   path: '/blocks_forked',
          // },
          // {
          //   type: 'divider',
          // },
          // {
          //   title: 'Verified Contracts',
          //   path: '/contractsVerified',
          // },
        ],
      },
     /*  validators: {
        title: 'Validators',
        children: [
          {
            title: 'Validators Leaderboard',
            path: '/validators',
          },
          {
            title: 'View Validators Set Info',
            path: '/validatorset',
          },
        ],
      },
       */

      tokens: {
        title: t('common.nav.tokens'),
        children: [
          {
            title: t('common.nav.hrc20marketcap'),
            path: '/tokens',
          },
           {
            title: t('common.nav.hrc20volume'),
            path: '/tokens-volume',
          },  
          {
            title: t('common.nav.hrc20Transfers'),
            path: '/tokentxns',
          },
          {type: 'divider'},
          {
            title: t('common.nav.hrc721'),
            path: '/tokens-nft',
          },
          {
            title: t('common.nav.hrc721Transfers'),
            path: '/tokentxns-nft',
          },
        ],
      },
     /*  resources: {
        title: 'Resources',
        children: [
           {
            title: 'Charts & Stats',
            path: '/charts',
          }, 
          {
            title: 'Top Statistics',
            path: '/topstat',
          },
           {type: 'divider'},
          {
            title: 'Yield Farms List',
            path: '/yieldfarms',
          }, 
        ],
      },
    */
      misc: {
        title: 'Misc',
        minWidth: '200px',
        children: [
          // {
          //   title: 'APIs',
          //   path: '/hpbapis',
          // },
          // {
          //   title: 'Verify Contract',
          //   path: '/verifyContract',
          // },  
          // {
          //   title: 'Leaderboard',
          //   path: '/Leaderboard',
          // }, 
          // {
          //   title: 'Node Status ',
          //   path: '/nodestatus',
          // },
          // {
          //   title: 'Confidential Payment (Beta) ',
          //   path: '/cftxs',
          // }, 
          // {
          //   title: 'Byte to Opcode',
          //   path: '/opcode-tool',
          // },
          // {
          //   title: 'Broadcast TXN',
          //   path: '/pushTx',
          // },
          // {
          //   title: 'Vyper Online Compiler',
          //   path: '/vyper',
          // },
          // {
          //   title: () => {
          //     return (
          //       <>
          //         Token Approvals{' '}
          //         <sup>
          //           <span className="badge badge-secondary ml-1"> Beta</span>
          //         </sup>
          //       </>
          //     );
          //   },
          //   path: '/tokenapprovalchecker',
          // },
          // {type: 'divider'},
          // {
          //   title: 'Pending Tx Query',
          //   path: '/pending',
          // },
          // {
          //   title: 'Gas Price',
          //   path: '/gasprice',
          // },       
          // {
          //   title: 'Block Monitor (Beta)',
          //   path: '/blockmonitor',
          // }, 
          // {
          //   title: 'HPB MainNet Faucet',
          //   path: 'https://hscan.org/faucet/',
          // }, {
          //   title: 'HPB MainNet Disperse',
          //   path: 'https://batch.hscan.org/',
          // },{
          //   title: 'HPB Token Generator',
          //   path: 'https://token.hscan.org/',
          // },
        ],
      },
      more:{
        title: t('common.nav.more'),
        minWidth: '200px',
        children:[
          {
            title: 'HPB Mainnet',
            path: 'https://hpbnode.com',
          }
        ]
      },
      explorers: {
        title: () => {
          return (
            <>
              <span className="btn btn-sm btn-icon btn-soft-primary cursor-pointer d-none d-md-inline-block">
                <img className="u-xs-avatar btn-icon__inner" src="/images/svg/brands/hpb-icon.svg?v=1.4" alt="HPB" />
              </span>
              <span className="d-inline-block d-md-none">Explorers</span>
            </>
          );
        },
        minWidth: '140px',
        children: [
          {
            title: 'HPB Mainnet',
            path: 'https://hpbnode.com',
          },
        /*   {
            title: 'HPB Testnet',
            path: 'https://hpbnode.com',
          }, */
        ],
      },
    },
    signIn: {
      path: '/login',
      title: () => {
        return (
          <>
            <i className="fa fa-user-circle mr-1"></i>{t('common.nav.login')}
          </>
        );
      },
      minWidth: '190px',
    },
    account: {
      title: u => {
        return (
          <>
            <i className="fa fa-user-circle mr-1"></i>
            {u.user_name}
          </>
        );
      },
      minWidth: '190px',
      children: [
        // {
        //   title: 'My Profile',
        //   path: '/myaccount',
        // },
        {
          title: t('common.myauthenticator'),
          path: '/myauthenticator',
        },
        {
          title: t('common.myapikey'),
          path: '/myapikey',
        },
        // {type: 'divider'},   {
        //   title: 'Watch List',
        //   path: '/myaddress',
        // },
        // {
        //   title: 'Private Name Tags',
        //   path: '/mynotes_address',
        // },
        // {
        //   title: 'Txn Private Notes',
        //   path: '/mynotes_tx',
        // },
      
     
       /*  {type: 'divider'},
        {
          title: 'Watch List',
          path: '/myaddress',
        },
        {
          title: 'Txn Private Notes',
          path: '/mynotes_tx',
        },
        {
          title: 'Address Private Notes',
          path: '/mynotes_address',
        },
        {
          title: 'Token Ignore List',
          path: '/mytokenignore',
        },
        {type: 'divider'},
        {
          title: 'API Keys',
          path: '/myapikey',
        },
        {
          title: 'Custom ABI',
          path: '/mycustomabi',
        }, */
        {type: 'divider', className: 'mb-3'},
        {
          className: 'px-3',
          component: () => {
            return (
              <a className="btn btn-xs btn-block btn-soft-primary" href="/login?cmd=logout">
                {t('common.signOut')}
              </a>
            );
          },
        },
      ],
    },
  });

  useEffect(() => {
    async function fetch () {
      dashboardRequest.run().then(res => {
        setDashboard(res);
      });
    }
    fetch();
  }, []);

  const handleToggleNavigation = () => {
    setExpandNavBar(!expandNavBar);
  };
 
  let percent = ((dashboard.current_price - dashboard.open_price) / dashboard.open_price) * 100;
  if(isNaN(percent)) percent = 0
  return (
    <header className="u-header" id="Header">
      <div className={`u-header__section ${isHome ? 'py-1' : ''}`}>
        <div className="container">
          <nav className={`js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space hs-menu-initialized hs-menu-horizontal ${!isMinMD ? 'hs-mobile-state' : ''}`}>
            <div className="w-lg-auto">
              <Logo />
              {!isHome ? (
                <div id="ethPrice" >
                  <div className="d-none d-md-inline-block u-label u-label--price rounded mt-1 ml-n1 text-nowrap">
                    <span className="text-dark">HPB: ${dashboard.current_price}</span>
                    <span data-toggle="tooltip" data-placement="top" data-title="Changes in the last 24 hours" data-original-title="" title="">
                      <span className="text-danger"> ({percent.toFixed(2)}%)</span>
                    </span>
                  </div>
                </div>
              ) : undefined}
            </div>
            <div>
              <ToggleNavigation onClick={handleToggleNavigation} expanded={expandNavBar} />
            </div>
            <div className="d-flex flex-column w-100">
              <div className={`collapse navbar-collapse u-header__navbar-collapse order-md-2 ${expandNavBar ? 'show' : 'dd'}`}>
                <ul className="navbar-nav u-header__navbar-nav px-2 px-md-0">
                  <Menu {...state.navs.home} />
                  <Menu {...state.navs.blockchain} />
                  {/* <Menu {...state.navs.validators} /> */}
                  <Menu {...state.navs.tokens} />
                  {/* <Menu {...state.navs.resources} /> */}
                  {/* <Menu {...state.navs.misc} /> */}
                  <Menu2 {...state.navs.more} />
                  
                  <li className="u-header__nav-separator"></li>
                  {user.user_id ? <Menu {...state.account} user={user} ulClassName="hs-position-right hs-reversed" /> : <Menu {...state.signIn} ulClassName="hs-position-right hs-reversed" />} 
                 
                  <li className="u-header__nav-separator"></li>
                  <Menu {...state.navs.explorers} className="my-md-n1" aClassName="u-header__nav-link-toggle--mobile pr-0" ulClassName="dropdown-menu-md-right" />
                </ul>
              </div>
              {!isHome ? (
                <div id="form1">
                  <SearchBox className="w-100 w-lg-55 order-md-1 ml-md-auto mt-2 mb-2 mb-md-0" />
                </div>
              ) : undefined}
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}
