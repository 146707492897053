import {useState, useRef, useEffect} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';

export default function Miner(params) {
  const location = useLocation();
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const body = {
    date_range: Number(query.date_range) || 7,
  };
  const dailyTradeChartRequest = useRequest({
    url: '/blockBrowser/resources/chartStats/validatorsData/minerChart',
    method: 'post',
    body: JSON.stringify(body),
  });

  const data = dailyTradeChartRequest.data || [];

  return (
    <main id="content" role="main">
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center border-bottom py-3">
          <div className="mb-1 mb-md-0">
            <h1 className="h4 mb-0">Top 25 Validators by Blocks</h1>
          </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb small p-0 mb-0">
              <li className="breadcrumb-item">
                <a href="/charts">Charts &amp; Stats</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/charts#topstats">Top Statistics</a>
              </li>
              <li className="breadcrumb-item active">Top 25 Validators by Blocks</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container space-bottom-2 mt-3">
        <div className="card mt-3 mb-4">
          <div className="card-body">
            <span>
              Top 25 Validators by Blocks breakdown the top 25 miner by the number of blocks each validator validated and the percentage validated on the HPB Chain network. The chart can be
              viewed in last 24 hours, last 7 days and last 14 days.
            </span>
          </div>
        </div>
        <form method="post" action="./miner?range=1&amp;blocktype=blocks" id="ctl00">
          <div className="d-flex align-items-center text-secondary mb-2">
            <i className="fa fa-filter mr-1"></i>
            Range: &nbsp;
            <select
              className="custom-select custom-select-xs mx-2"
              defaultValue={body.date_range}
              onChange={e => {
                console.log(e);
              }}
            >
              <option value="1">Last 24 Hours</option>
              <option value="7">Last 7 Days</option>
              <option value="14">Last 14 Days</option>
            </select>
          </div>
        </form>
        <div className="card">
          <div className="card-body">
            <div id="ContentPlaceHolder1_chartdiv" className="col-md-12 mb-3" style={{padding: '0px', margin: '0px'}}>
              <div id="container" className="mb-2 h-25" data-highcharts-chart="0"></div>
            </div>
            <div className="d-md-flex justify-content-between align-items-center mb-4">
              <p className="mb-2 mb-md-0">
                <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>(A total of 46,448 blocks validated by 21 validators in the last 24 hours)
              </p>
              <ul className="pagination pagination-sm mb-0">
                <li className="page-item disabled">
                  <a href="../#" id="ContentPlaceHolder1_pageLinkFirst" className="page-link" aria-label="First">
                    First
                  </a>
                </li>
                <li className="page-item disabled">
                  <a href="../#" id="ContentPlaceHolder1_pageLinkPrev" className="page-link" aria-label="Prev">
                    <i className="fa fa-chevron-left small"></i>
                    <span className="sr-only">Prev</span>
                  </a>
                </li>
                <li className="page-item disabled">
                  <span className="page-link">
                    Page <b>1</b> of <b>1</b>
                  </span>
                </li>
                <li className="page-item disabled">
                  <a href="../#" id="ContentPlaceHolder1_pageLinkNext" className="page-link" aria-label="Next">
                    <i className="fa fa-chevron-right small"></i>
                    <span className="sr-only">Next</span>
                  </a>
                </li>
                <li className="page-item disabled">
                  <a href="../#" id="ContentPlaceHolder1_pageLinkLast" className="page-link">
                    Last<span className="sr-only">Last</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-12">
              <div className="table-responsive mb-2 mb-md-0">
                <table className="table table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">Address</th>
                      <th scope="col">Blocks Validated</th>
                      <th scope="col">Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>21</td>
                      <td>
                        <a href="/address/0x0ed0d1f46391e08f0ceab98fc3700e5d9c1c7b19#mine">0x0ed0d1f46391e08f0ceab98fc3700e5d9c1c7b19</a>
                      </td>
                      <td>
                        <a href="/blocks?m=0x0ed0d1f46391e08f0ceab98fc3700e5d9c1c7b19">2,211</a>
                      </td>
                      <td>4.7602%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
