const home = {
    'home.banner.title': 'HPB Chain Explorer',

    'home.lasteblock.title':'Latest Blocks', 
    'home.lasteblock.viewBlocks':' View all blocks',

    'home.lasteTransactions.title':'Latest Transactions',
    'home.lasteTransactions.from':'From',
    'home.lasteTransactions.to':'To',
    'home.lasteblock.viewTransactions':' View all transactions',

    'home.searchbox.all':'All Filters',
    'home.searchbox.addresses':'Addresses',
    'home.searchbox.tokens':'Tokens',
    'home.searchbox.nameTags':'Name Tags',
    'home.searchbox.labels':'Labels',
    'home.searchbox.websites':'Websites',
    'home.searchbox.placeholder':'Search by Address / Txn Hash / Block / Token',

    'home.dashboard.hpbprice':'HPB Price',
    'home.dashboard.viewhistoryprice':'View Historical HPB Price',
    'home.dashboard.view14dayhistoryprice':'HPB TRANSACTION HISTORY LAST 14 DAYS',
    'home.dashboard.btc':'BTC',
    'home.dashboard.hpbmarketcap':'HPB Market Cap',
    'home.dashboard.hpbsupplytip':'Circulating Supply / Total Supply',
    'home.dashboard.lastestBlock':'Latest Block',
    'home.dashboard.lastestBlockIips':'The latest Block No',
    'home.dashboard.lastestBlockIips2':'Average Block Time (The latest 5000 blocks)',
    'home.dashboard.ActiveValidator':'Active Validator',
    'home.dashboard.TOTALSTAKED':'TOTAL STAKED',
    'home.dashboard.transactions':'Transactions',
    'home.dashboard.transactionsTips':'Total Transactions Counter. <br />(Update every 5 mins)',
    'home.dashboard.transactionsTips2':'Transactions per Second',
    'home.dashboard.TotalActiveValidator':'Total Active Validator',
    'home.dashboard.ValidatedBy':'Validated By',

    'home.random.latestblock': 'latest block',
    'home.random.provable': 'onchain provable random number',
  

};

export default home;
