import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import Loading from '../../components/Loading';
import Pager from '../../components/Pager';
import {useTranslation} from 'react-i18next';
export default function TokenExchange({a, token, overview}) {
  const {t} = useTranslation(['token']);
  const [state] = useState({
    body: {
      start: '1',
      length: '50',
      field: 'token_address',
      value: token,
    },
  });

  const tokenExchangeRequest = useRequest(
    body => ({
      url: '/blockBrowser/tokens/address/tokenExchange',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    tokenExchangeRequest.run(state.body);
  }, []);

  if (tokenExchangeRequest.loading) {
    return <Loading />;
  }

  const data = tokenExchangeRequest.data?.token_exChange_list || [];
  const counts = tokenExchangeRequest.data?.counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);

  return (
    <div className="tab-pane fade active show" id="tokenExchange">
      <div className="d-flex justify-content-lg-end align-items-right mb-2 mb-lg-0">
        <nav className="mb-2 mb-md-3 mr-md-2 text-secondary small" aria-label="page navigation">
          Exchange Data Source:{' '}
          <a href="https://www.coingecko.com/en" target="_blank" rel="nofollow nopener">
            Coingecko
          </a>
        </nav>
      </div>
      <div className="table-responsive" style={{borderColor: 'white'}}>
        <table className="table table-hover" border="0" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th>#</th>
              <th>{t('token.TokenExchange.Exchange')}</th>
              <th>{t('token.TokenExchange.Pair')}</th>
              <th>{t('token.TokenExchange.Price')}</th>
              <th data-toggle="tooltip" title="" data-original-title="Sorted by 24 hours volume from highest to lowest">
                <b>
                  <i className="fa fa-angle-down" style={{color: 'gray'}}></i>
                </b>
                &nbsp;{t('token.TokenExchange.24Volume')}
              </th>
              <th>% {t('token.TokenExchange.Volume')}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{item.order_no}</td>
                  <td>
                    <div style={{minWidth: '16px', display: 'inline-table', marginRight: '2px'}}>
                      <img src="/images/exchanges/empty.png" style={{width: '16px', marginTop: '-4px'}} />
                    </div>
                    <span style={{margin: '2px'}}>{item.trade_name}</span>
                  </td>
                  <td>
                    <a href={item.pair_url} target="_blank" rel="nofollow noopener">
                      <span className="hash-tag text-truncate">{item.pair_name}</span>
                    </a>
                  </td>
                  <td>
                    ${item.price_dollar}
                    <br />
                    <span className="small">{item.price_btc} Btc </span>
                  </td>
                  <td>
                    ${item['24h_volume_dollar']}
                    <br />
                    <span className="small hash-tag text-truncate" data-toggle="tooltip" title="" data-original-title={`${item['24h_volume_other']} ETH`}>
                      {item['24h_volume_other']} ETH{' '}
                    </span>
                  </td>
                  <td>{item.volume}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
