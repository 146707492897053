import {useTranslation} from 'react-i18next';

export default function MySideBar({value}) {
  const {t} = useTranslation(['mySideBar']);
  return (
    <div className="col-md-3">
      <div className="list-group list-group-lg mb-3">
       
        {/* <a href="myaccount" className={`list-group-item list-group-item-action ${value === 'myaccount' ? 'active' : ''}`}>
          <i className="fa fa-user-md mr-1"></i> Account Overview
        </a>

        <a href="mysettings" className={`list-group-item list-group-item-action ${value === 'mysettings' ? 'active' : ''}`}>
          <i className="fa fa-user-shield mr-1"></i> Account Settings
        </a> */}

        <a href="myauthenticator" className={`list-group-item list-group-item-action ${value === 'myauthenticator' ? 'active' : ''}`}>
          <i className="fa fa-user-circle mr-1"></i> {t('mySideBar.myauthenticator')}
        </a>

        <a href="myapikey" className={`list-group-item list-group-item-action ${value === 'myapikey' ? 'active' : ''}`}>
          <i className="fa fa-key mr-1"></i>{t('mySideBar.myapikey')}  
        </a>

        {/* <a href="myaddress" className={`list-group-item list-group-item-action ${value === 'myaddress' ? 'active' : ''}`}>
          <i className="fa fa-heart mr-1"></i> Watch List
        </a>
        <a href="mynotes_address" className={`list-group-item list-group-item-action ${value === 'mynotes_address' ? 'active' : ''}`}>
          <i className="far fa-sticky-note mr-1"></i> Private Name Tags
        </a> */}
        {/* <a href="mynotes_tx" className={`list-group-item list-group-item-action ${value === 'mynotes_tx' ? 'active' : ''}`}>
          <i className="far fa-sticky-note mr-1"></i> Txn Private Notes
        </a> */}
 

       {/*   <a href="myaccount" className={`list-group-item list-group-item-action ${value === 'myaccount' ? 'active' : ''}`}>
          <i className="fa fa-user-circle mr-1"></i> My Profile
        </a>
       <a href="myaddress" className={`list-group-item list-group-item-action ${value === 'myaddress' ? 'active' : ''}`}>
          <i className="fa fa-heart mr-1"></i> Watch List
        </a>
        <a href="mynotes_tx" className={`list-group-item list-group-item-action ${value === 'mynotes_tx' ? 'active' : ''}`}>
          <i className="far fa-sticky-note mr-1"></i> Txn Private Notes
        </a>
        <a href="mynotes_address" className={`list-group-item list-group-item-action ${value === 'mynotes_address' ? 'active' : ''}`}>
          <i className="far fa-sticky-note mr-1"></i> Address Private Notes
        </a>
        <a href="mytokenignore" className={`list-group-item list-group-item-action ${value === 'mytokenignore' ? 'active' : ''}`}>
          <i className="fas fa-eye-slash"></i> Token Ignore List
        </a>
       
        <a href="mycustomabi" className={`list-group-item list-group-item-action ${value === 'mycustomabi' ? 'active' : ''}`}>
          <i className="fa fa-tasks mr-1"></i> Custom ABIs
        </a> */}
      </div>
      <div className="card mb-3">
        <div className="card-body">
          <div className="media">
            <span className="btn btn-sm btn-icon btn-soft-info rounded-circle mb-2 mr-2">
              <span className="fas fa-info btn-icon__inner"></span>
            </span>
            <div className="media">
              <h2 className="h5">{t('mySideBar.accountSet')}?</h2>
            </div>
          </div>
          <p className="mb-0">
           {t('mySideBar.tip1')}
            {/* You can also update your email address or password here. */}
            <br />
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}
