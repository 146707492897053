const block = { 
    'block.title': 'Block', 
    'block.Overview': 'Overview', 
    'block.BlockHeight': 'Block Height', 
    'block.Timestamp': 'Timestamp', 
    'block.Validated': 'Validated by', 
    'block.blockReward': 'Block Reward', 
    'block.Transactions': ' Transactions', 
    'block.transactions': 'transactions', 
    'block.ClickTransactions': ' Click to view Transactions', 
    'block.contractInteralTransactions': ' contract internal transactions', 
    'block.thisblock': ' in this block', 
    'block.Random': 'Random(Hex)', 
    'block.RandomNumber': 'Random Number', 
    'block.UnclesReward': 'Uncles Reward', 
    'block.Difficulty': 'Difficulty', 
    'block.TotalDiff': 'Total Difficulty', 
    'block.Size': 'Size', 
    'block.gasUsed': 'Gas Used', 
    'block.gasLimit': 'Gas Limit', 
    'block.extraData': ' Extra Data', 
    'block.hash': 'Hash', 
    'block.ParentHash': 'Parent Hash', 
    'block.Sha3Uncles': 'Sha3Uncles', 
    'block.Nonce': 'Nonce', 
    'block.Overview.ClicktoseeLess': 'Click to see Less',
    'block.Overview.ClicktoseeMore': 'Click to see More',

};

export default block;
