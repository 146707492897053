import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';  
import Loading from '../../components/Loading';


export default function MyAccountActive() {

    const location = useLocation();
    const query = qs.parse(location.search, {ignoreQueryPrefix: true});

    const [state, setState] = useState({
      body: {
        token: query.token
      },
    });  
    const activedRequest = useRequest(
        body => ({
        url: '/loginapi/sys/user/actived',
        method: 'post',    
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(body),
        }),
        {manual: true, formatResult: r => r},
    ); 
    useEffect(() => {
        activedRequest.run(state.body);
    }, [state]);

    if (activedRequest.loading) {
        return <Loading />;
    }



    const showError = typeof activedRequest.data?.code !== 'undefined' && activedRequest.data?.code !== 200;

  return (
    <main className="bg-light content-height" role="main">
      <div className="container space-2">
        <div className="js-validate w-md-75 w-lg-50 mx-md-auto">
          
          <div className="mb-7">
            <h1 className="h3 text-primary font-weight-normal mb-2">
               <span className="font-weight-semi-bold">Activated Account</span> 
            </h1> 
          </div>
          
          <div className="alert alert-danger" style={{display: showError ? 'block' : 'none'}}>
            {activedRequest.data?.message}
          </div>
          
          <div className="alert alert-info" style={{display: !showError ? 'block' : 'none'}}>
            {activedRequest.data?.message}
          </div> 
          
          <div className="row text-center" style={{display: !showError ? 'block' : 'none',marginLeft:"200px"}}>
            <div className="col-md-3">
                <span className="text-muted text-nowrap">&nbsp;&nbsp;</span> 
            </div>
            <div className="col-md-6">
                <a href="/login" className="btn btn-block btn-primary"> Click To Login</a>  
            </div> 
          </div>

        </div>
      </div>
    </main>
  );
}
