import {useEffect, useState, useRef} from 'react';
import LinkWithTooltip from '../../components/LinkWithTooltip';
import {useTranslation} from 'react-i18next';
export default function CardTabsHeader({data, tabs, current, onChange,queryA}) {
  const {t} = useTranslation(['token']);
  const [state, setState] = useState({
    showSearchBox: false 
  });

  const handleToggleSearch = e => {
    e.preventDefault();
    setState({...state, showSearchBox: !state.showSearchBox});
  };


  return (
    <div className="card-header d-md-flex align-items-start p-0">
      <ul className="nav nav-custom nav-borderless nav_tabs1 order-2 order-md-1" role="tablist">
        {tabs.map(tab => {
          return (
            <li className="nav-item" key={tab.key}>
              <a
                className={`nav-link ${current === tab.key ? 'active' : ''}`}
                href={`#${tab.key}`}
                data-toggle="tab"
                onClick={() => {
                  onChange(tab.key);
                }}
              >
                {typeof tab.title === 'function' ? tab.title() : tab.title}
              </a>
            </li>
          );
        })}
      </ul>
      <div className="d-flex order-1 order-md-2 ml-md-auto my-2">
        {
          queryA? <div id="ContentPlaceHolder1_searchdiv" className="btn-group order-2 order-md-1 ml-2 mr-md-2 " role="group">
          <span className="btn btn-xss btn-soft-secondary ml-auto">
            <span className="hash-tag text-truncate" data-toggle="tooltip" date-placement="top" title="" data-original-title="Filtered by TokenID"> 
               <LinkWithTooltip placement="bottom" tooltip={queryA}> 
                 <span> {queryA}</span>
              </LinkWithTooltip>
            </span>
          </span>
          <a
            className="btn btn-sm btn-icon btn-soft-secondary"
            href={`/token/${data.token_address}`}
            role="button"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title="Clear filter Address / Txn Hash / Token ID"
          >
            <i className="fa fa-times btn-icon__inner"></i>
          </a>
        </div>
          :''
        }
        

        <div className="position-relative order-1 order-md-2 mr-md-3 ml-3 ml-md-0">
          <a
            className="btn btn-sm btn-icon btn-primary"
            href="#"
            role="button"
            aria-controls="searchFilter"
            aria-haspopup="true"
            aria-expanded="false"
            data-unfold-target="#searchFilter"
            data-unfold-type="css-animation"
            data-unfold-duration="300"
            data-unfold-delay="300"
            data-unfold-hide-on-scroll="false"
            data-unfold-animation-in="slideInUp"
            data-unfold-animation-out="fadeOut"
            data-toggle="tooltip"
            title=""
            data-original-title="Search for Token Name or Address"
            onClick={handleToggleSearch}
          >
            <i className="fa fa-search btn-icon__inner"></i>
          </a>
          <div
            className={`dropdown-menu dropdown-unfold dropdown-menu-sm-right p-2 u-unfold--css-animation ${state.showSearchBox ? 'slideInUp' : 'u-unfold--hidden'}`}
            aria-labelledby="searchFilterInvoker"
            style={{width: '310px', animationDirection: '300ms'}}
          >
            <form action={`/token/${data.token_address}`} method="get" autoComplete="off" spellCheck="false" className="js-focus-state input-group input-group-sm w-100">
              <input className="form-control" type="search" placeholder={t('token.transfer.placeholder')} name="a" maxLength="66" />
              <div className="input-group-append">
                <button className="btn btn-primary" type="submit">
                  Find
                </button>
              </div>
            </form>
          </div>
          {/*   <a
            className="btn btn-sm btn-icon btn-primary"
            href="#"
            role="button"
            aria-controls="searchFilter"
            aria-haspopup="true"
            aria-expanded="false"
            data-unfold-target="#searchFilter"
            data-unfold-type="css-animation"
            data-unfold-duration="300"
            data-unfold-delay="300"
            data-unfold-hide-on-scroll="false"
            data-unfold-animation-in="slideInUp"
            data-unfold-animation-out="fadeOut"
          >
            <i className="fa fa-search btn-icon__inner"></i>
          </a>

          <div
            className="dropdown-menu dropdown-unfold dropdown-menu-sm-right p-2 u-unfold--css-animation u-unfold--hidden fadeOut"
            aria-labelledby="searchFilterInvoker"
            style={{animationDuration: '300ms', right: 0}}
          >
            <form className="js-focus-state input-group input-group-sm" style={{width: '310px'}} method="get" action={`/token/${data.token_address}`} autoComplete="off">
              <input className="form-control" type="search" placeholder="Filter Address / Txn Hash / Token ID" name="a" maxLength="66" />
              <div className="input-group-append">
                <button className="btn btn-primary" type="submit">
                  Find
                </button>
              </div>
            </form>
          </div> */}
        </div>
      </div>
    </div>
  );
}
