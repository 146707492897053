import {useEffect, useState, useRef} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';
import {useTranslation} from 'react-i18next';

import Pager from '../../components/Pager';

export default function TokensNft() {
  const location = useLocation();
  const {t} = useTranslation(['tokensNft']);
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const [tokensNft, setTokensNft] = useState();
  const [state, setState] = useState({
    showSearchBox: false,
    body: {
      start: query.p || '1',
      length: window.localStorage.getItem('pageLength') || '50',
    },
  });
  const searchInputRef = useRef();

  const tokensNftRequest = useRequest(
    body => ({
      url: '/blockBrowser/tokens/tokenNft/tokenNftList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  const searchRequest = useRequest(
    body => ({
      url: body.field === 'name' ? '/blockBrowser/tokens/tokenNft/searchName' : '/blockBrowser/tokens/tokenNft/searchAddress',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  const handleChangePageSize = e => {
    window.localStorage.setItem('pageLength', e.target.value);
    setState({...state, body: {...state.body, length: e.target.value}});
  };

  const handleToggleSearch = e => {
    e.preventDefault();
    setState({...state, showSearchBox: !state.showSearchBox});
  };

  const handleSearch = e => {
    const v = searchInputRef.current.value;

    if (!v) {
      return;
    }

    searchRequest.run({field: v.indexOf('0x') === 0 ? 'address' : 'name', value: v}).then(res => {
      console.log(res);
      setTokensNft(res);
    });
  };

  useEffect(() => {
    tokensNftRequest.run(state.body).then(res => {
      setTokensNft(res);
    });
  }, [state]);

  const data = tokensNft?.token_nft_list || tokensNft?.token_nft_name_list || [];
  const counts = tokensNft?.counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);

  return (
    <main role="main">
      <div className="container py-3">
        <div className="d-sm-flex align-items-center">
          <div className="mb-2 mb-sm-0">
            <h1 className="h4 d-flex align-items-center mb-0">
             {t('tokensNft.title')}<span className="u-label u-label--xs u-label--secondary rounded color-strong ml-2">HRC-721</span>
            </h1>
          </div>
        </div>
      </div>
      <div className="container space-bottom-2">
        <div className="card">
          <div className="card-header d-md-flex justify-content-between align-items-center">
            <div className="mb-1 mb-md-0">
              <h2 className="card-header-title">Non-Fungible Tokens (NFT)</h2>
            </div>
            <div className="d-flex ml-auto">
              <div className="position-relative order-1 order-md-2">
                <a
                  className="btn btn-sm btn-icon btn-primary"
                  href="#"
                  role="button"
                  aria-controls="searchFilter"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-unfold-target="#searchFilter"
                  data-unfold-type="css-animation"
                  data-unfold-duration="300"
                  data-unfold-delay="300"
                  data-unfold-hide-on-scroll="false"
                  data-unfold-animation-in="slideInUp"
                  data-unfold-animation-out="fadeOut"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Search for Token Name or Address"
                  onClick={handleToggleSearch}
                >
                  <i className="fa fa-search btn-icon__inner"></i>
                </a>
                <div
                  className={`dropdown-menu dropdown-unfold dropdown-menu-sm-right p-2 u-unfold--css-animation ${state.showSearchBox ? 'slideInUp' : 'u-unfold--hidden'}`}
                  aria-labelledby="searchFilterInvoker"
                  style={{width: '310px', animationDirection: '300ms'}}
                >
                  <form action="/tokens-nft" method="get" autoComplete="off" spellCheck="false" className="js-focus-state input-group input-group-sm w-100">
                    <input name="q" type="search" ref={searchInputRef} className="form-control ui-autocomplete-input" placeholder="Search for Token Name or Address" autoComplete="off" />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-primary" onClick={handleSearch}>
                        Find
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="d-md-flex justify-content-between align-items-lg-center mb-4">
              <p className="mb-2 mb-md-0">
                <i className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>{t('tokensNft.tip1')}{counts} HRC-721 {t('tokensNft.tip2')}
              </p>
              <Pager path="/tokens-nft" current={state.body.start} total={totalPage} />
            </div>
            <div className="tab-content">
              <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="tokens-nft-tab">
                <div className="stickyTableHead">
                  <div className="sticky-thead-cloned-wrapper" style={{display: 'none'}}>
                    <table className="sticky-thead-cloned-table table table-text-normal table-hover mb-0 border-bottom-0" style={{width: '1084px'}}>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" width="1" className="token-sticky-header" style={{width: '35px'}}>
                            #
                          </th>
                          <th scope="col" className="token-sticky-header" style={{width: '814px'}}>
                            Token
                          </th>
                          <th scope="col" width="100" className="text-nowrap" style={{width: '127px'}}>
                            <i className="fa fa-angle-down" style={{color: 'gray'}}></i>{' '}
                            <a href="/tokens-nft?sort=24h&amp;order=asc" data-toggle="tooltip" data-boundary="viewport" data-html="true" title="" data-original-title="Click for ascending sort">
                              Transfers (24H)
                            </a>
                          </th>
                          <th scope="col" width="100" className="text-nowrap" style={{width: '108px'}}>
                            <a href="/tokens-nft?sort=7d&amp;order=desc" data-toggle="tooltip" data-boundary="viewport" data-html="true" title="" data-original-title="Click for descending sort">
                              Transfers (7D)
                            </a>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div className="table-responsive mb-2 mb-md-0">
                    <table className="table table-text-normal table-hover">
                      <thead className="thead-light sticky-thead-original-thead">
                        <tr>
                          <th scope="col" width="1" className="token-sticky-header">
                            <div className="sticky-thead-original-th-inner-wrapper">#</div>
                          </th>
                          <th scope="col" className="token-sticky-header">
                            <div className="sticky-thead-original-th-inner-wrapper">{t('tokensNft.table.token')}</div>
                          </th>
                          <th scope="col" width="100" className="text-nowrap">
                            <div className="sticky-thead-original-th-inner-wrapper">
                              <i className="fa fa-angle-down" style={{color: 'gray'}}></i>{' '}
                              <a href="/tokens-nft?sort=24h&amp;order=asc" data-toggle="tooltip" data-boundary="viewport" data-html="true" title="" data-original-title="Click for ascending sort">
                              {t('tokensNft.table.transfersTime')}
                              </a>
                            </div>
                          </th>
                          <th scope="col" width="100" className="text-nowrap">
                            <div className="sticky-thead-original-th-inner-wrapper">
                              <a href="/tokens-nft?sort=7d&amp;order=desc" data-toggle="tooltip" data-boundary="viewport" data-html="true" title="" data-original-title="Click for descending sort">
                              {t('tokensNft.table.TransfersDay')} 
                              </a>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map(item => {
                          return (
                            <tr key={item.order_no}>
                              <td>{item.order_no}</td>
                              <td>
                                <div className="media">
                                  <img className="u-xs-avatar mr-2" src="/images/main/empty-token.png" />
                                  <div className="media-body">
                                    <h3 className="h6 mb-0">
                                      <div className="token-wrap">
                                        <a className="text-primary" href={`/token/${item.token_address}`} data-toggle="tooltip" title="" data-original-title={item.token_address}>
                                          {item.token_name}
                                        </a>
                                      </div>
                                    </h3>
                                  </div>
                                </div>
                              </td>
                              <td>{item.transfers_24h}</td>
                              <td>{item.transfers_seven_day}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <form method="post" action="./tokens-nft">
                  <div className="d-md-flex justify-content-between align-items-center my-3">
                    <div className="d-flex align-items-center text-secondary mb-3 mb-md-0">
                    {t('tokensNft.tip5')}
                      <select name="ctl00$ContentPlaceHolder1$ddlRecordsPerPage" value={state.body.length} className="custom-select custom-select-xs mx-2" onChange={handleChangePageSize}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      {t('tokensNft.tip6')}
                    </div>
                    <Pager path="/tokens-nft" current={state.body.start} total={totalPage} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
