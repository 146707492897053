import {useState, useRef, useEffect} from 'react';
import {useRequest} from 'ahooks';

function Chart({data = []}) {
  const containerRef = useRef();

  useEffect(() => {
    if (data) {
      console.log('data', data);
      const litChartData = data.map(v => {
        return {y: Number(v.total_verified_contracts), formattedValue: v.total_verified_contracts, dt: v.date};
      });
      window.Highcharts.setOptions({
        lang: {
          numericSymbols: [' k', ' M', ' B', ' T', ' P', ' E'],
        },
      });

      //$('#containerchart').highcharts({
      var chart = new window.Highcharts.chart({
        chart: {
          renderTo: containerRef.current,
          zoomType: 'x',
          resetZoomButton: {
            position: {
              align: 'left', // right by default
              verticalAlign: 'top',
              //x: -55,
              //y: 10
              x: 10,
              y: 10,
            },
            relativeTo: 'plot',
          },
        },
        title: {
          useHTML: true,
          text: ' HPB Daily Verified Contracts Chart ',
        },
        subtitle: {
          text: document.ontouchstart === undefined ? 'Source: hpb.io<br>Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
        },
        xAxis: {
          type: 'datetime',
          minRange: 14 * 24 * 3600000,
        },
        yAxis: {
          title: {
            text: 'Total Verified Contracts per Day ',
          },

          min: 0,
          //
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
              stops: [
                [0, window.Highcharts.getOptions().colors[0]],
                [1, window.Highcharts.Color(window.Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
              ],
            },
            marker: {
              radius: 2,
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            threshold: null,
          },
        },
        credits: {
          enabled: false,
        },

        tooltip: {
          formatter: function () {
            return (
              '<span style="font-size:10px">' +
              this.point.dt +
              '</span><br><table><tr><td style="padding:0">' +
              '[ <span style="color:' +
              this.series.color +
              '">Total Verified Contracts : </a></span><b>' +
              this.point.formattedValue +
              '</b> ]<br>' +
              '</td></tr></table>'
            );
          },
        },
        series: [
          {
            type: 'line',
            turboThreshold: 10000,
            name: 'Daily Transaction Count',
            pointInterval: 24 * 3600 * 1000,
            pointStart: Date.UTC(2020, 11, 1),
            data: litChartData,
          },
        ],
      });

      function generateAnnotationText(title, desc, url) {
        var text = '';

        if (title) {
          text +=
            '<tr><td colspan=2 style="padding-bottom:5px;border-bottom:1px solid #D5D8DC;"><b><span style="font-size: 13px; font-weight: 900; font-family: arial;">' +
            title +
            '</span></b><br></td></tr>';
        }

        if (desc) {
          text += '<tr><td style="padding-top:6px;padding-bottom:2px;" valign="top">' + desc;
          if (url) {
            text += ' ' + '<a href="' + url + '" target="_blank" rel="nofollow" class="button" style="color:black"><i class="fa fa-external-link-alt"></i></a>';
          }

          text += '</td></tr>';
        } else if (url) {
          text +=
            '<br><div class="d-flex justify-content-end"><a href="' + url + '" target="_blank" rel="nofollow" class="button" style="color:black"><i class="fa fa-external-link-alt"></i></a></div>';
        }

        return text;
      }

      function getAnnotations() {
        var dataArray = [];
        //var chartdata = chart.series[0].data;
        var i, dataSubArray, add_Location, xCoordinate, yCoordinate;
        for (i = 0; i < dataArray.length; ++i) {
          dataSubArray = dataArray[i];
          add_Location = (i % 3) * 30;
          chart.addAnnotation({
            id: dataSubArray['pointid'],
            labels: [
              {
                useHTML: true,
                point: {x: dataSubArray['x'], y: dataSubArray['y'], xAxis: dataSubArray['xaxis'], yAxis: dataSubArray['yaxis']},
                text: generateAnnotationText(dataSubArray['title'], dataSubArray['desc'], dataSubArray['url']),
                x: -100,
                style: {
                  color: 'rgb(0,0,0)',
                },
              },
            ],
            labelOptions: {
              borderRadius: 5,
              backgroundColor: 'rgba(255, 255, 255)',
              borderWidth: 1,
              borderColor: '#ddd',
              y: 0,
            },
          });
        }
      }

      getAnnotations();
    }
  }, [data]);

  return <div className="content" ref={containerRef} style={{height: '550px', minWidth: '310px', overflow: 'hidden'}} data-highcharts-chart="0"></div>;
}

export default function VerifiedContracts() {
  const body = {};
  const dailyTradeChartRequest = useRequest({
    url: '/blockBrowser/resources/chartStats/contractData/dailyVerifiedContractChart',
    method: 'post',
    body: JSON.stringify(body),
  });

  const data = dailyTradeChartRequest.data || [];

  return (
    <main id="content" role="main">
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center border-bottom py-3">
          <div className="mb-1 mb-md-0">
            <h1 className="h4 mb-0">
              <span className="d-block">HPB Daily Verified Contracts Chart</span>
            </h1>
          </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb small p-0 mb-0">
              <li className="breadcrumb-item">
                <a href="/charts">Charts &amp; Stats</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/charts#blockchainData" id="ContentPlaceHolder1_aChartRegion">
                  Blockchain Data
                </a>
              </li>
              <li className="breadcrumb-item active">HPB Daily Verified Contracts Chart</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container">
        <div className="card mt-3 mb-4">
          <div className="card-body">
            <span>
              The chart shows the total number of contracts verified daily. Check out the 500 most recent <a href="/contractsVerified">verified contracts!</a>
            </span>
          </div>
        </div>
        <div className="card">
          <div className="card-body space-bottom-1">
            <Chart data={data} />
          </div>
        </div>
        <div className="my-4 text-right">
          Download: <a href="/chart/verified-contracts?output=csv">CSV Data</a> (Attribution Required) &nbsp;&nbsp;&nbsp;
        </div>
        <br />
      </div>
    </main>
  );
}
