import {useState, useContext,useEffect} from 'react';
import {useRequest} from 'ahooks';
import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext'; 
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Loading from '../../components/Loading';
import {useTranslation} from 'react-i18next';

function Copy({text, title}) {
  const {t} = useTranslation(['myAuthenticator']);

  const [copied, setCopied] = useState(false);

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      }}
    >
       <button  className="btn btn-primary mb-1 mr-1" type="button">                
            {copied ? (
              <>
                <span className="far fa-check-circle"></span> {t('myAuthenticator.tip5')}
              </>
            ) : (
              <>
                <span className="far fa-copy"></span> {t('myAuthenticator.tip6')}
              </>
            )}
       </button>

    
    </CopyToClipboard>
  );
}

export default function MyAuthenticator() {
  const {t} = useTranslation(['myAuthenticator']);
  const userContext = useContext(UserContext);
  const user = userContext.user || {};

  const [userRandom, setUserRandom] = useState({});
  
  const userRequest = useRequest(
    body => ({
      url: '/loginapi/sys/user/getUserRandom',
      method: 'post',
      headers: { 
        'Content-Type': 'application/json',
        'X-Access-Token': (user.token ||'')
      },
      body: JSON.stringify(body),
    }),
    {manual: true, formatResult: r => r},
  );

  useEffect(() => {     
    if (!user.token) {
      return (window.location.href = '/login');
    }
    console.log('@',1)
    userRequest.run().then(res => { 
      if (res.code === 200) {  
        setUserRandom(res.data.qrModel);
      } 
    }); 
  }, []);

  if (userRequest.loading) {
    return <Loading />;
  } 

  if (!user.token) {
    return (window.location.href = '/login');
  }

  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="myauthenticator" />
          <div className="col-md-9">
            <div className="card">
                <div className="card-header">
                  <h2 className="h5 mb-0 font-weight-bold ">{t('myAuthenticator.title')}</h2>
                </div>
                <div className="card-body px-4">
                  <div className="mb-4"> 
                      <h3 className="h5 mt-1">
                        
                         <div dangerouslySetInnerHTML={{__html:  t('myAuthenticator.tip1')}}  />
                      </h3>  
                      <h3 className="h5 mt-3">
                        {t('myAuthenticator.tip2')}
                      </h3>     
                      <h3 className="h5 mt-3">
                      {t('myAuthenticator.tip3')}
                      </h3>
                      <h3 className="h3 font-weight-bold text-center  mt-2" >
                            {userRandom.enCode}
                      </h3>   
                      <h3 className="h3 font-weight-bold text-center  mt-2" >
                        <Copy text={userRandom.enCode} title="Copy Txn Hash to clipboard" />
                      </h3>  
                      <h3 className="h5 font-weight-bold text-center  mt-2" >
                       {t('myAuthenticator.tip4')}
                      </h3> 
                      <h3 className="h4 font-weight-bold text-center">
                        <img src={userRandom.qrCode} width="200px" alt="random" /> 
                      </h3>
                  </div> 
                </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
