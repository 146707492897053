import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import Pager from '../../components/Pager';
import Loading from '../../components/Loading';
import TimeTD from '../../components/TimeTD';
import LinkWithTooltip from '../../components/LinkWithTooltip';


export default function Tokentxns() {
  const location = useLocation();
  const {t} = useTranslation(['tokentxns']);
  
  const defaultLNG = window.localStorage.getItem('lng') || 'en_US';
  moment.locale(defaultLNG);


  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      length: window.localStorage.getItem('pageLength') || '50',
    },
  });
  const [type, setType] = useState('showAge'); 
  const handleChangePageSize = e => {
    window.localStorage.setItem('pageLength', e.target.value);
    setState({...state, body: {...state.body, length: e.target.value}});
  };

  const tokenTradeListRequest = useRequest(
    body => ({
      url: '/blockBrowser/tokens/tokenTrade/tokenTradeList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    tokenTradeListRequest.run(state.body);
  }, [state]);

  if (tokenTradeListRequest.loading) {
    return <Loading />;
  }

  const data = tokenTradeListRequest.data?.token_trade_list || [];
  const counts = tokenTradeListRequest.data?.counts || 0;
  const token_trade_count = tokenTradeListRequest.data?.token_trade_count || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);

  return (
    <main id="content" role="main">
      <div className="bg-light py-3">
        <div className="container">
          <h1 className="h4 mb-0">
            <div className="d-flex align-items-center">
              {t('tokentxns.title')}
              <a
                className="u-label u-label--xs u-label--secondary rounded color-strong ml-2"
                href="https://docs.hpb.io/#/en-us/dev/hrc20"
                target="_blank"
                rel="nofollow noreferrer"
                data-toggle="tooltip"
                title=""
                data-original-title="External link to HRC-20 Information"
              >
                HRC-20
              </a>
            </div>
            <p className="mb-0 text-break small">
              <span className="small text-secondary"></span>
            </p>
          </h1>
        </div>
      </div>

      <div className="container space-bottom-2">
        <div className="card">
          <div className="card-body">
            <div className="d-md-flex justify-content-between mb-4">
              <p className="mb-2 mb-md-0">
                <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>  {t('tokentxns.tip1')} &gt; {counts}  {t('tokentxns.tip2')}{' '}
                <span className="d-block text-secondary small">( {t('tokentxns.tip3')} {token_trade_count}  {t('tokentxns.title4')})</span>
              </p>
              <Pager path="/tokentxns" current={state.body.start} total={totalPage} />
            </div>
            <div className="table-responsive mb-2 mb-md-0">
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col" width="20"></th>
                    <th scope="col" width="1">
                    {t('tokentxns.table.txnHash')}
                    </th>
                    <th scope="col">
                    {
                          (type ==='showAge')?
                          <LinkWithTooltip placement="bottom" tooltip="Click to show Datetime Format">
                            <a href="#!" title="" onClick={e => {  e.preventDefault(); setType('showDate'); }} >
                            {t('tokentxns.table.age')}
                            </a>
                          </LinkWithTooltip>
                          :
                          <LinkWithTooltip placement="bottom" tooltip="Click to show Age Format"> 
                            <a href="#!" onClick={e => {  e.preventDefault(); setType('showAge'); }}  >
                            {t('tokentxns.table.time')} (UTC)
                            </a>
                          </LinkWithTooltip>
                        }
                    </th>
                    <th scope="col">{t('tokentxns.table.from')}</th>
                    <th scope="col" width="30"></th>
                    <th scope="col">{t('tokentxns.table.to')}</th>
                    <th scope="col">{t('tokentxns.table.value')}</th>
                    <th scope="col">{t('tokentxns.table.token')}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => {
                    const now = new Date().getTime();
                    const time = moment(now - Number(item.trade_time))
                      .startOf('minute')
                      .fromNow();
                    return (
                      <tr key={i}>
                        <td>
                          <a role="button" tabIndex="0" type="button" className="js-txnAdditional-1 btn btn-xs btn-icon btn-soft-secondary myFnExpandBox">
                            <i className="far fa-eye btn-icon__inner"></i>
                          </a>
                        </td>
                        <td>
                          <span className="hash-tag text-truncate">
                            <a href={`/tx/${item.trade_hash}`} className="myFnExpandBox_searchVal">
                              {item.trade_hash}
                            </a>
                          </span>
                        </td>
                        <TimeTD time={item.trade_time} interval={item.trade_time_interval} type={type} />
                        <td>
                          <a className="hash-tag text-truncate" href={`/address/${item.trade_from}#tokentxns`} target="_parent" data-toggle="tooltip" title="" data-original-title="{item.trade_from}">
                            {item.trade_from}
                          </a>
                        </td>
                        <td className="text-align">
                          <span className="btn btn-xs btn-icon btn-soft-success rounded-circle">
                            <i className="fas fa-long-arrow-alt-right btn-icon__inner"></i>
                          </span>
                        </td>
                        <td>
                          {item.trade_to_sign ? <i className="far fa-file-alt text-secondary mr-1" data-toggle="tooltip" title="" data-original-title="Contract"></i> : undefined}
                          <span style={{whiteSpace: 'nowrap'}}>
                            <a
                              className="hash-tag text-truncate"
                              href={`/address/${item.trade_to}#tokentxns`}
                              target="_parent"
                              data-toggle="tooltip"
                              title=""
                              data-original-title={`"Mdex: Heco Pool (${item.trade_to})`}
                            >
                              {item.trade_to}
                            </a>
                          </span>
                        </td>
                        <td>{item.value}</td>
                        <td>
                          <a href={`/token/${item.token_address}`}>
                            <img  src={item.token_image ? item.token_image : '/images/main/empty-token.png'} alt={item.symbol}  width="13px" />{' '}
                            <span data-toggle="tooltip" title="" data-original-title={item.token_name}>
                              <font color="">{item.token_name}</font>
                            </span>{' '}
                            ({item.token_symbol})
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <form method="post" action="./tokentxns" id="ctl00">
              <div className="d-md-flex justify-content-between my-3">
                <div id="ContentPlaceHolder1_pageRecords" className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                {t('tokentxns.tip5')}
                  <select onChange={handleChangePageSize} value={state.body.length} className="custom-select custom-select-xs mx-2">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  {t('tokentxns.tip6')}
                </div>
                <Pager path="/tokentxns" current={state.body.start} total={totalPage} />
              </div>
            </form>
            <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
              <div className="d-flex align-items-center text-secondary"></div>
              <div className="small text-muted"></div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
