const verifyContract = { 
    'verifyContract.StepOne.title1': 'Verify & Publish Contract Source Code',
    'verifyContract.StepOne.title2': 'COMPILER TYPE AND VERSION SELECTION',
    'verifyContract.StepOne.title3': 'Source code verification provides transparency for users interacting with smart contracts. By uploading the source code, HPB will match the compiled code with that on the blockchain. Just like contracts, a "smart contract" should provide end users with more information on what they are "digitally signing" for and give users an opportunity to audit the code to independently verify that it actually does what it is supposed to do.',
    'verifyContract.StepOne.lable1': 'Please enter the Contract Address you would like to verify', 
    'verifyContract.StepOne.lable2': 'Please select Compiler Type',
    'verifyContract.StepOne.lable3': 'Please select Compiler Version',    
    'verifyContract.StepOne.chkLable3': 'Un-Check to show all nightly Commits also',
    'verifyContract.StepOne.lable4': 'Please select Open Source License Type',
    'verifyContract.StepOne.chkLable4': 'I agree to the terms of service',
    'verifyContract.StepOne.btnContinue': 'Continue',
    'verifyContract.StepOne.btnReset': 'Reset',
    
    'verifyContract.StepOne.selected': '[Please Select]',
    'verifyContract.StepOne.selectSingle': 'Solidity (Single file)',
    'verifyContract.StepOne.selectMul': 'Solidity (Multi-Part files)',
    'verifyContract.StepOne.selectSta': 'Solidity (Standard-Json-Input)',


    'verifyContract.StepTwo.BasicInfo.ContractAddress': 'Contract Address ',
    'verifyContract.StepTwo.BasicInfo.Compiler': 'Compiler',
    'verifyContract.StepTwo.BasicInfo.Optimization': 'Optimization',
    'verifyContract.StepTwo.BasicInfo.Yes': 'Yes',
    'verifyContract.StepTwo.BasicInfo.No': 'No',

    'verifyContract.StepTwo.ConstructorArguments.Constructor': 'Constructor Arguments',
    'verifyContract.StepTwo.ConstructorArguments.tip1': 'for contracts that were created with constructor parameters',
    'verifyContract.StepTwo.ConstructorArguments.tip2': ' For additional information on Constructor Arguments',
    'verifyContract.StepTwo.ConstructorArguments.tip3': 'see Our KB Entry',

    'verifyContract.StepTwo.ContractLibraryAddress.tip1': 'Contract Library Address',
    'verifyContract.StepTwo.ContractLibraryAddress.tip2': 'for contracts that use libraries, supports up to 10 libraries',
    'verifyContract.StepTwo.ContractLibraryAddress.tip3': 'Note: Library names are case sensitive and affects the keccak library hash',
    'verifyContract.StepTwo.ContractLibraryAddress.tip4': ' Library_1 Contract Address:',


    'verifyContract.StepTwo.MiscSettings.tip1': 'Misc Settings',
    'verifyContract.StepTwo.MiscSettings.tip2': 'Runs, EvmVersion&License Type settings',
    'verifyContract.StepTwo.MiscSettings.tip3': 'Runs',
    'verifyContract.StepTwo.MiscSettings.tip4': '(Optimizer)',
    'verifyContract.StepTwo.MiscSettings.tip5': 'EVM Version to target',
    'verifyContract.StepTwo.MiscSettings.tip6': 'LicenseType',

    'verifyContract.StepTwo.SuccessResult.tip1': 'Compiler debug log',
    'verifyContract.StepTwo.SuccessResult.tip2': 'Note: Contract was created during TxHash',
    'verifyContract.StepTwo.SuccessResult.tip3': 'Successfully generated ByteCode and ABI for Contract Address ',

    'verifyContract.StepTwo.ErrorResult.tip1': 'Compiler debug log',
    'verifyContract.StepTwo.ErrorResult.tip2': 'For troubleshooting, you can try compiling your source code with the',
    'verifyContract.StepTwo.ErrorResult.tip3': ' Remix - Solidity IDE',
    'verifyContract.StepTwo.ErrorResult.tip4': '  and check for exceptions',
    'verifyContract.StepTwo.ErrorResult.tip5': '  Start Over',


    'verifyContract.StepTwo.tip1': 'Verify & Publish Contract Source Code ',
    'verifyContract.StepTwo.tip2': 'Compiler Type: SINGLE FILE / CONCATENANTED METHOD',
    'verifyContract.StepTwo.tip3': 'Info',
    'verifyContract.StepTwo.tip4': ' A simple and structured interface for verifying smart contracts that fit in a single file',
    'verifyContract.StepTwo.tip5': 'Contract Source Code',
    'verifyContract.StepTwo.tip6': 'Compiler Output',
    'verifyContract.StepTwo.tip7': '... Processing, this can take up to 30 seconds ...',

    'verifyContract.StepTwo.tip8': '1. If the contract compiles correctly at',
    'verifyContract.StepTwo.tip9': 'REMIX',
    'verifyContract.StepTwo.tip10': ', it should also compile correctly here.',
    'verifyContract.StepTwo.tip11': '2. We have limited support for verifying contracts created by another contract and there is a timeout of up to 45 seconds for each contract compiled. ',
    'verifyContract.StepTwo.tip12': '3. For programatic contract verification, check out the  Contract API Endpoint  ',
    'verifyContract.StepTwo.tip13': 'Please select the Solidity (*.sol) files for upload ',
    'verifyContract.StepTwo.tip14': 'Step 1',
    'verifyContract.StepTwo.tip15': 'Step 2',
    'verifyContract.StepTwo.tip16': 'Enter the Solidity Contract Code below',
    'verifyContract.StepTwo.tip17': 'Verify and Publish',
    'verifyContract.StepTwo.tip18': 'Reset',
    'verifyContract.StepTwo.tip19': 'Return to Main',
    'verifyContract.StepTwo.tip20': 'Click to Upload selected files',



 

};

export default verifyContract;
