import {useState} from 'react';
import Copy from '../../components/Copy';
import {useTranslation} from 'react-i18next';
let web3 = undefined; 
export default function Unitconverter() { 
  const {t} = useTranslation(['unitConverter']);
/*   const [unit, setUnit] = useState({
    wei: '1000000000000000000',
    kwei: '1000000000000000',
    mwei:'1000000000000',
    GWei: '1000000000',
    Szabo: '1000000',
    Finney: '1000',
    Hpb: '1',
    KHpb: '0.001',
    MHpb: '0.000001',
    GHpb: '0.000000001',
    THpb: '0.000000000001' 
  });  */
  const [wei,setWei] =  useState('1000000000000000000');
  const [kwei,setKwei] =  useState('1000000000000000');
  const [mwei,setMwei] =  useState('1000000000000');
  const [gWei,seGWei] =  useState('1000000000');
  const [szabo,setSzabo] =  useState('1000000');
  const [finney,setFinney] =  useState('1000');
  const [Hpb,setHpb] =  useState('1');
  const [kHpb,setKHpb] =  useState('0.001');
  const [mHpb,setMHpb] =  useState('0.000001');
  const [gHpb,setGHpb] =  useState('0.000000001');
  const [tHpb,setTHpb] =  useState('0.000000000001');

  web3 = new window.Web3(window.ethereum); 

  const handleInputChange = field => e =>{ 
    debugger
    const value = e.target.value; 

    switch (field) {
      case "wei":
        setWei(value);
        break; 
      case "kwei":
        setKwei(value);
        break;
      case "mwei":
        setMwei(value);
        break;
      case "gWei":
        seGWei(value);
        break;
      case "szabo":
        setSzabo(value);
        break;
      case "finney":
        setFinney(value);
        break;
      case "Hpb":
        setHpb(value);
        break;
      case "KHpb":
        setKHpb(value);
        break;
      case "MHpb":
        setMHpb(value);
        break;
      case "GHpb":
        setGHpb(value);
        break;
      case "THpb":
        setTHpb(value);
        break;
      default:
        break;

    }

    var patrn = /^(-)?\d+(\.\d+)?$/;
    if (patrn.exec(value) === null || value === "") {
        return false
    }

    // setUnit({...unit, [field]: value})

    var weiValue = web3.utils.toWei(value, field); 
    setWei(weiValue);

    let kWeiValue = web3.utils.fromWei(weiValue,'kwei'); 
    setKwei(kWeiValue)

    let mWeiValue = web3.utils.fromWei(weiValue,'mwei'); 
    setMwei(mWeiValue)

    let gweiValue = web3.utils.fromWei(weiValue,'gwei'); 
    seGWei(gweiValue)

    let szaboValue = web3.utils.fromWei(weiValue,'Szabo'); 
    setSzabo(szaboValue) 

    let finneyValue = web3.utils.fromWei(weiValue,'Finney'); 
    setFinney(finneyValue)

    let HpbValue = web3.utils.fromWei(weiValue,'Ether'); 
    setHpb(HpbValue)

    let kHpbValue = web3.utils.fromWei(weiValue,'KEther'); 
    setKHpb(kHpbValue)

    let mHpbValue = web3.utils.fromWei(weiValue,'MEther'); 
    setMHpb(mHpbValue)


    let gHpbValue = web3.utils.fromWei(weiValue,'GEther'); 
    setGHpb(gHpbValue)

    let tHpbValue = web3.utils.fromWei(weiValue,'TEther'); 
    setTHpb(tHpbValue)
 
 
 
  } 
  
  return (
    <main role="main">
         <div className="container mb-4">
            <div className="border-bottom py-3 mb-3">
              <div className="mb-2 mb-sm-0">
                <h1 className="h4 d-flex mb-0">
                <figure className="u-sm-avatar mr-2">
                  <img src="../images/svg/icons/calc.svg" alt="SVG" width="28px" />
                </figure>
                <span>{t('unitConverter.title')}</span>
                </h1>
              </div>
            </div>
              <p>
              {t('unitConverter.tip1')}
              </p>
          </div>
          <div className="container space-bottom-2">
            <div className="card">
              <div className="card-body">
                
                <div className="d-flex justify-content-end mb-3">
                  <a id="btnReset" href="/unitconverter">{t('unitConverter.tip2')}</a>
                </div>

                {/* wei */}
                <div className="input-group mb-3 mt-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text"> 
                      <Copy text={wei} title="Copy to clipboard" />
                    </span>
                  </div>
                  <input name="wei" type="text" id="wei" className="form-control convertedValue"   
                    placeholder="Wei" aria-label="wei"  value={wei} autoComplete="off"  onChange= {handleInputChange('wei')}   />
                  <div style={{width:'110px'}}>
                    <span className="input-group-text" style={{borderTopLeftRadius:0,borderBottomLeftRadius:0}}>Wei(10<sup>-18</sup>)</span>
                  </div>
                </div>

                {/* kwei */}
                <div className="input-group mb-3 mt-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text"> 
                      <Copy text={kwei} title="Copy to clipboard" />
                    </span>
                  </div>
                  <input name="kwei" type="text" id="kwei" className="form-control convertedValue"  autoComplete="off" 
                    placeholder="KWei"  value={kwei}  onChange ={handleInputChange('kwei')}   />
                  <div style={{width:'110px'}}>
                    <span className="input-group-text" style={{borderTopLeftRadius:0,borderBottomLeftRadius:0}}>KWei(10<sup>-15</sup>)</span>
                  </div>
                </div>
 

                {/* MWei */}
                <div className="input-group mb-3 mt-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text"> 
                      <Copy text={mwei} title="Copy to clipboard" />
                    </span>
                  </div>
                  <input name="mwei" type="text" id="mwei" className="form-control convertedValue" autoComplete="off" 
                    placeholder="MWei"  value={mwei}  onChange ={handleInputChange('mwei')}   />
                  <div style={{width:'110px'}}>
                    <span className="input-group-text" style={{borderTopLeftRadius:0,borderBottomLeftRadius:0}}>MWei(10<sup>-12</sup>)</span>
                  </div>
                </div>
 
                {/* Gwei */}
                <div className="input-group mb-3 mt-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text"> 
                      <Copy text={gWei} title="Copy to clipboard" />
                    </span>
                  </div>
                  <input name="gWei" type="text" id="gWei" className="form-control convertedValue" autoComplete="off" 
                    placeholder="GWei"  value={gWei}  onChange ={handleInputChange('gWei')}   />
                  <div style={{width:'110px'}}>
                    <span className="input-group-text" style={{borderTopLeftRadius:0,borderBottomLeftRadius:0}}>GWei(10<sup>-9</sup>)</span>
                  </div>
                </div>
 
                {/* Szabo */}
                <div className="input-group mb-3 mt-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text"> 
                      <Copy text={szabo} title="Copy to clipboard" />
                    </span>
                  </div>
                  <input name="Szabo" type="text" id="Szabo" className="form-control convertedValue"  autoComplete="off" 
                    placeholder="Szabo"  value={szabo}  onChange ={handleInputChange('szabo')}   />
                 <div style={{width:'110px'}}>
                    <span className="input-group-text" style={{borderTopLeftRadius:0,borderBottomLeftRadius:0}}>Szabo(10<sup>-6</sup>)</span>
                  </div>
                </div>
 
               {/* Finney */}
                <div className="input-group mb-3 mt-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text"> 
                      <Copy text={finney} title="Copy to clipboard" />
                    </span>
                  </div>
                  <input name="finney" type="text" id="finney" className="form-control convertedValue" autoComplete="off" 
                    placeholder="Finney"  value={finney}  onChange ={handleInputChange('finney')}   />
                  <div style={{width:'110px'}}>
                    <span className="input-group-text" style={{borderTopLeftRadius:0,borderBottomLeftRadius:0}}>Finney(10<sup>-3</sup>)</span>
                  </div>
                </div>
 



               {/* Hpb  */}
                <div className="input-group mb-3 mt-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text"> 
                      <Copy text={Hpb} title="Copy to clipboard" />
                    </span>
                  </div>
                  <input name="Hpb" type="text" id="Hpb" className="form-control convertedValue" autoComplete="off" 
                    placeholder="Hpb"  value={Hpb}  onChange ={handleInputChange('Ether')}   />
                 <div style={{width:'110px'}}>
                    <span className="input-group-text" style={{borderTopLeftRadius:0,borderBottomLeftRadius:0}}>Hpb(1)</span>
                  </div>
                </div>
 

                {/* KHpb */}
                <div className="input-group mb-3 mt-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text"> 
                      <Copy text={kHpb} title="Copy to clipboard" />
                    </span>
                  </div>
                  <input name="kHpb" type="text" id="kHpb" className="form-control convertedValue" autoComplete="off" 
                    placeholder="kHpb"  value={kHpb}  onChange ={handleInputChange('KEther')}   />
                  <div style={{width:'110px'}}>
                    <span className="input-group-text" style={{borderTopLeftRadius:0,borderBottomLeftRadius:0}}>KHpb(10<sup>3</sup>)</span>
                  </div>
                </div>
 
                {/* MHpb  */}
                <div className="input-group mb-3 mt-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text"> 
                      <Copy text={mHpb} title="Copy to clipboard" />
                    </span>
                  </div>
                  <input name="mHpb" type="text" id="mHpb" className="form-control convertedValue"  autoComplete="off" 
                    placeholder="MHpb"  value={mHpb}  onChange ={handleInputChange('MEther')}   />
                  <div style={{width:'110px'}}>
                    <span className="input-group-text" style={{borderTopLeftRadius:0,borderBottomLeftRadius:0}}>MHpb(10<sup>6</sup>)</span>
                  </div>
                </div>

                 {/* GHpb  */}
                <div className="input-group mb-3 mt-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text"> 
                      <Copy text={gHpb} title="Copy to clipboard" />
                    </span>
                  </div>
                  <input name="gHpb" type="text" id="gHpb" className="form-control convertedValue" autoComplete="off" 
                    placeholder="GHpb"  value={gHpb}  onChange ={handleInputChange('GEther')}   />
                  <div style={{width:'110px'}}>
                    <span className="input-group-text" style={{borderTopLeftRadius:0,borderBottomLeftRadius:0}}>GHpb(10<sup>9</sup>)</span>
                  </div>
                </div>
              
              {/* THpb   */}
                <div className="input-group mb-3 mt-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text"> 
                      <Copy text={tHpb} title="Copy to clipboard" />
                    </span>
                  </div>
                  <input name="tHpb" type="text" id="tHpb" className="form-control convertedValue" autoComplete="off" 
                    placeholder="THpb"  value={tHpb}  onChange ={handleInputChange('TEther')}   />
                  <div style={{width:'110px'}}>
                    <span className="input-group-text" style={{borderTopLeftRadius:0,borderBottomLeftRadius:0}}>THpb(10<sup>12</sup>)</span>
                  </div>
                </div>
 


              </div>
            </div>
          </div>
     </main>
  ); 
  }
  