import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import LinkWithTooltip from '../../components/LinkWithTooltip';
import Pager from '../../components/Pager';
import Loading from '../../components/Loading';
import TimeTD from '../../components/TimeTD';
import {HexToDec} from '../../commons/utils';
export default function TokentxnsNft() {
  const location = useLocation();
  const {t} = useTranslation(['tokentxnsNft']);


  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      length: window.localStorage.getItem('pageLength') || '50',
    },
  });
  const [type, setType] = useState('showAge');   
  const handleChangePageSize = e => {
    window.localStorage.setItem('pageLength', e.target.value);
    setState({...state, body: {...state.body, length: e.target.value}});
  };

  const tokenTradeNftListRequest = useRequest(
    body => ({
      url: '/blockBrowser/tokens/tokenTradeNft/tokenTradeNftList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    tokenTradeNftListRequest.run(state.body);
  }, [state]);

  if (tokenTradeNftListRequest.loading) {
    return <Loading />;
  }

  const data = tokenTradeNftListRequest.data?.token_trade_list || [];
  const counts = tokenTradeNftListRequest.data?.counts || 0;
  const token_trade_count = tokenTradeNftListRequest.data?.token_trade_count || 0;
  const totalPage = Math.ceil(Number(token_trade_count) / state.body.length);

  return (
    <main role="main">
      <div className="bg-light py-3">
        <div className="container">
          <h1 className="h4 mb-0">
            <div className="d-flex align-items-center">
              {t('tokentxnsNft.title')}<span className="u-label u-label--xs u-label--secondary rounded color-strong ml-2">HRC-721</span>
            </div>
            <p className="mb-0 text-break small">
              <span className="small text-secondary"></span>
            </p>
          </h1>
        </div>
      </div>

      <div className="container space-bottom-2">
        <div className="card">
          <div className="card-body">
            <div className="d-md-flex justify-content-between mb-4">
              <div className="hidden-xs-down">
                <p className="mb-2 mb-md-0">
                  <i className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i>  {t('tokentxnsNft.tip1')} &gt;= {counts} {t('tokentxnsNft.tip2')}
                  <span className="d-block text-secondary small">({t('tokentxnsNft.tip3')} {token_trade_count} {t('tokentxnsNft.tip4')})</span>
                </p>
              </div>
              <Pager path="/tokentxns-nft" current={state.body.start} total={totalPage} />
            </div>
            <div className="table-responsive mb-2 mb-md-0">
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col" width="20"></th>
                    <th scope="col">{t('tokentxnsNft.table.txnHash')}</th>
                    <th scope="col">
                      {
                      (type ==='showAge')?
                      <LinkWithTooltip placement="bottom" tooltip={t('tokentxnsNft.age.tip1')}>
                        <a href="#!" title="" onClick={e => {  e.preventDefault(); setType('showDate'); }} >
                        {t('tokentxnsNft.table.age')}
                        </a>
                      </LinkWithTooltip>
                      :
                      <LinkWithTooltip placement="bottom" tooltip={t('tokentxnsNft.age.tip2')}> 
                        <a href="#!" onClick={e => {  e.preventDefault(); setType('showAge'); }}  >
                        {t('tokentxnsNft.table.time')}
                        </a>
                      </LinkWithTooltip>
                      }

                    </th>
                    <th scope="col">{t('tokentxnsNft.table.from')}</th>
                    <th scope="col" width="30"></th>
                    <th scope="col">{t('tokentxnsNft.table.to')}</th>
                    <th scope="col">{t('tokentxnsNft.table.tokenID')}</th>
                    <th scope="col">{t('tokentxnsNft.table.token')}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <a role="button" tabIndex="0" type="button" className="js-txnAdditional-1 btn btn-xs btn-icon btn-soft-secondary myFnExpandBox">
                            <i className="far fa-eye btn-icon__inner"></i>
                          </a>
                        </td>
                        <td>
                          <span className="hash-tag text-truncate">
                            <a href={`/tx/${item.trade_hash}`} className="myFnExpandBox_searchVal">
                              {item.trade_hash}
                            </a>
                          </span>
                        </td>
                        <TimeTD time={item.trade_time} interval={item.trade_time_interval}  type={type} />
                        <td>
                        <LinkWithTooltip placement="top" tooltip={item.trade_from}>
                          <a  className="hash-tag text-truncate"   href={`/address/${item.trade_from}#tokentxnsErc721`} target="_parent">{item.trade_from} </a>
                          </LinkWithTooltip>
                        </td>
                        <td className="text-center">
                          <span className="btn btn-xs btn-icon btn-soft-success rounded-circle">
                            <i className="fas fa-long-arrow-alt-right btn-icon__inner"></i>
                          </span>
                        </td>
                        <td>
                        <LinkWithTooltip placement="top" tooltip={item.trade_to}>
                          <a className="hash-tag text-truncate" href={`/address/${item.trade_to}#tokentxnsErc721`} target="_parent" data-toggle="tooltip" title="" data-original-title={item.trade_to}>
                            {item.trade_to}
                          </a>
                          </LinkWithTooltip>
                        </td>
                        <td>
                        <LinkWithTooltip placement="top" tooltip={ HexToDec(item.token_id)}>
                          <a href={`/token/${item.token_id}?a=${item.token_id}`} className="hash-tag text-truncate">
                            {HexToDec(item.token_id)}
                          </a>
                          </LinkWithTooltip>
                        </td>
                        <td>
                        <LinkWithTooltip placement="top" tooltip= {item.token_name} >
                          <a href={`/token/${item.token_address}`}>
                          <img src="/images/main/empty-token.png" width="13px" /> 
                               {item.token_name} ({item.token_symbol}) 
                          </a>
                          </LinkWithTooltip>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <form method="post" action="./tokentxns-nft">
              <div className="d-md-flex justify-content-between my-3">
                <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                {t('tokentxnsNft.tip5')}
                  <select onChange={handleChangePageSize} className="custom-select custom-select-xs mx-2" value={state.body.length}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  {t('tokentxnsNft.tip6')}
                </div>
                <Pager path="/tokentxns-nft" current={state.body.start} total={totalPage} />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center text-secondary"></div>
                <div className="small text-muted"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
}
