import moment from 'moment';
import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation, useParams} from 'react-router-dom';

import Loading from '../../components/Loading';
import Copy from '../../components/Copy';
import React from 'react'; 
import LinkWithTooltip from '../../components/LinkWithTooltip';
import {useTranslation} from 'react-i18next';


function BlockMiner({data}) { 
  
  const {t} = useTranslation(['blocksRewardsDetail']); 

  const [copied, setCopied] = useState(false);
  return (
    <div className="row align-items-center mt-1">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0"> 
       {t('blocksRewardsDetail.BlockMiner')}:
      </div>
      <div className="col-md-9">
      
        <LinkWithTooltip placement="bottom" tooltip={`${data.miner}`}>  
          <span className="mr-1">
            <a className="hash-tag text-truncate" href={`/address/${data.miner}`} 
                data-toggle="tooltip" data-placement="bottom" title="" data-original-title={data.miner}>
                {data.miner}
            </a>
            </span>
        </LinkWithTooltip>
       
        <Copy text={data.miner} title="Copy block miner  to clipboard" />
      </div>
    </div>
  );
}

function BlockReward({data}) { 
  
  const {t} = useTranslation(['blocksRewardsDetail']); 
  return (
    <div className="row align-items-center mt-1">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0"> 
        {t('blocksRewardsDetail.BlockReward')}:
      </div>
      <div className="col-md-9">
        <span className="u-label u-label--value u-label--secondary text-dark rounded mr-1">{data.block_reward} HPB</span> 
        <span  className=" ml-1">
            65%({t('blocksRewardsDetail.hpbNode')} +{t('blocksRewardsDetail.cadNode')}) +  35%({t('blocksRewardsDetail.voteReward')})
        </span>
        
      </div>
    </div>
  );
} 

function BlockNo({data}) {
  const {t} = useTranslation(['blocksRewardsDetail']); 
  return (
    <div className="row align-items-center">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0"> 
        {t('blocksRewardsDetail.Block')}:
      </div>
      <div className="col-md-9">
        <a href={`/block/${data.block_no}`}>{data.block_no}</a>
       
      </div>
    </div>
  );
}

function TimeStamp({data}) {
  const {t} = useTranslation(['blocksRewardsDetail']); 
  const now = new Date().getTime();
  const timeInterval = moment(now - Number(data.block_time_interval) * 1000)
    .startOf('minute')
    .fromNow();
  return (
    <div className="row align-items-center">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0"> 
        {t('blocksRewardsDetail.Timestamp')}:
      </div>
      <div className="col-md-9">
        <span></span>
        <i className="far fa-clock small mr-1"></i>
        <span>{timeInterval}</span>
        <span> ({ data.time_stamp })</span>
      </div>
    </div>
  );
}  


function HPBNodeList({data}) {
  const {t} = useTranslation(['blocksRewardsDetail']); 
  const leg =data.hpb_node_list?.length||0;
  const flag = (leg ===0) ?false :true
  return (
    <div className="row">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <div className="d-flex align-items-center">
          <span className="mr-1" title={` HPB Node List`}> 
           {t('blocksRewardsDetail.hpbNodeList')}:
          </span>
          {leg > 1 ? <span className="badge badge-pill badge-secondary align-midle">{leg}</span> : undefined}
        </div>
      </div>
      <div className="col-md-9">
        <ul className="list-unstyled mb-0">
          {flag?React.Children.toArray(
            data.hpb_node_list.map((item, i) => {
              return (
                <li className={`media align-items-baseline ${i === leg - 1 ? 'mb-0' : 'mb-2'}`}>
                  <span className="row-count text-secondary small mr-1">
                    <i className="fa fa-caret-right"></i>
                  </span>
                  <div className="media-body">
                    <span className="">
                      <b>{t('blocksRewardsDetail.Address')}</b>{' '}
                    </span>
                    <span className="hash-tag text-truncate mr-1"  >
                      <a href={`/address/${item.addr}`}>
                        <span className="hash-tag text-truncate hash-tag-custom-from tooltip-address"   data-toggle="tooltip" title="" data-original-title={item}>
                          {item.addr}
                        </span>
                      </a>
                    </span> 
                    
                    <span className="mr-1">
                        <b>{t('blocksRewardsDetail.hpbReward')}:</b>{' '}
                      </span> 
                      <span className="mr-1">
                        <span data-toggle="tooltip" >
                          {item.hpb_reward}  
                        </span>
                      </span>
                     
                      <span>
                          <span className="mr-1">
                          <b> {t('blocksRewardsDetail.voteReward')}:</b>{' '}
                          </span> 
                          <span className="mr-1">
                            <span data-toggle="tooltip">
                              {item.vote_reward}  
                            </span>
                          </span> 
                      </span> 
                      

                  </div>
                </li>
              );
            }),
          ):''}
        </ul>
      </div>
    </div>
  );
}

function CadNodeList({data}) {  
  const {t} = useTranslation(['blocksRewardsDetail']); 
    const leg =data.cad_node_list?.length ||0;
    const flag = (leg ===0) ?false :true
    return (
      <div className="row">
        <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
          <div className="d-flex align-items-center">
            <span className="mr-1" title={` HPB Node List`}> 
             {t('blocksRewardsDetail.cadNodeList')}:
            </span>
            {leg > 1 ? <span className="badge badge-pill badge-secondary align-midle">{leg}</span> : undefined}
          </div>
        </div>
        <div className="col-md-9">
          <ul className="list-unstyled mb-0">
            {flag?React.Children.toArray(
              data.cad_node_list.map((item, i) => {
                return (
                  <li className={`media align-items-baseline ${i === leg - 1 ? 'mb-0' : 'mb-2'}`}>
                    <span className="row-count text-secondary small mr-1">
                      <i className="fa fa-caret-right"></i>
                    </span>
                    <div className="media-body">
                      <span className="mr-1">
                        <b>{t('blocksRewardsDetail.Address')}</b>{' '}
                      </span> 
                      <span className="hash-tag text-truncate mr-1" >
                        <a href={`/address/${item.addr}`}>
                          <span className="hash-tag text-truncate hash-tag-custom-from tooltip-address" data-toggle="tooltip" title="" data-original-title={item}>
                            {item.addr}
                          </span>
                        </a>
                      </span> 
                      <span className="mr-1">
                        <b>{t("blocksRewardsDetail.CadReward")}:</b>{' '}
                      </span> 
                      <span className="mr-1">
                        <span data-toggle="tooltip" >
                          {item.cad_reward}  
                        </span>
                      </span> 
                      <span>
                          <span className="mr-1">
                          <b> {t("blocksRewardsDetail.voteReward")} :</b>{' '}
                          </span> 
                          <span className="mr-1">
                            <span data-toggle="tooltip">
                              {item.vote_reward}  
                            </span>
                          </span> 
                      </span>
 

  
                    </div>
                  </li>
                );
              }),
            ):''}
          </ul>
        </div>
      </div>
    );
}

  
 
function VoteReward({data}) {
  const {t} = useTranslation(['blocksRewardsDetail']); 
  return (
    <div className="row align-items-center mn-3">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">  
         {t('blocksRewardsDetail.voteReward')}:
      </div>
      <div className="col-md-9">
        <span>
          <span className="u-label u-label--value u-label--secondary text-dark rounded mr-1" > 
            <a href={`https://vote.hpbnode.com/votedetail?boeAddr=${data.miner}&field=tabBonus`} target="_blank">
              {data.vote_reward} HPB 
            </a>  
          </span>
        </span>
      </div>
    </div>
  );
} 

function HpbNodeReward({data}) {
  const {t} = useTranslation(['blocksRewardsDetail']); 
  return (
    <div className="row align-items-center mn-3">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">  
         {t('blocksRewardsDetail.hpbNode')}:
      </div>
      <div className="col-md-9">
        <span>
          <span data-toggle="tooltip" title="" data-original-title="The amount of HPB to be transferred to the recipient with the transaction">
            <span className="u-label u-label--value u-label--secondary text-dark rounded mr-1">{data?.hpb_node_reward||0} HPB</span>
 
          </span>
        </span>
      </div>
    </div>
  );
} 
 
function CadNodeReward({data}) {
  const {t} = useTranslation(['blocksRewardsDetail']); 
  return (
    <div className="row align-items-center mn-3">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0"> 
         {t('blocksRewardsDetail.cadNode')}:
      </div>
      <div className="col-md-9">
        <span>
          <span data-toggle="tooltip" title="" data-original-title="The amount of HPB to be transferred to the recipient with the transaction">
            <span className="u-label u-label--value u-label--secondary text-dark rounded mr-1">{data.cad_node_reward} HPB</span>
          </span>
        </span>
      </div>
    </div>
  );
}

 
 



function TradeDetail({data}) { 
  const {t} = useTranslation(['blocksRewardsDetail']); 
  const [showMore, setShowMore] = useState(false);
  const defaultLNG = window.localStorage.getItem('lng') || 'en_US';
  moment.locale(defaultLNG);
  
  return (
    <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="home-tab">
      <div className="card-body">

     
        <BlockNo data={data} />
        <hr className="hr-space" />

        <BlockMiner data={data} />
        <hr className="hr-space" />  

        <TimeStamp data={data} />
        <hr className="hr-space" />


        <BlockReward data={data} />
        <hr className="hr-space" /> 

        <HpbNodeReward data={data} />
        <hr className="hr-space" />

        <CadNodeReward data={data} />
        <hr className="hr-space" />  

        <VoteReward data={data} />
        <hr className="hr-space" />

        {/* <HpbNodePerReward data={data} />
        <hr className="hr-space" /> */} 
        

        {/* <CadNodePerReward data={data} /> 
        <hr className="hr-space" /> */}
       


        <div className={`collapse ${showMore ? 'show' : ''}`}>
           
          <HPBNodeList data={data} />
          <hr className="hr-space" />
          <CadNodeList data={data} />
          <hr className="hr-space" /> 

        </div>
        <span>
          <a
            className={`d-block ${showMore ? '' : 'collapsed'}`}
            href="!#"
            data-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="ContentPlaceHolder1_collapseContent"
            onClick={() => {
              setShowMore(!showMore);
            }}
          >
            <span>
              {showMore ? <span className="card-arrow-less">{t('blocksRewardsDetail.tip10')}</span> : <span className="card-arrow-more">{t('blocksRewardsDetail.tip11')}</span>}
              <span className="card-btn-arrow card-btn-arrow--up ml-2">
                <span className="fas fa-arrow-up small"></span>
              </span>
            </span>
          </a>
      </span>  
      </div>
    </div>
  );
}
 
function TradeDetailRemarks(){
  const {t} = useTranslation(['blocksRewardsDetail']); 
  return (
    <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="home-tab">
       <div className="mt-3 ml-3  mb-1 mb-md-0 font-weight-bold" style={{fontSize:'1rem'}}>
            <span >1.{t('blocksRewardsDetail.tip1')}  </span>
        </div> 
        <div className="mt-3 ml-3 text-lh-md" style={{fontSize:'1rem',textIndent:'20px'}} >
          {t('blocksRewardsDetail.tip2')}
        </div>
        <div className="mt-3 ml-3  mb-1 mb-md-0 font-weight-bold" style={{fontSize:'1rem'}}>
            <span >2.{t('blocksRewardsDetail.tip3')} </span>
        </div> 
        <div className="mt-3 ml-3 text-lh-md" style={{fontSize:'1rem',textIndent:'20px'}} >
          {t('blocksRewardsDetail.tip4')}
        </div>
        <div className="mt-3 ml-3 text-center">
          <img src="/images/reward.png" alt="" width="700px" />
        </div>
        <div className="mt-3 ml-3  mb-1 mb-md-0 font-weight-bold" style={{fontSize:'1rem'}}>
            <span >3. {t('blocksRewardsDetail.tip5')}</span>
        </div> 
        <div className="mt-3 ml-3 text-lh-md" style={{fontSize:'1rem',textIndent:'20px'}} >
              1. {t('blocksRewardsDetail.tip6')} 
        </div> 
        <div className="mt-3 ml-3 text-lh-md" style={{fontSize:'1rem',textIndent:'20px'}} >
            2. {t('blocksRewardsDetail.tip7')}
                
        </div> 
        <div className="mt-3 ml-3 text-lh-md" style={{fontSize:'1rem',textIndent:'20px'}} >
            3. {t('blocksRewardsDetail.tip8')}
             
        </div> 
         <div className="mt-3 ml-3 text-lh-md" style={{fontSize:'1rem',textIndent:'20px'}} >
            4. {t('blocksRewardsDetail.tip9')} 
        </div> 
    </div>
  );
}
 

export default function BlocksRewardsDetail() {
  const {t} = useTranslation(['blocksRewardsDetail']); 
  const {block} = useParams(); 
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(location.hash.slice(1) || 'home'); // home, internal, eventlog
  
  const [state, setState] = useState({
    body: {
      field: 'block_no', value: block
    },
  }); 

  const blocksRequest = useRequest(
    body => ({
      url: '/blockBrowser/blockChain/block/blockRewardDetail',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    blocksRequest.run(state.body);
  }, [state]);


  if (blocksRequest.loading) {
    return <Loading />;
  } 
  return (
    <main role="main">
      <div className="container py-3 mn-b3">
        <div className="d-lg-flex justify-content-between align-items-center">
          <div className="mb-3 mb-lg-0">
            <h1 className="h4 mb-0">{t('blocksRewardsDetail.title')} </h1>
          </div>
          <div className="d-flex flex-wrap mt-1 mt-md-0 flex-wrap ml-md-auto"></div>
        </div>
      </div>
      <div className="container space-bottom-2">
        <div className="card">
          <div className="card-header sticky-card-header d-flex justify-content-between p-0">
            <ul className="nav nav-custom nav-borderless nav_tabs1" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-link ${currentTab === 'home' ? 'active' : ''}`}
                  data-toggle="tab"
                  href="#home"
                  aria-controls="home"
                  aria-selected="true"
                  onClick={() => {
                    setCurrentTab('home');
                  }}
                >
                  {t('blocksRewardsDetail.Overview')}
                </a>
              </li> 
              <li className="nav-item">
                  <a
                    className={`nav-link ${currentTab === 'remarks' ? 'active' : ''}`}
                    data-toggle="tab"
                    href="#remarks"
                    aria-controls="remarks"
                    aria-selected="false"
                    onClick={() => {
                      setCurrentTab('remarks');
                    }}
                  >
                      {t('blocksRewardsDetail.Illustration')}
                  </a>
                </li>
            </ul>
            <div className="d-flex mr-3 mt-3">
              <div style={{display: 'none'}}>
                <img src="/images/main/EllipsisSpinner3.svg?v=0.0.1" />
              </div> 

            </div>
          </div>
          {blocksRequest.data ? (
            <div className="tab-content">
              {currentTab === 'home' ? <TradeDetail data={blocksRequest.data} /> : undefined} 
              {currentTab === 'remarks' ? <TradeDetailRemarks /> : undefined}
            </div>
          )  :""}
        </div>
      </div>
    </main>
  );
}
