import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';
import LinkWithTooltip from '../../components/LinkWithTooltip';
import Loading from '../../components/Loading';
import {createComprisonFunctionAsc,createComprisonFunctionDesc} from '../../commons/utils'

import {useTranslation} from 'react-i18next';

export default function NodeStatus() {
  const {t} = useTranslation(['nodestatus']);
  const location = useLocation(); 
  const query = qs.parse(location.search, {ignoreQueryPrefix: true}); 

  const [sortBy, setSortBy] = useState('asc'); 
  const [sortType, setSortType] = useState('vote'); 
  const [queryData, setQueryData] = useState([]); 


  const [nodetype, setNodetype] = useState(query.nodetype||'');   
  const [nodeStatus, setNodeStatus] = useState(query.nodestatus||'');   
  const goNodeType =()=>{  
    const url = `/nodestatus${qs.stringify({...query, nodetype:nodetype}, {addQueryPrefix: true})}`; 
    window.location.href = url 
  }  
  
  const goNodeStatus =()=>{  
    const url = `/nodestatus${qs.stringify({...query, status:nodeStatus}, {addQueryPrefix: true})}`; 
    window.location.href = url 
  }
  const goClearNoteType =()=>{
    //删除指定的key
    delete query['nodetype']
    const url = `/nodestatus${qs.stringify({...query}, {addQueryPrefix: true})}`; 
    window.location.href = url 
  }  

  const goClearNodeStatus =()=>{
    //删除指定的key
    delete query['status']
    const url = `/nodestatus${qs.stringify({...query}, {addQueryPrefix: true})}`; 
    window.location.href = url 
  }  

  const [state, setState] = useState({
    isLimitPages: Number(query.p || 1) > 10000,
    body: {
      start: query.p || '1',
      length:  window.localStorage.getItem('pageLength') || '50',
      ...query
    },
  });   
 
  
  const handleSortData = (e,data,type,sort) => { 
    e.preventDefault();  
    if(sort === 'asc'){
      data.nodeinfos.sort(createComprisonFunctionAsc(type));
      setSortBy('desc');
      setSortType(type);
    }else if (sort==='desc'){ 
      data.nodeinfos.sort(createComprisonFunctionDesc(type));
      setSortBy('asc');
      setSortType(type);
    } else{
      setQueryData(data);
    }
 
   
  }

  const accountListRequest = useRequest(
    body => ({
      url: '/nodemonitor/api/nodeinfo',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    accountListRequest.run(state.body).then(res => { 
      setQueryData(res);
    });

  }, [state]);

  // useEffect(() => {
  //   if (!state.isLimitPages) {
      
  //     accountListRequest.run(state.body);
  //   }
  // }, [state]);

  if (state.isLimitPages) {
    return window.location.replace('/error');
  }

  if (accountListRequest.loading) {
    return <Loading />;
  }

  const data = queryData?.nodeinfos || [];
  const counts = data.length || 0;  
  const hpcount = queryData?.hpcount ||0 ;
  const hpnotmining = queryData?.hpnotmining ||0 ;
  const hpoffline = queryData?.hpoffline ||0 ;
  return (
    <main role="main">
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center py-3">
          <div className="mb-1 mb-md-0">
            <h1 className="h4 font-weight-normal mb-0">
              {t('nodestatus.title')}&nbsp;<span className="small text-secondary"></span>
            </h1>
          </div>
        </div>
      </div>

      <div className="container space-bottom-2">
        <div className="card">
          <div className="card-body"> 
          

          <div className="d-md-flex align-items-center mb-3">
            <p className="mr-2 mb-2" style={{fontSize:'16px'}}>
              <i className="fas fa-sort-amount-down text-secondary" data-toggle="tooltip" title="" data-original-title="Oldest First"></i>
              {t('nodestatus.hpNodes')}  :{hpcount}&nbsp; &nbsp; 
              {t('nodestatus.offHpNodes')}:{hpoffline}&nbsp;&nbsp; {t('nodestatus.notOffHpNodes')} :{hpnotmining} &nbsp;
            </p> 
          </div>


            <div className="table-responsive mb-2 mb-md-0">
              <table className="table table-hover" style={{width: '100%',minHeight:'200px'}}>
                <thead className="thead-light">
                  <tr>
                    <th width="40" scope="col">
                      {t('nodestatus.table.Rank')}
                    </th> 
                    <th  scope="col">                     
                      <div className="">
                          <strong> 
                              {(sortType ==='name')?( sortBy==='asc'?<i className="fa fa-angle-down"></i>:<i className="fa fa-angle-up"></i>) :''} 
                          </strong>
                          &nbsp;
                          <a href="/#" data-toggle="tooltip"   onClick={((e) => handleSortData(e,queryData,'name',sortBy))}    data-original-title="Click for descending sort">
                          {t('nodestatus.table.nodeName')}
                          </a> 
                      </div>
                    </th>                     
                     <th   scope="col"> 
                      <div className="">
                          <strong> 
                              {(sortType ==='coinbase')?( sortBy==='asc'?<i className="fa fa-angle-down"></i>:<i className="fa fa-angle-up"></i>) :''} 
                          </strong>
                          &nbsp;
                          <a href="/#" data-toggle="tooltip"   onClick={((e) => handleSortData(e,queryData,'coinbase',sortBy))}    data-original-title="Click for descending sort">
                              
                              {t('nodestatus.table.Coinbase')}
                          </a> 
                      </div>
                    </th>
                    <th width="40" scope="col">  
                      <div className="d-flex align-items-center"> 
                        <div className="">
                          <strong> 
                              {(sortType ==='status')?( sortBy==='asc'?<i className="fa fa-angle-down"></i>:<i className="fa fa-angle-up"></i>) :''} 
                          </strong>
                          &nbsp;
                          <a href="/#" data-toggle="tooltip"   onClick={((e) => handleSortData(e,queryData,'status',sortBy))}    data-original-title="Click for descending sort">
                          {t('nodestatus.table.Status')}
                          </a> 
                        </div>
                        <div className="dropdown">
                              <button className="btn btn-xs btn-soft-secondary btn-icon" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-filter btn-icon__inner"></i>
                              </button>
                              <div className="dropdown-menu" style={{minWidth: '220px'}}>
                                  <div className="py-0 px-3">
                                      <input type="text" className="form-control form-control-xs mb-2" placeholder="online/offline" onChange={ (e)=> setNodeStatus(e.target.value) } value={nodeStatus} name="nodeStatus" />
                                      <div className="d-flex">
                                        <button type="" className="btn btn-xs btn-block btn-primary mr-2 mt-0" onClick={goNodeStatus}>
                                          <i className="far fa-filter mr-1"></i> Filter
                                        </button>
                                        <button type="" onClick={goClearNodeStatus} className="btn btn-xs btn-block btn-soft-secondary mt-0">
                                          Clear
                                        </button>
                                      </div>
                                  </div>
                              </div>
                        </div>
                      </div>
                    </th>
                    <th scope="col"  >
                      <div className="d-flex align-items-center"> 
                        <div className="">
                          <strong> 
                              {(sortType ==='nodetype')?( sortBy==='asc'?<i className="fa fa-angle-down"></i>:<i className="fa fa-angle-up"></i>) :''} 
                          </strong>
                          &nbsp;
                          <a href="/#" data-toggle="tooltip"   onClick={((e) => handleSortData(e,queryData,'nodetype',sortBy))}    data-original-title="Click for descending sort">
                             {t('nodestatus.table.nodeType')}
                          </a> 
                        </div>
                        <div className="dropdown">
                              <button className="btn btn-xs btn-soft-secondary btn-icon" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-filter btn-icon__inner"></i>
                              </button>
                              <div className="dropdown-menu" style={{minWidth: '220px'}}>
                                  <div className="py-0 px-3">
                                      <input type="text" className="form-control form-control-xs mb-2" placeholder="hpnode/prenode/syncnode" onChange={ (e)=> setNodetype(e.target.value) } value={nodetype} name="noteTpe" />
                                      <div className="d-flex">
                                        <button type="" className="btn btn-xs btn-block btn-primary mr-2 mt-0" onClick={goNodeType}>
                                          <i className="far fa-filter mr-1"></i> Filter
                                        </button>
                                        <button type="" onClick={goClearNoteType} className="btn btn-xs btn-block btn-soft-secondary mt-0">
                                          Clear
                                        </button>
                                      </div>
                                  </div>
                              </div>
                        </div>
                      </div>
                    </th>
                    <th scope="col"> 
                      <div className="">
                          <strong> 
                              {(sortType ==='vote')?( sortBy==='asc'?<i className="fa fa-angle-down"></i>:<i className="fa fa-angle-up"></i>) :''} 
                          </strong>
                          &nbsp;
                          <a href="/#" data-toggle="tooltip"   onClick={((e) => handleSortData(e,queryData,'vote',sortBy))}    data-original-title="Click for descending sort">
                          {t('nodestatus.table.Vote')}
                          </a> 
                      </div>
                    </th>

                    <th scope="col" >
                      <div className="">
                          <strong> 
                              {(sortType ==='boeversion')?( sortBy==='asc'?<i className="fa fa-angle-down"></i>:<i className="fa fa-angle-up"></i>) :''} 
                          </strong>
                          &nbsp;
                          <a href="/#" data-toggle="tooltip"   onClick={((e) => handleSortData(e,queryData,'boeversion',sortBy))}    data-original-title="Click for descending sort">
                          {t('nodestatus.table.BoeVersion')}
                          </a> 
                      </div>
                    </th>
                    <th scope="col">
                      <div className="">
                          <strong> 
                              {(sortType ==='ghpbversion')?( sortBy==='asc'?<i className="fa fa-angle-down"></i>:<i className="fa fa-angle-up"></i>) :''} 
                          </strong>
                          &nbsp;
                          <a href="/#" data-toggle="tooltip"   onClick={((e) => handleSortData(e,queryData,'ghpbversion',sortBy))}    data-original-title="Click for descending sort">
                          {t('nodestatus.table.GhpbVersion')}
                          </a> 
                      </div>
                    </th>
                    <th scope="col">
                      <div className="">
                          <strong> 
                              {(sortType ==='mining')?( sortBy==='asc'?<i className="fa fa-angle-down"></i>:<i className="fa fa-angle-up"></i>) :''} 
                          </strong>
                          &nbsp;
                          <a href="/#" data-toggle="tooltip"   onClick={((e) => handleSortData(e,queryData,'mining',sortBy))}    data-original-title="Click for descending sort">
                            {t('nodestatus.table.Mining')}
                          </a> 
                      </div>
                    </th> 

                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => { 
                    return (
                      <tr key={i}>
                        <td>{i + 1 + Number(state.body.length) * (state.body.start - 1)}</td> 
                        <td>
                          {/* {item.name} */}
                          <LinkWithTooltip placement="bottom" tooltip={item.name}>  
                           <span  className="hash-tag text-truncate"  >{item.name}</span>  
                          </LinkWithTooltip>
                        </td>
                        <td>{item.coinbase}</td>
                        <td>
                          {item.status==='online'?
                          <span  style={{color:'#00c9a7'}} className=" u-label--xs u-label--secondary"  >
                            <strong><i className="fa fa-level-up  mr-1"></i></strong>
                             {t('nodestatus.table.online')} 
                          </span>:
                           <span className=".text-secondary u-label--xs u-label--secondary"  >
                              <strong><i className="fa fa-level-down mr-1"></i></strong>  
                              {t('nodestatus.table.offline')}
                            </span>
                          }
                       
                        </td>
                        <td>{item.nodetype}</td>
                        <td>{item.vote}</td>
                        <td>{item.boeversion}</td>
                        <td>{item.ghpbversion}</td>
                        <td>  {item.status==='true'? t('nodestatus.table.true'): t('nodestatus.table.false')} </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div> 
          </div>
        </div>
      </div>
    </main>
  );
}
