import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import React from 'react';
import qs from 'qs';
import moment from 'moment';
import './cfts.css';

import Pager3 from '../../components/Pager3';
import Loading from '../../components/Loading';
import TimeTD from '../../components/TimeTD';

import LinkWithTooltip from '../../components/LinkWithTooltip';
import ShowAdditionalInfo from '../../components/ShowAdditionalInfo'; 
import {useTranslation} from 'react-i18next';

export default function Cftxs() { 
  const {t} = useTranslation(['cftxs']);
  let thStyle = {
    color: '#ffffff',
    backgroundColor: '#191e3a'
  }

  const defaultLNG = window.localStorage.getItem('lng') || 'en_US';
  moment.locale(defaultLNG);
  
  const address = '0xa9ad6a1701741e135029ec6524419b44eb9de825';
  const location = useLocation();
  const [type, setType] = useState('showAge');     
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const [state, setState] = useState({
    isLimitPages: Number(query.p) > 10000,
    body: {
      start: query.p || '1',
      length: window.localStorage.getItem('pageLength') || '50',
      field: 'address', 
      value: address,
    }
  });  

  const handleChangePageSize = e => {
    window.localStorage.setItem('pageLength', e.target.value);
    setState({...state, body: {...state.body, length: e.target.value}});
  };

  const tradeListRequest = useRequest(
    body => ({
      url: '/blockBrowser/blockChain/contractInternalTrade/contractTradeList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );
 
  useEffect(() => {
    if (!state.isLimitPages) {
      tradeListRequest.run(state.body);
    }
  }, [state]);

  if (state.isLimitPages) {
    return window.location.replace('/error');
  }

  if (tradeListRequest.loading) {
    return <Loading />;
  } 
  const data = tradeListRequest.data?.contract_trade_list || [];
  const counts = tradeListRequest.data?.counts || 0; 

  const totalPage = Math.ceil(Number(counts) / state.body.length); 


  return (
    <main role="main">
      
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center py-3">
          <div className="mb-1 mb-md-0">
            <h1 className="h4 mb-0 " style={{color:'#233371',fontWeight:600}}>
              {t('cftxs.title')}&nbsp;<span className="small text-secondary"></span> 
            </h1>
          </div>
        </div>
      </div>
      <div className="container space-bottom-2">
        <span></span>
        <div className="card" style={{backgroundColor:'#191e3a'}}>
          <div className="card-body">
            <div className="d-flex flex-wrap flex-xl-nowrap text-nowrap align-items-center ml-auto"></div>
            <div className="d-md-flex justify-content-between mb-4"> 
                <p className="mb-2 mb-md-0">
                  <span className="d-flex align-items-center text-white">  {t('cftxs.tip1')} {counts}   {t('cftxs.tip2')}</span> 
                </p>      
                <Pager3 current={state.body.start} total={totalPage} path="/cftxs" />
            </div>
            
            <div className="table-responsive mb-2 mb-md-0">
                <table id="cfts-table" className="table table-hover" style={{color:'#fff'}}>
                    <thead className="thead-light">
                        <tr>
                        <th scope="col" width="20"></th>
                        <th width="40" scope="col">
                             {t('cftxs.table.Rank')}
                        </th>
                        <th scope="col" width="1">
                           {t('cftxs.table.TxnHash')}
                        </th>
                        <th scope="col" className="d-none d-sm-table-cell" style={{...thStyle}}>
                            <div className="d-flex align-items-center">
                                <div className="mr-2"> {t('cftxs.table.Block')} </div>
                                    <div className="dropdown" style={{display: 'none'}}>
                                        <button className="btn btn-xs btn-soft-secondary btn-icon" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fa fa-filter btn-icon__inner"></i>
                                        </button>
                                        <div className="dropdown-menu" style={{minWidth: '220px'}}>
                                        <div className="py-0 px-3">
                                            <label className="mb-1" htmlFor="fromblock">
                                             {t('cftxs.table.From')} 
                                            </label>
                                            <input type="text" name="fromblock" className="form-control form-control-xs mb-2 js-form-message" placeholder="Block Number" />
                                            <label className="mb-1" htmlFor="toblock">
                                            {t('cftxs.table.To')} 
                                            </label>
                                            <input type="text" name="toblock" className="form-control form-control-xs mb-2 js-form-message" placeholder="Block Number" />
                                            <div className="d-flex">
                                            <button type="" className="btn btn-xs btn-block btn-primary mr-2 mt-0">
                                                <i className="far fa-filter mr-1"></i> {t('cftxs.table.Filter')}
                                            </button>
                                            <button type="" className="btn btn-xs btn-block btn-soft-secondary mt-0">
                                            {t('cftxs.table.Clear')} 
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th scope="col">
                            <div className="d-flex align-items-center">
                            <div className="mr-2">
                                {
                                (type ==='showAge')?
                                <LinkWithTooltip placement="bottom" tooltip={t('cftxs.age.tip1')}>
                                <a href="#!" title="" style={{color:'#00F5FF',fontWeight:'600'}} onClick={e => {  e.preventDefault(); setType('showDate'); }} >
                                {t('cftxs.table.age')}
                                </a>
                                </LinkWithTooltip>
                                :
                                <LinkWithTooltip placement="bottom" tooltip={t('cftxs.age.tip2')}> 
                                <a href="#!"  style={{color:'#00F5FF',fontWeight:'600'}} onClick={e => {  e.preventDefault(); setType('showAge'); }}  >
                                {t('cftxs.table.time')}
                                </a>
                                </LinkWithTooltip>
                                }

                            </div>
                            <div className="dropdown" style={{display: 'none'}}>
                                <button className="btn btn-xs btn-soft-secondary btn-icon" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-filter btn-icon__inner"></i>
                                </button>
                                <div className="dropdown-menu" style={{minWidth: '220px'}}>
                                <div className="py-0 px-3">
                                    <label className="mb-1" htmlFor="fromage">
                                    {t('cftxs.table.From')} 
                                    </label>
                                    <input type="date" name="fromage" className="form-control form-control-xs mb-2" />
                                    <label className="mb-1" htmlFor="toage">
                                    {t('cftxs.table.To')} 
                                    </label>
                                    <input type="date" name="toage" className="form-control form-control-xs mb-2" />
                                    <div className="d-flex">
                                    <button type="" className="btn btn-xs btn-block btn-primary mr-2 mt-0">
                                        <i className="far fa-filter mr-1"></i>{t('cftxs.table.Filter')} 
                                    </button>
                                    <button type="" className="btn btn-xs btn-block btn-soft-secondary mt-0">
                                    {t('cftxs.table.Clear')}
                                    </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </th>
                        <th width="30" ></th>
                        <th scope="col" >
                        {t('cftxs.table.Operation')}
                        </th>
                        <th scope="col" >
                            <div className="d-flex align-items-center">
                                <div className="mr-2">{t('cftxs.table.Token')}</div> 
                            </div>
                        </th> 
                        <th scope="col" >
                            <div className="d-flex align-items-center">
                                <div className="mr-2">{t('cftxs.table.From')}</div> 
                            </div>
                        </th> 
                       
                        <th scope="col" >{t('cftxs.table.Value')}</th>
                        <th scope="col" >
                            <span data-toggle="tooltip" title="" data-toggle-second="tooltip" data-original-title="(Gas Price * Gas Used by Txns) in HPB">
                            <span className="">{t('cftxs.table.TxnFee')}</span>
                            </span>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, i) => { 
                        const aa = []
                        const now = new Date().getTime();
                        const time = moment(item.create_time).startOf('minute').fromNow();
                        return (
                            <tr key={item.trade_hash}>
                                <td> 
                                    <ShowAdditionalInfo txHash={item.trade_hash} popOverTitle={aa}  placement="right" >                             
                                        <a role="button" tabIndex="0" type="button"  className="js-txnAdditional-1 btn btn-xs btn-icon btn-soft-secondary">
                                        <i className="far fa-eye btn-icon__inner"></i>
                                        </a>
                                    </ShowAdditionalInfo>

                                </td>
                                <td>{i + 1 + Number(state.body.length) * (state.body.start - 1)}</td>
                                <td>
                                    {item.trade_status ===0  ?   <i className="far fa-exclamation-circle mr-1"  style={{color: '#ff0000'}}  data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Contract"></i> :''}
                                    <a className="hash-tag text-truncate myFnExpandBox_searchVal" href={`/tx/${item.trade_hash}`}>
                                    {item.trade_hash}
                                    </a>
                                </td>
                                <td className="d-none d-sm-table-cell">
                                    <a href={`/block/${item.block_no}`}>{item.block_no}</a>
                                </td>
                                  <TimeTD time={item.trade_time} interval={ item.trade_time_interval}  type={type} />
                                <td width="30">
                                  <img
                                  alt={item.function_name} src={`/images/cftx/${item.function_name}.svg?v=0.0.1`} 
                                  width="20" />
                                </td>
                                <td> 
                                {item.function_name}
                                </td>
                                <td>{item.input_args !==''?item.input_args:'****'}</td>
                                <td> 
                                  <a className="hash-tag text-truncate" href={`/address/${item.trade_from}`} data-toggle="tooltip" data-placement="bottom" title={item.trade_from} data-original-title={item.trade_from}>
                                    {item.trade_from}
                                  </a>                                  
                                </td>             
                              
                                <td>{item.trade_amount} HPB</td>
                                <td>
                                    <span className="small">{item.trade_fee}</span>
                                </td>
                            </tr>
                        );
                        })}
                    </tbody>
                </table>
            </div>

            <div>
              <div className="d-md-flex justify-content-between my-3">
                <div className="d-flex align-items-center text-white mb-2 mb-md-0">
                
                  <select className="custom-select custom-select-xs mx-2" onChange={handleChangePageSize} value={state.body.length}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  {t('cftxs.tip6')}
                </div>
                <Pager3 current={state.body.start} total={totalPage} path="/cftxs" />
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
              <div className="d-flex align-items-center text-secondary"></div>
              <div className="small text-muted"></div>
            </div>

          </div>
        </div>
      </div>  

    </main> 
  );
}


