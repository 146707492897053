const address = { 
    'address.title': '合约',
    'address.header.contract': '合约',
    'address.header.address': '地址',
    'address.header.tip1': '点击查看二维码', 

    
    'address.index.tab.title1': '交易',
    'address.index.tab.title2': '内部交易',
    'address.index.tab.title3': 'HRC-20代币交易',
    'address.index.tab.title4': 'Erc721代币交易',
    'address.index.tab.title5': 'Validated Blocks',
    'address.index.tab.title6': 'Validators Set Info',
    'address.index.tab.title7': '合约',
    'address.index.tab.title8': '事件',
    'address.index.tab.title9': '分析',

    'address.transactions.tip1': '最新的',
    'address.transactions.tip2': ' 从总计',
    'address.transactions.tip3': '交易',

    'address.Internaltx.tip1': '最新',
    'address.Internaltx.tip2': '内部交易', 
    'address.Internaltx.table.txnHash': '父区块交易哈希值', 
    'address.Internaltx.table.Block': '区块', 
    'address.Internaltx.table.age': '块龄',
    'address.Internaltx.table.time': '日期事件 (UTC)',
    'address.Internaltx.table.From': '发送方',
    'address.Internaltx.table.To': '接收方',
    'address.Internaltx.table.value': '价值', 

    'address.Tokentxns.tip1': '最新的', 
    'address.Tokentxns.tip2': '代币转移事件',  

    'address.Tokentxns.table.txnHash': '交易哈希',   
    'address.Tokentxns.table.age': '块龄',
    'address.Tokentxns.table.time': '日期事件 (UTC)',
    'address.Tokentxns.table.From': '发送方',
    'address.Tokentxns.table.To': '接收方',
    'address.Tokentxns.table.Value': '价值', 
    'address.Tokentxns.table.Token': '代币', 


    'address.transactions.table.TxnHash': '交易哈希',
    'address.transactions.table.Block': '区块',
    'address.transactions.table.age': '块龄',
    'address.transactions.table.time': '日期事件 (UTC)',
    'address.transactions.table.From': '发送方',
    'address.transactions.table.To': '接收方',
    'address.transactions.table.value': '价值',
    'address.transactions.table.txnfee': '交易费用',

    'address.Events.tip1': '最新', 
    'address.Events.tip2': '合约事件', 
    'address.Events.tip3': '提示', 
    'address.Events.tip4': '日志', 
    'address.Events.tip5': '由开发人员/外部UI提供者用于跟踪合同操作和审计', 
    'address.Events.table.txnHash': ' 交易哈希',  
    'address.Events.table.Method': '方法',
    'address.Events.table.Logs': '日志',

    'address.overview.contractOverview': '合约概况',
    'address.overview.accountoverview': '账户概况',
    'address.overview.overview': '概况',
    'address.overview.Balance': '余额',
    'address.overview.hpbValue': 'HPB价值',
    'address.token.token': '代币',

    'address.more.more': '更多信息',
    'address.more.mynametag': '我的名字标签',
    'address.more.createor': '合约创建者',
    'address.more.tokenTracker': '代币追踪者',
    'address.more.attxn': ' 在交易',
    'address.more.NotAvailable': ' 不可用',
     


    'address.analytics.timeSeries':'时间序列: HPB余额',
    'address.analytics.hpbHBalance':'HPB最高余额',
    'address.analytics.hpbLBalance':' HPB最低余额',
    'address.analytics.USDHValue':' USD最高价值',
    'address.analytics.USDLValue':' USD最低价值',
    'address.analytics.HPBBalancefor':'HPB余额',

    'address.analytics.HPBAccountBalance':' HPB账户余额',
    'address.analytics.HistoricUSDVal':' USD历史价值',
    'address.analytics.CurrentUSDVal':' USD 当前价值',
    'address.analytics.TxnCount':' 交易数量',

    'address.analytics.htbalance':'HPB余额',
    'address.analytics.transactions':'交易',
    'address.analytics.txnfees':'交易费用',
    'address.analytics.httransfers':'HPB 转移',
    'address.analytics.tokentransfers':'代币转移',

    'address.analytics.timeSeriesTr':'时间序列: HPB交易',
    'address.analytics.HPBTransactionsfor':'HPB交易',
    'address.analytics.HPBTransactions':'HPB交易',
    'address.analytics.UniqueOutgoingAddress':'唯一传出地址',
    'address.analytics.UniqueIncomingAddress':'唯一传入地址',

    'address.analytics.HTBalanceChart.HPBAccountBalance':'HPB账户余额',
    'address.analytics.HTBalanceChart.HistoricUSDVal':'USD历史价值',
    'address.analytics.HTBalanceChart.CurrentUSDVal':'USD 当前价值',
    'address.analytics.HTBalanceChart.TxnCount':'交易数量',
    'address.analytics.HTBalanceChart.USDBalanceValue':'USD余额价值',

    'address.analytics.TxnFees.HPBFeesSpent':'HPB花费费用',
    'address.analytics.TxnFees.USDFeesSpent':'USD花费费用',
    'address.analytics.TxnFees.HPBFeesUsed':'HPB节省费用',
    'address.analytics.TxnFees.USDFeesUsed':'USD节省费用',
    'address.analytics.TxnFees.HPBTransactionFeesfor':'HPB交易费用',
    'address.analytics.TxnFees.TimeSeries':'时间序列: HPB交易花费与节省',
    'address.analytics.TxnFees.TotalFeesSpent':'总花费费用(作为发送者)',
    'address.analytics.TxnFees.TotalFeesUsed':' 总节省费用(作为接收者)',

    
    'address.analytics.HTTransfers.Sent':'发送 (出)',
    'address.analytics.HTTransfers.Receive':'接收 (入)',
    'address.analytics.HTTransfers.ValidatorReward':'有效奖励', 
    'address.analytics.HTTransfers.HPBTransactionFeesfor':'HPB转移',
    'address.analytics.HTTransfers.TimeSeries':'时间序列: HPB转移',
    'address.analytics.HTTransfers.TransferAmounts':'转移金额', 


    'address.analytics.TokenTransfers.TokenTransfers':'代币转移',
    'address.analytics.TokenTransfers.TokenContractsCount':'代币合约数量',
    'address.analytics.TokenTransfers.OutboundTransfers':'出站转移', 
    'address.analytics.TokenTransfers.InboundTransfers':'入站转移',
    'address.analytics.TokenTransfers.UniqueAddressSent':'唯一地址发送',
    'address.analytics.TokenTransfers.UniqueAddressReceived':'唯一地址接收', 
    'address.analytics.TokenTransfers.HPBTransactionFeesfor':'HPB转移',  
    'address.analytics.TokenTransfers.TimeSeries':'时间序列: 地址代币(HRC-20)转移', 
    'address.analytics.TokenTransfers.ProTip':'专业提示: 单击图表数据点以查看更多信息', 

    'address.contract.tip1':'您是合约的创建者？', 
    'address.contract.tip2':'验证并且发布', 
    'address.contract.tip3':'您的合约源代码!', 
    'address.contract.tip4':'代码', 
    'address.contract.tip5':'读合约', 
    'address.contract.tip6':'写合约 ', 
    'address.contract.tip7':'合约代码验证', 
    'address.contract.tip8':'完全匹配', 
    'address.contract.tip9':'本合约由以下合约代码创建', 
    'address.contract.tip10':'注意：我们还发现另外', 
    'address.contract.tip11':'合约', 
    'address.contract.tip12':'具有完全匹配的字节码', 
    'address.contract.tip13':'反编译字节码',  
    'address.contract.tip14':'切换到操作码视图', 
    'address.contract.tip15':'相似合约', 
    'address.contract.tip16':'合约名称', 
    'address.contract.tip17':'编译器版本', 
    'address.contract.tip18':'启用优化', 
    'address.contract.tip19':'是', 
    'address.contract.tip20':'否', 
    'address.contract.tip21':'其他设置', 
    'address.contract.tip22':'evm版本', 
    'address.contract.tip23':'许可证', 
    'address.contract.tip24':'合约源代码', 
    'address.contract.tip25':'固态',
    'address.contract.tip26':'合约ABI',
    'address.contract.tip27':'合约创建代码',


    'account.tip1': '共找到',
    'account.tip2': '帐户 ',
    'account.tip3': '仅显示最后的',
    'account.tip4': '个最佳账户 ',
    'account.tip5': '显示',
    'account.tip6': '记录',

};

export default address;
