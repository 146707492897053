import {useState, useRef, useEffect, useContext} from 'react';
import {useRequest} from 'ahooks';

import Loading from '../../components/Loading';
import {useTranslation} from 'react-i18next';
export default function TokenAnalytics({token}) {
  const {t} = useTranslation(['token']);
  const containerRef = useRef();
  const [body, setBody] = useState({
    field: 'address',
    value: token,
    start_date: undefined,
    end_date: undefined,
  });

  const tradeFeeChartRequest = useRequest(
    body => ({
      url: '/blockBrowser/tokens/analysis/tokenChart',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  const tokenname = '';

  useEffect(() => {
    tradeFeeChartRequest.run(body).then(res => {
      // res.fee_charts
      if (!res) {
        return;
      }

      console.log(res);
      var tokensymbol = 'HOT';

      var chartHeight = 500;

      var plotData = eval([[Date.UTC(2021, 5, 29), 19485.83451463, 4, 4, 2, 5]]);
      var plotData = res.map(r => {
        const [y, m, d] = r.date.split('-');
        return [Date.UTC(y, Number(m) - 1, d), Number(r.transfer_amount), Number(r.transfers_count), Number(r.unique_receivers), Number(r.unique_senders), Number(r.total_uniques)];
      });

      var tokenname = '';
      var tokensymbol = '';

      var contractAddress = token;
      var chartHeight = 500;

      var plotData = eval([[Date.UTC(2020, 11, 21), 100000000, 1, 1, 1, 2]]);
      var plotData_datemax = Date.UTC(2020, 11, 21);

      //Token Contract Time Series
      function renderChart() {
        var seriesNameConverter = {
          Series_1: t('token.TokenContract.TransferAmount'),
          Series_2: t('token.TokenContract.TransfersCount'),
          Series_3: t('token.TokenContract.Receivers'),
          Series_4: t('token.TokenContract.Senders'),
          Series_5: t('token.TokenContract.Uniques'),
        };
        console.log(window.Highcharts)
        new window.Highcharts.chart(
          {
            chart: {
              renderTo: containerRef.current,
              zoomType: 'x',
              height: chartHeight,
            },
            navigator: {
              enabled: true,
            },
            rangeSelector: {
              buttons: [
                {
                  type: 'month',
                  count: 1,
                  text: '1m',
                },
                {
                  type: 'month',
                  count: 6,
                  text: '6m',
                },
                {
                  type: 'year',
                  count: 1,
                  text: '1y',
                },
                {
                  type: 'all',
                  text: 'All',
                },
              ],
              selected: 4,
              enabled: true,
            },
            title: {
              text: t('token.TokenContract.tokenCon') + contractAddress + ' (' + tokenname + ')',
            },
            subtitle: {
              text: 'Source: hpb.io',
            },
            xAxis: {
              //gridLineWidth: 1,
              //top: 11,
              type: 'datetime',
              crosshair: {
                width: 2,
              },
              max: plotData_datemax,
            },
            yAxis: [
              {
                //-Total Transfers
                title: {
                  text: 'Amount',
                },
                resize: {
                  enabled: true,
                },
                opposite: false,
                showEmpty: false,
              },
              {
                //-contracts
                resize: {
                  enabled: true,
                },
                title: {
                  text: t('token.TokenContract.tokenConCount'),
                },
                showEmpty: false,
                opposite: true,
              },
              {
                //-Outbound Transfers
                visible: false,
                linkedTo: 0,
                opposite: false,
              },
              {
                //-Inbound Transfers
                visible: false,
                linkedTo: 0,
                resize: {
                  enabled: true,
                },
                showEmpty: false,
              },
              {
                //-Unqiue Addr Sent
                resize: {
                  enabled: true,
                },
                opposite: true,
                showEmpty: false,
              },
              {
                //-Unqiue Addr Received
                resize: {
                  enabled: true,
                },
                showEmpty: false,
              },
            ],
            plotOptions: {
              series: {
                animation: {
                  duration: 0,
                },
                turboThreshold: 10000,
                //cursor: 'pointer',
                point: {},
                marker: {
                  lineWidth: 1,
                },
              },
            },
            credits: {
              enabled: false,
            },
            legend: {
              enabled: true,
              labelFormatter: function () {
                return seriesNameConverter[this.name];
              },
              //itemStyle: {
              //    color: '#000000',
              //    fontWeight: 'bold'
              //}
            },
            tooltip: {
              hideDelay: 0,
              crosshairs: true,
              //split: false,
              useHTML: true,
              shared: true,
              shadow: true,
              split: false,
              backgroundColor: 'white',
              formatter: function () {
                var s;
                var points = this.points;
                var pointsLength = points.length;
                s =
                  '<table class="tableformat" style="border: 0px;" width="100%"><tr><td colspan=2 style="padding-bottom:5px;"><span style="font-size: 10px;"> ' +
                  window.Highcharts.dateFormat('%a %e, %B %Y', new Date(points[0].key)) +
                  '</span><br></td></tr>';
                for (var index = 0; index < pointsLength; index += 1) {
                  s +=
                    '<tr><td style="padding-top:4px;padding-bottom:4px;border-top:1px solid #D5D8DC;" valign="top"><span style="color:' +
                    points[index].series.color +
                    ';font-size: 15px !important;">\u25A0</span> ' +
                    seriesNameConverter[points[index].series.name] +
                    '</td><td style="border-top:1px solid #D5D8DC;">' +
                    (points[index].series.name == 'Series_1' ? '<b>' + window.Highcharts.numberFormat(points[index].y, 2, '.', ',') + ' </b>' + tokensymbol : '<b>' + points[index].y + '</b>') +
                    '</td></tr>';
                }
                s += '</table>';
                return s;
              },
            },
            series: [
              {
                name: 'Series_1', //Total token Transfers
                type: 'column',
                keys: ['x', 'y'],
                data: plotData,
                pointWidth: 5,
                tooltip: {
                  pointFormatter: function () {
                    var text;
                    var point = this;
                    text = '<br><span style="color:' + point.series.color + '">&#11044</span> ' + point.series.name + ': <b>' + this.y + '</b><br>';
                    return text;
                  },
                },
              },
              {
                name: 'Series_2', //Token Contract Counts
                type: 'line',
                yAxis: 1,
                keys: ['x', 'a', 'y'],
                data: plotData,
              },
              {
                name: 'Series_3', //Unique receivers
                type: 'line',
                yAxis: 1,
                keys: ['x', 'a', 'b', 'y'],
                data: plotData,
              },
              {
                name: 'Series_4', //Unique senders
                type: 'line',
                yAxis: 1,
                keys: ['x', 'a', 'b', 'c', 'y'],
                data: plotData,
              },
              {
                name: 'Series_5', //Total Unique Senders
                type: 'line',
                yAxis: 1,
                keys: ['x', 'a', 'b', 'c', 'd', 'y'],
                data: plotData,
              },
            ],
          },
          function (chart) {
            // on complete
          },
        );
      }

      renderChart();
    });
  }, []);

  if (tradeFeeChartRequest.loading) {
    return <Loading />;
  }

  return (
    <div>
      <ul className="nav nav-pills nav-pills-secondary mb-2" id="nav_tabs" role="tablist">
        <li id="li_tab1" className="nav-item">
          <a className="nav-link active show" href="#tab1" data-toggle="tab">
            {t('token.TokenContract.title')}
          </a>
        </li>
      </ul>
      <div className="tab-content card border p-3 mb-4">
        <div className="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
          <span id="tab1_default_content"></span>
          <div id="container_1">
            <div className="d-md-flex justify-content-between align-items-center mb-3">
              <h4 className="h6 mb-1 mb-md-0">{t('token.TokenContract.timerSer')}</h4>
             {/*  <div className="text-secondary">
                <span id="addressdisplay_1">Wed 3, Mar 2021 - Tue 29, Jun 2021</span>
              </div> */}
            </div>
            <hr className="my-0" />
            <div className="mt-3">
              <div className="content" ref={containerRef} style={{overflow: 'hidden'}} data-highcharts-chart="0"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
