import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import {useLocation} from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';
// import 'moment/locale/zh-cn'; 
import {useTranslation} from 'react-i18next';
import Pager from '../../components/Pager';
import Loading from '../../components/Loading';
import TimeTD from '../../components/TimeTD';
import LinkWithTooltip from '../../components/LinkWithTooltip';

export default function TxsInternal() {
  const location = useLocation();
  const {t} = useTranslation(['txsInternal']);
  
  const defaultLNG = window.localStorage.getItem('lng') || 'en_US';
  moment.locale(defaultLNG);

  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      length: window.localStorage.getItem('pageLength') || '50',
      field: query.block ? 'block_no' : undefined,
      value: query.block ? query.block : undefined,
    },
  });
  const [type, setType] = useState('showAge');   
  const handleChangePageSize = e => {
    window.localStorage.setItem('pageLength', e.target.value);
    setState({...state, body: {...state.body, length: e.target.value}});
  };

  const contractInternalTradeListRequest = useRequest(
    body => ({
      url: '/blockBrowser/blockChain/contractInternalTrade/contractInternalTradeList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    contractInternalTradeListRequest.run(state.body);
  }, [state]);

  if (contractInternalTradeListRequest.loading) {
    return <Loading />;
  }

  const data = contractInternalTradeListRequest.data?.contract_internal_trade_list || [];
  const counts = contractInternalTradeListRequest.data?.counts || 0;
  const contract_internal_trade_count = contractInternalTradeListRequest.data?.contract_internal_trade_count || 0;
  const totalPage = Math.ceil(Number(contract_internal_trade_count) / state.body.length);

  return (
    <main id="content" role="main">
      <div className="container py-3">
        <div className="d-sm-flex align-items-center">
          <div className="mb-2 mb-sm-0">
            <h1 className="h4 mb-0">
              <span>{t('txsInternal.title')}</span>
              {query.block ? (
                <p className="mb-0 text-break small">
                  <span className="small text-secondary ml-1">For Block {query.block}</span>
                </p>
              ) : undefined}
            </h1>
          </div>
        </div>
      </div>

      <span id="ContentPlaceHolder1_lblAdResult"></span>
      <div className="container space-bottom-2">
        <div className="card">
          <form method="post" action="./txsInternal?p=1" id="ctl00">
            <div className="card-body">
              <div className="d-md-flex justify-content-between mb-4">
                <p className="mb-2 mb-md-0">
                  <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{display: 'none'}}></i> {t('txsInternal.tip1')} {counts} {t('txsInternal.tip2')}
                  {query.block ? undefined : <span className="d-block small">({t('txsInternal.tip3')} {contract_internal_trade_count} {t('txsInternal.tip4')})</span>}
                </p>

                <Pager current={state.body.start} total={totalPage} path="/txsInternal" />
              </div>
              <div className="table-responsive mb-2 mb-md-0">
                <table className="table table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{t('txsInternal.table.block')}</th>
                      <th scope="col">
                      {
                          (type ==='showAge')?
                          <LinkWithTooltip placement="bottom" tooltip={t('txsInternal.age.tip1')}>
                            <a href="#!" title="" onClick={e => {  e.preventDefault(); setType('showDate'); }} >
                            {t('txsInternal.table.age')}
                            </a>
                          </LinkWithTooltip>
                          :
                          <LinkWithTooltip placement="bottom" tooltip={t('txsInternal.age.tip2')}> 
                            <a href="#!" onClick={e => {  e.preventDefault(); setType('showAge'); }}  > 
                              {t('txsInternal.table.time')}
                            </a>
                          </LinkWithTooltip>
                        }
                      </th>
                      <th scope="col">{t('txsInternal.table.txnHash')} </th>
                      <th scope="col">{t('txsInternal.table.type')}</th>
                      <th scope="col">{t('txsInternal.table.from')}</th>
                      <th scope="col" width="30"></th>
                      <th scope="col">{t('txsInternal.table.to')}</th>
                      <th scope="col">{t('txsInternal.table.value')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, i) => {
                      const now = new Date().getTime();
                      const time = moment(now - Number(item.trade_time) * 1000)
                        .startOf('minute')
                        .fromNow();

                      return (
                        <tr key={i}>
                          <td className="bg-soft-secondary">{i === 0 || item.block_no !== data[i - 1].block_no ? <a href={`/block/${item.block_no}`}>{item.block_no}</a> : undefined}</td>

                          <TimeTD time={item.trade_time} interval={item.trade_time_interval}  type={type}/>

                          <td className="bg-light">
                            {/* <td className="border-danger bg-light"> */}
                            {/* <i className="fa fa-exclamation-circle text-danger" data-toggle="tooltip" data-title="Error occured during Contract Execution" data-original-title="" title=""></i> */}
                            <span className="hash-tag text-truncate">
                              <a href={`/tx/${item.parent_trade_hash}`}>{item.parent_trade_hash}</a>
                            </span>
                            <i className="fas fa-chevron-circle-right text-secondary"></i>
                          </td>
                          <td>{item.types}</td>
                          <td>
                            <i className="far fa-file-alt text-secondary" data-toggle="tooltip" title="" style={{marginRight: '4px'}} data-original-title="Contract"></i>
                            <a className="hash-tag text-truncate" href={`/address/${item.trade_from}`} data-toggle="tooltip" title="" data-original-title={item.trade_from}>
                              {item.trade_from}
                            </a>
                          </td>
                          <td>
                            <span className="btn btn-xs btn-icon btn-soft-success rounded-circle">
                              <i className="fas fa-long-arrow-alt-right btn-icon__inner"></i>
                            </span>
                          </td>
                          <td>
                          <a className="hash-tag text-truncate" href={`/address/${item.trade_to}`} data-toggle="tooltip" title="" data-original-title={item.trade_to}>
                              {item.trade_to}
                            </a>
                            {/* <i className="far fa-newspaper text-secondary hash-tag text-truncate" data-toggle="tooltip" title="" data-original-title="Contract creation failed"></i>&nbsp; */}
                            {/* {item.trade_to_name ? item.trade_to_name : item.trade_to} */}
                          </td>
                          <td>{item.value} HPB</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="d-md-flex justify-content-between my-3">
                <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                  {t('txsInternal.tip5')}
                  <select className="custom-select custom-select-xs mx-2"   value={state.body.length} onChange={handleChangePageSize}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  {t('txsInternal.tip6')}
                </div>
                <Pager current={state.body.start} total={totalPage} path="/txsInternal" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}
